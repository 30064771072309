import { sumBy } from 'lodash';

// NEWEST!!! function to be reused
const createNewPayments = (paid, total, payments = []) => {
  let diff = paid - total
    let newPayments = payments.map((cur, i) => {
      let payObj = {...cur}
      delete payObj._id //  NEWEST!!! We remove the _id field from the payment object so it will be deleted in the back end.
      if (diff <= 0) {
        // if diff is 0 or less, we stop modifying the payments
        return payObj
      }
      // we remove the diff amount from the first payment. If there is still a diff, we remove the next payment and so on
      diff = diff - payObj.paymentAmount; // might be negative amount. We will remove the payment if it is
      payObj.paymentAmount = diff >= 0 ? 0 : Math.abs(diff)
      return {...payObj}
    }).filter(el => el.paymentAmount > 0)
    return newPayments;
}

// This function updates the payments array to remove any payments that are more than the total of the wig in the lineItems array. NEW!!!
const updatePayments = (lineItems, payments = [], total) => {
  // check if wig payments are not more than the wig total in lineitmes. if so, remove the extra payments. NEW!!!

  let updatedPayments = []
  const remainingBalance = payments.reduce((acc, cur) => {
    return cur.remainingBalance ? acc + cur.paymentAmount : 0 }, 0)
  const remainingTotal = lineItems.reduce((acc, cur) => {
    return cur.wigId === "remain" ? acc + cur.total : 0 }, 0)
  // run through the lineItems first and check for overpaid wigs
  lineItems.forEach(el => {
   let wigPayments = payments.filter(pay => pay.paymentWigId === el.wigId)

    if (wigPayments.length) {
      let paidTotal = sumBy(wigPayments, 'paymentAmount')
      if (paidTotal > el.total) {
        let newPayments = createNewPayments(paidTotal, el.total, wigPayments)
        updatedPayments = updatedPayments.concat(newPayments)
      } else {
          updatedPayments = updatedPayments.concat(wigPayments)
      }
    }
  })

  let remainingPayments = payments.filter(pay => !pay.paymentWigId)

  // remove excess payments for remaining balance
  if (remainingBalance > remainingTotal) {
    remainingPayments = createNewPayments(remainingBalance, remainingTotal, remainingPayments)
  }

  updatedPayments = updatedPayments.concat(remainingPayments)
  return updatedPayments;
}

export { updatePayments };