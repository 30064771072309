import React from 'react';

import styled from 'styled-components';

import { currencyFormat } from '../../utils/util'

import { BasicFlex, FullDivider } from '../../utils/common';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Slider from '@material-ui/core/Slider';
import Button from '@material-ui/core/Button';
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import SwitchField from '@material-ui/core/Switch';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';


const Container = styled.div`
 
`;


const SliderLabel = styled.span`
  font-size: 1.5rem;
  display: block;
  margin-bottom: 1.5rem;
  font-weight: 500;
`

const CheckboxLabel = styled.span`
  font-size: 1.5rem;
  display: block;
  margin: 1.5rem 0;
  margin-top: 2.5rem;
  font-weight: 500;
`

const SliderContainer = styled.div`
  width: 50rem;
  min-height: 6rem;
  padding: 1rem;

  .slider {
    padding: 0 2rem;
  }

  .labels {
    display: flex;
    width: 100%;
    height: 2.5rem;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .ranges {
    display: block;
    font-size: 1.4rem;
    font-weight: 300;
  }
`

const FilterOption = styled.div`
  margin: 2rem 0;
`;

const valuetext = (value) => {
  return `$${value}`;
}

const FilterPayments = ({ handleFilter, filter, applyFilter, minMax, marks}) => {

  const { start, end, useFromDate, slider, balance, isReconciled } = filter;

  return (
    <Container>
      <SliderContainer>
        <SliderLabel>Payment Amount:</SliderLabel>
        <div className="labels">
          <span className="ranges">Min: {currencyFormat(slider[0])}</span>
          <span className="ranges">Max: {currencyFormat(slider[1])}</span>
        </div>
        <div className="slider">
          <Slider
            getAriaLabel={() => 'Min Max range'}
            value={slider}
            onChange={(e, ne) => handleFilter(ne, "slider", "range")}
            min={0}
            max={minMax[1]}
            name="slider"
            step={500}
            marks={marks}
            valueLabelDisplay="off"
            getAriaValueText={valuetext}
            valueLabelFormat={(val) => `$${val}`}
          />
          <FormHelperText>Select range to target amount</FormHelperText>
        </div>
      </SliderContainer>
      <FullDivider />
      <FilterOption>
      <CheckboxLabel>Payment Availability:</CheckboxLabel>
      <FormControlLabel
        control={<Checkbox 
        checked={balance || false} 
        onChange={(e) => handleFilter(e, "balance", "check")} 
        name="balance" color="primary" />}
        label="Available Balance"
      />
      </FilterOption>
      <FullDivider />

      <FilterOption>
        <CheckboxLabel>Filter Reconciled Payments:</CheckboxLabel>
          <RadioGroup 
            onChange={(e) => {
              handleFilter(e, "isReconciled", "radio")
              // onChange(e)
            }} 
            row>
          <FormControlLabel
            value="reconciled"
            control={<Radio checked={isReconciled === "reconciled"} />}
            label="Reconciled"
          />
          <FormControlLabel
            value="non-reconciled"
            control={<Radio checked={isReconciled === "non-reconciled"} />}
            label="Non-Reconciled"
          />
          <Button color="secondary" onClick={(e) => handleFilter({target: {value: ""}}, "isReconciled", "radio")}>Remove Filter</Button>
          </RadioGroup>
      </FilterOption>
      <FullDivider />

      <FilterOption>
      <BasicFlex my="2rem">
       <FormControlLabel
          control={
          <SwitchField
            checked={useFromDate}
            onChange={(e) => handleFilter(e, "useFromDate", "check")}
            name="closed"
            color="secondary"
            size='small'
          />
        }
        label="Use From Date"
      />
      </BasicFlex>
      <BasicFlex gap="3rem">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            variant="inline"
            openTo="year" 
            disableToolbar 
            views={["year", "month", "date"]} 
            inputVariant="outlined"
            disabled={!useFromDate}
            value={start} 
            format="MM/dd/yyyy" 
            onChange={(e) => handleFilter(e, "start", "date")}  
            label="From Date" 
            name="startDate"
          />
          <KeyboardDatePicker
            variant="inline"
            openTo="year" 
            disableToolbar 
            views={["year", "month", "date"]} 
            inputVariant="outlined"
            value={end} 
            format="MM/dd/yyyy" 
            onChange={(e) => handleFilter(e, "end", "date")}  
            label="To Date" 
            name="endDate"
          />
        </MuiPickersUtilsProvider>
      </BasicFlex>
      </FilterOption>
      <FullDivider />
      <BasicFlex my="1rem">
        <Button startIcon={<FilterListRoundedIcon />} variant="contained" size="small" color="secondary" onClick={applyFilter}>Apply Filters</Button>
      </BasicFlex>
    </Container>
  )
}

export default FilterPayments;