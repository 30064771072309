import React, {useState, useEffect, useCallback} from 'react';
import styled from 'styled-components';
import axios from '../../../axios';
import ProtectedRoute from '../../../utils/ProtectedRoute';
import { Switch, Route } from 'react-router-dom'

import useLoadList from '../../../hooks/useLoadList';

import Table from '../../../utils/table-server';
import EditTransfer from './AddTransfer';
import ViewTransfer from './ViewTransfer';

import FilterFields from '../../../utils/common/filters';

import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';
import ArrowRightAltRoundedIcon from '@material-ui/icons/ArrowRightAltRounded';

import CustomChip from '../../../utils/CustomChip';


const ChipContainer = styled.div`
  width: max-content;
`

const ChipFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: .5rem;
`;

const ChipBlock = ({level, fontSize, text, icon}) => {
  return (
    <ChipContainer>
      <CustomChip level={level} fontSize={fontSize}>
        <ChipFlex>
        <ArrowRightAltRoundedIcon /> <span>{text}</span>
        </ChipFlex>
      </CustomChip>
    </ChipContainer>
  )
}
 
const TransferList = (props) => {
  const {rows, loading, load, searchList, count, switcher, setSwitch, tabFilter, setTabFilter, query, handleQuery, reload} = useLoadList('/repair_transfers');
  

  const headers = [
    { id: 'transferId', label: 'ID' },
    { id: 'qty', label: 'Qty' },
    { id: "from.name", render: (row) => row?.from?.entity === 'hq' ? "HQ" : row?.from?.name, label: "From" },
    { id: 'to.name', render: (row) => row?.to?.entity === 'hq' ? "HQ" : row?.to?.name, label: 'To' },
    { id: "transferType", render: (row) => {
      let to = row.to.entity;
      let transferType;
      switch (to) {
        case 'hq':
          transferType = <ChipBlock level="info" fontSize="small" text="HQ" />
          break;
        case 'repair':
          transferType = <ChipBlock level="icon3" fontSize="small" text="Repair" />
          break;
        case 'salon':
          transferType = <ChipBlock level="icon4" fontSize="small" text="Salon" />
          break;
        default:
          transferType = <ChipBlock level="info" fontSize="small" text="HQ" />
          break;
      }
      return transferType;
    }, label: "TransferType"},
    { id: 'date', date: true, label: 'Date' }
  ];

  const path = props.match.url;


  const handleTabs = (filter) => {
    setTabFilter(filter)
  }

  const tabs = [
    {
      label: "All",
      title: "",
      active: tabFilter === "" ? true : false
    },
    {
      label: "To HQ",
      default: true,
      title: "hq",
      active: tabFilter === "hq" ? true : false
    },
    {
      label: "To Repair",
      title: "repair",
      active: tabFilter === "repair" ? true : false
    },
    {
      label: "Returns",
      title: "salon",
      active: tabFilter === "salon" ? true : false
    }
  ]

  const applyFilter = () => {
    reload();
  }

  const handleFilter = (e, field, type) => {
    let value;
    if (type === "check") {
      value = e.target.checked;
    } else if (type === "date") {
      value = e;
    } else {
      value = e.target.value;
    }
    handleQuery(field, value);
  }

  useEffect(() => {
    handleQuery("startDate", new Date())
    handleQuery("endDate", new Date())
  }, [])


 return (
  <Switch>
    <ProtectedRoute path={`${path}/edit/:id`} guard='writeTransfer' component={EditTransfer} />
    <ProtectedRoute path={`${path}/edit`} guard='writeTransfer' component={EditTransfer} />
    <ProtectedRoute path={`${path}/view/:id`} guard='readTransfer' component={ViewTransfer} />
    <Route path="/repair_transfers">
      <Table loading={loading} tabs={tabs} tabHandler={handleTabs} load={load} title="Transfers" headers={headers} rows={rows} link="repair_transfers" count={count} ord="desc" ordField="date"
      filterItems={<FilterFields filter={query} handleFilter={handleFilter} applyFilter={applyFilter} enableBalance={false} />}
      search={setPage => <TextField name="search" label="Search by Name, or Transfer ID"
      variant="outlined" size="small" fullWidth onChange={(e) => searchList(e.target.value, setPage)}
      InputProps={{
        endAdornment:
          <InputAdornment position="end">
            <Search color="primary" />
          </InputAdornment>
      }}
      />} 
      />
    </Route>
  </Switch>
 )
}

export default TransferList;