import React, { useState, useEffect, useCallback } from 'react';

import axios from '../axios';
import { debounce } from 'lodash';

import { useSBContext } from '../utils/snackbar';

import { downloadFile } from '../utils/util';

const useLoadList = (url, tab = '') => {
  const [rows, setRows] = useState([]);

  const [loading, setLoading] = useState(false)

  const [switcher, setSwitch] = useState(false);

  const [tabFilter, setTabFilter] = useState(tab);

  const [count, setCount] = useState(0);

  const [search, setSearch] = useState({
    p: '', rpp: '', sp: '', so: ''
  });

  const [query, setQuery] = useState({ // additional filters

  })

  console.log(query, "query")

  const { handleClick } = useSBContext();

  const getQueries = (queries, exclude) => {
    let queryStr = ''
    Object.keys(queries).forEach(key => {
      if (key === exclude) {
        return;
      }
      if (Array.isArray(queries[key])) {
        queryStr = queryStr + `&${key}=${queries[key].join(',')}`
      } else {
        queryStr = queryStr + `&${key}=${queries[key]}`
      }
    })
    if (tabFilter) {
      queryStr = queryStr + `&tab=${tabFilter}`
    }
    return queryStr;
  } 

  const load = async (page, rowsPerPage, sortProp, sortOrder, download = false) => {
    setSearch({p: page, rpp: rowsPerPage, sp: sortProp, so: sortOrder});
    setLoading(true)
    let queryString = `${url}?sort=${sortProp}&sortOrd=${sortOrder}&page=${page}&limit=${rowsPerPage}`;
    let additionalQueries = getQueries(query)
    queryString = queryString + additionalQueries

    

    console.log(queryString, "queryString", additionalQueries, "additionalQueries")
    try {
      if (download) {
        console.log(download, "download")
        queryString += '&csv=true'
        await downloadFile({url: queryString})
        return setLoading(false);
      }
      const {data} = await axios.get(queryString)
      setLoading(false)

      setRows(data.docs || []);
      setCount(data.pages || 0)

      if (!data.docs)
        handleClick('error', 'Error retrieving data')
    } catch (e) {
      handleClick('error', 'Error retrieving data')
      setLoading(false)
      setRows([])
    }
  }

  const reload = async () => {
    const {p, rpp, sp, so} = search
    load(p, rpp, sp, so)
  }

  const downloadCsv = async () => {
    console.log("downloadCsv")
    const {p, rpp, sp, so} = search
    load(p, rpp, sp, so, true)
  }

  const handleQuery = (field, value) => {
    setQuery(x => ({
      ...x,
      [field]: value
    }))
  }

  const handleFilter = (e, field, type) => {
    let value;
    if (type === "check") {
      value = e.target.checked;
    } else if (type === "date" || type === "range") {
      value = e;
    } else {
      value = e.target.value;
    }
    handleQuery(field, value);
  }
  
  const searchList = useCallback(debounce(async (val, setPage) => {
    let encodedVal = encodeURIComponent(val)
    // console.log(val, encodedVal, "shfdlfalsdjfd")

    const {rpp} = search
    let additionalQueries = getQueries(query, "term")
    console.log(additionalQueries, "addkdk")
    const {data} = await axios.get(`${url}?term=${encodedVal}&page=0&limit=${rpp}${additionalQueries}`)
    setPage(0)
    setQuery(x => ({
      ...x,
      term: encodedVal
    }));
    setRows(data.docs || []);
    setCount(data.pages || 0)
  }, 250), [search, switcher, tabFilter])

  useEffect(() => {
    const {p, rpp, sp, so} = search
    if (sp)
      load(0, rpp, sp, so)
  }, [switcher, tabFilter])

  return { load, loading, rows, count, searchList, setQuery, handleQuery, handleFilter, setSwitch, tabFilter, setTabFilter, query, reload, downloadCsv }
}

export default useLoadList;
