import React, {useEffect, useState, useCallback, useRef} from 'react';
import styled, { css } from 'styled-components';
import { Formik, Form, Field } from 'formik';
import axios from '../../axios';
import {Grid, Paper, Button, InputAdornment, LinearProgress,
  CircularProgress, MenuItem, InputLabel, FormControl, FormHelperText, FormControlLabel} from '@material-ui/core';
import { TextField, Select } from 'formik-material-ui';
import * as Yup from 'yup';
import { handleErrors, createNewOption } from '../../utils/util';
import {useSBContext} from '../../utils/snackbar';

import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import PostAddRoundedIcon from '@material-ui/icons/PostAddRounded';

import DisplayWorkOrderErrors from '../shipments/WorkOrderErrors';

import Sidebar from './Components/Sidebar';

import { debounce, isEmpty } from 'lodash'

import { SectionHeader, SectionHeaderRow, FullDivider } from '../../utils/common'

import systemColors from '../../utils/colors';

import EditForm from '../../utils/editForm';

import Autocomplete from '../../utils/simpleAutoComplete';

import { theme } from '../../utils/theme';

import DatePicker from "../../utils/DatePicker";

import LineItem from './Components/LineItem';


const Row = styled(Grid)`
  min-height: 10rem;
  /* width: 100%; */
  /* background-color: pink; */
  padding-top: 4rem;
`
 
const AddInventory = (props) => {

  const {id} =  props.match.params;
 
  const [info, SetInfo] = useState({});

  const [loading, setLoading] = useState(false)

  const [brand, setBrand] = useState("")

  const [orderLineItems, setOrderLineItems] = useState([
  //   {
  //   lengthNum: "",
  //   color: "",
  //   capSize: "",
  //   baseMaterial: "",
  //   line: "",
  //   hairstyle: "",
  //   quantity: 1,
  //   price: 0,
  //   canEdit: false
  // }
])

  const [woErrors, setWoErrors] = useState({}) // work order errors
  const [workOrders, setWorkOrders] = useState([]);
  const [wigs, setWigs] = useState([])

  const [includeMaterial, setIncludeMaterial] = useState(false);
  const [includeColor, setIncludeColor] = useState(false);

  const [costModel, setCostModel] = useState({});
  const [priceModel, setPriceModel] = useState({});

  const [systemValues, setSystemValues] = useState({
    lengths: [],
    colors: [],
    capSizes: [],
    baseMaterials: [],
    capSizes: [],
    hairstyles: [],
    lines: []
  });

  const getRequiredFields = () => {
    let requiredFields = ["lengthNum", "color", "capSize"]
    const { materials = [], hairstyles = [], lines = [] } = systemValues;
    if (materials.length) requiredFields.push("baseMaterial");
    if (hairstyles.length) requiredFields.push("hairstyle");
    if (lines.length) requiredFields.push("line");
    return requiredFields;
  }

  const addWigs = (code, index, info) => {
    if (!code) {
      return;
    }
    let existingCode = wigs.find(el => el.wigId === code)
    if (existingCode) {
      return;
    }
    let requiredFields = getRequiredFields();
    if (requiredFields.some(field => !info[field])) {
      handleClick("warning", "Please fill in all required fields.")
      return;
    }
    setWigs(oldWigs => {
      return oldWigs.concat({
        wigId: code,
        id: index,
        ...info
      })
    })
    let qty = orderLineItems[index].qty
    setLineItem({target: {value: qty + 1}}, index, "qty")
    code = "";
  }

  const removeWig = (code, index) => {
    setWigs(oldWigs => oldWigs.filter(wig => wig.wigId !== code))
    let qty = orderLineItems[index].qty
    setLineItem({target: {value: qty - 1}}, index, "qty")
  }


  const [newDate, setNewDate] = useState(new Date());

  const handleChangeWorkOrder = useCallback(debounce(async val => {
    const {data} = await axios.get(`/work_orders?term=${encodeURIComponent(val)}`)
    setWorkOrders(data?.docs);
    return val;
  }, 250), [])

  const addLineItem = (lineItem) => {
    let rand = Math.random();
    let newItem = {...lineItem, rand};
    setOrderLineItems(oli => oli.concat(newItem));
  }

  const removeLineItem = (index) => {
    setOrderLineItems(oli => {
      let newItems = [...oli];
      newItems.splice(index, 1);
      return newItems;
    })
    setWigs(oldWigs => oldWigs.filter(wig => wig.id !== index));
  }

  const setLineItem = (e, index, field) => {
    const val = e.target.value || e.target.checked;
    let concatVal = "";
    setOrderLineItems(oli => {
      let newItems = [...oli];
      let item = newItems[index];
      item[field] = val;
      concatVal = item.lengthNum?.toString();
      if (includeMaterial) {
        concatVal += item.baseMaterial;
      }
      if (includeColor) {
        concatVal += item.color;
      }
      let qty = item.qty

      if (!item.canEdit) {
        if (costModel[concatVal]) {
          item.cost = costModel[concatVal];
        } else {
          item.cost = 0;
          item.price = 0;
        }

        if (priceModel[concatVal]) { // NEW!!
          item.retail = priceModel[concatVal];
        } else {
          item.retail = 0;
        }
      }
      if (+qty) {
        item.price = item.cost * qty;
      } else {
        item.qty = 0;
        item.price = 0;
      }
      return newItems;
    })

  }

  const loadData = async () => {
    try {
      setLoading(true)
      const promises = [axios.get('/styles/'), axios.get('/system_settings/system_values'), axios.get(`/system_settings/pricing?brand=${brand}&getSchema=true&includePrice=true`)];
      const [styles, systemValues, pricing] = await Promise.all(promises);
      let sysValueSettings = systemValues.data.settings;
      setSystemValues({
        lengths: sysValueSettings.lengths,
        colors: styles.data,
        capSizes: sysValueSettings.capSizes,
        materials: sysValueSettings.materials,
        hairstyles: sysValueSettings.hairstyles,
        lines: sysValueSettings.lines
      })

      let pricingData = pricing.data?.prices;
      let costSchema = pricing.data?.costSchema || {};
      let priceSchema = pricing.data?.priceSchema || {};
      setCostModel(costSchema);
      setPriceModel(priceSchema);
      if (pricingData?.settings?.includeMaterial) {
        setIncludeMaterial(true);
      }
      if (pricingData?.settings?.includeColor) {
        setIncludeColor(true);
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  useEffect(() => {
      loadData()
  }, [])


  const shipNum = useRef()

  const action = id ? 'edit' : 'add';
  const successMsg = action === "edit" ? "Inventory successfully updated" : "Inventory successfully added";

  let crumbs = [{path: '/inventory/', text: 'Inventory'}];

  if (action === "edit")
    crumbs.push({path: `/inventory/view/${id}`, text: 'View'})

  crumbs.push({path: '/inventory/edit', text: action === "edit" ? 'Edit' : 'Add'});

  const EditSchema = Yup.object().shape({
   
  });

  const {handleClick} = useSBContext();

  const onSubmit = async (userInfo={}, { setSubmitting, setErrors}) => {

    if (!wigs.length) {
      return handleClick("error", "Please add wigs")
    }

    let hangingProducts = false;
    orderLineItems.forEach((cur, i) => {
      let findWigs = wigs.find(el => el.id === i)
      if (!findWigs) hangingProducts = true;
    })

    if (hangingProducts) {
      return handleClick("error", "Please remove products with no wigs")
    }

    let formattedWigs = wigs.map(el => {
      let {wigId, color, cost, retail, hairstyle, line, capSize, lengthNum, baseMaterial} = el;
      let item = {
        freight: userInfo.shipping || 0,
        date: userInfo.date.toLocaleDateString(),
        orderNo: shipNum?.current?.value || "",
        wigId,
        sku: color,
        lengthField: lengthNum.toString(),
        capSize,
      }
      if (baseMaterial) item.base = baseMaterial;
      if (hairstyle) item.hairstyle = hairstyle;
      if (line) item.line = line;

      item.cost = cost || 0
      item.retail = retail || 0

      return item;
    })

    console.log("getting here", formattedWigs);

    try {
      let query = !id ? axios.post('/shipments/', {wigs: formattedWigs, isManual: true}) : axios.put(`/shipments/${id}`, {...userInfo});

      const {data} = await query;
      setSubmitting(false)
      if (data?.errors) {
        setWoErrors(data.errors)
        handleClick('error', 'Error adding inventory. Please fix the issues and try again.')
      } else {
        handleClick('success', successMsg)
        props.history.push(`/shipments/`)
      }
    } catch (e) {
      setSubmitting(false);
      const displayMessage = handleErrors({e, setErrors})
      if (displayMessage)
        handleClick('error', displayMessage)
    }
  }
 
 return (
 <EditForm title={action === "edit" ? "Edit Inventory" : "New Inventory"} header="Inventory" crumbs={crumbs}
  sidebar={<Sidebar lineItems={orderLineItems} shipmentNum={shipNum} />}
 >
   {loading ? <LinearProgress /> :
   <Formik
    enableReinitialize
    initialValues={
      info.vendor ? {...info, vendor: info?.vendor?.id} : 
      {
        date: newDate,
        shipping: "",
        notes: "",
      }
    }
    validationSchema={EditSchema}
    onSubmit={onSubmit}
   >
    {
      ({submitForm, isSubmitting, setFieldValue, isValidating, setErrors, errors, touched, values}) => (
        <Form>
          {/* {console.log(errors, values, "checkem")} */}
          <Grid container spacing={4}>

          <SectionHeaderRow item xs={12}>
              <SectionHeader>General Info</SectionHeader>
            </SectionHeaderRow>

            {console.log(values,  "values")}

            <Row item xs={12} lg={6}>
              <Autocomplete
                list={workOrders}  
                label="Shipment Number (Optional)"
                getOptionLabel={({orderId}) => orderId || ""} 
                getOptionSelected={({orderId}, val) => orderId === val.orderId}
                renderOption={(opt) => <span>{opt.orderId}</span>}
                handleChange={handleChangeWorkOrder}
                blur
                inputRef={shipNum}
                freeSolo
              />
            </Row>

            <Row item xs={12} lg={6}>
              <DatePicker
                label="Shipment Date"
                name="date"
                dtb
                fullWidth
              />
            </Row>

           {!isEmpty(woErrors) ? <Grid item xs={12}>
              <FullDivider />
            </Grid> : null }

            {!isEmpty(woErrors) ? <SectionHeaderRow item xs={12}>
              <SectionHeader>Work Order Issues</SectionHeader>
            </SectionHeaderRow> : null }

            {!isEmpty(woErrors) ? <Grid item xs={12}>
              <DisplayWorkOrderErrors errors={woErrors} />
            </Grid> : null}

            <Grid item xs={12}>
              <FullDivider />
            </Grid>

            <SectionHeaderRow item xs={12}>
              <SectionHeader>Inventory Items</SectionHeader>
            </SectionHeaderRow>

            <Row item xs={12}>
              {
                orderLineItems.map((item, i) => (
                  <LineItem wigs={wigs.filter(w => w.id === i)} addWigs={addWigs} removeWig={removeWig} key={i} itemInfo={item} index={i} setLineItem={setLineItem} systemValues={systemValues} remove={removeLineItem} />
                ))
              }
              <Button
                startIcon={<PostAddRoundedIcon />}
                variant="outlined"
                color="secondary"
                onClick={() => addLineItem({price: 0, qty: 0})}
              >Add Line Item</Button>
            </Row>

            <Grid item xs={12}>
              <FullDivider />
            </Grid>

            <SectionHeaderRow item xs={12}>
              <SectionHeader>Additional Charges</SectionHeader>
            </SectionHeaderRow>

            <Row item xs={3}>
              <Field
                component={TextField}
                type="number"
                label="Total Shipping Cost"
                name="shipping"
                variant="outlined"
                fullWidth
              />
            </Row>

            <Grid item xs={12}>
              <FullDivider />
            </Grid>

            <SectionHeaderRow item xs={12}>
              <SectionHeader>Notes</SectionHeader>
            </SectionHeaderRow>

            <Row item xs={12}>
            <Field
              component={TextField}
              variant="outlined"
              fullWidth
              multiline
              minRows={3}
              name="notes"
              label="Notes"
              />
            </Row>

            <Grid xs={12} className="submit" item >
            <Button 
            type="reset"
            color="primary"
            disabled={isSubmitting}
            variant="outlined"
            className="button_reset"
            >Clear</Button>
            {isSubmitting ? <CircularProgress /> : 
            <Button 
            type="submit"
            color="primary"
            disabled={isSubmitting}
            variant="contained"
            startIcon={<SaveRoundedIcon />}
            >Save</Button>
            }
            </Grid>
          </Grid>
        </Form>
      )
    }
   </Formik>
  }
 </EditForm>
 )
}

export default AddInventory;
