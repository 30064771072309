import React from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';

import { theme } from '../../utils/theme';

import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import PersonPinRoundedIcon from '@material-ui/icons/PersonPinRounded';
import RoomIcon from '@material-ui/icons/Room';
import CreditCard from '@material-ui/icons/CreditCard';
import Redeem from '@material-ui/icons/Redeem';
import WigIcon from '../../utils/WigIcon';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import Search from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import LockRoundedIcon from '@material-ui/icons/LockRounded';
import LockOpenRoundedIcon from '@material-ui/icons/LockOpenRounded';

const StyledLink = styled(Link)`
  display: block;
  padding: 0 !important;
  margin: 0 !important;
  color: inherit;
  min-height: auto;
  height: auto;

  svg {
    display: block;
  }
`;


// styles
const MainBar = styled(Card)`
  /* margin: 1rem 0; */
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  background-color: #f6f6f6;
`;

const InfoRow = styled.div`
  display: flex;
  gap: 3rem;
  /* justify-content: space-between; */
`;

const ActionBar = styled.div`
  display: flex;
  box-shadow: ${props => props.theme.scShadows.light};
  border-radius: 6px;
  background-color: #fff;
`;

const ActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: ${props => props.bg}; */
  color: ${props => props.color};
  /* height: 6rem; */
  width: 6rem;
  border-right: 1px solid ${props => props.theme.colors.border};

  &:last-child {
    border-right: none;
  }

  &:hover {
    background-color: ${props => props.theme.colors.headerBg};
    cursor: pointer;
  }
`;

const InfoTile = styled.div`
  display: flex;
  align-items: center;
  box-shadow: ${props => props.theme.scShadows.light};
  /* height: 6rem; */
  /* padding: .6rem; */
  border-radius: 6px;
  min-width: 20rem;
  padding-right: 1.5rem;
  background-color: #fff;
`;

const StatCard = styled.div`
  display: flex;
  align-items: center;
`;

const IconBox = styled.div`
  display: flex;
  background-color: ${props => props.bg};
  color: ${props => props.color};
  height: 6rem;
  width: 6rem;
  border-radius: 6px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: 1.5rem;
  justify-content: center;
  align-items: center;
`;

const StatInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: .7rem;
  /* height: 100%; */
  /* padding: .3rem 0; */
`;

const StatHeader = styled.span`
  display: block;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  color: ${props => props.theme.colors.lightText};
`;

const Stat = styled.span`
  display: block;
  font-weight: 500; 
  font-size: 1.4rem;
`;


const showCurrentLocation = (info) =>  (info?.currentLocation?.entity === "hq" && info?.status !== "stock") || info?.currentLocation?.entity === "repair"

const LinkItem = ({op, linkId, shipment, children}) => {
  let collection = ""
  let isAddedToStock = op === 'added to stock' ? true : false
  switch (op) {
    case "added to stock": 
      collection = "shipments"
      break;
    case "returned":
      collection = "consignments"
      break;
    case "sent on consignment":
      collection = "consignments"
      break;
    case "sold":
      collection = "orders"
      break;
    default:
      break;
  }
  return <StyledLink to={`/${collection}/view/${isAddedToStock ? shipment : linkId}`} target="_blank">{children}</StyledLink>
}


const Icon = props => {

  let chooseIcon;

  let choose = (props.choose).toLowerCase();

  if (choose.includes('stock') || choose.includes('returned')){
    chooseIcon = <WigIcon fontSize={props.fontSize} />
   } else if (choose.includes('consignment')) {
     chooseIcon = <Redeem fontSize={props.fontSize} />
   } else {
     chooseIcon = <CreditCard fontSize={props.fontSize} />
   }

  return (
    <>
    {chooseIcon}
    </>
  )
}


const StatsBar = ({ info, currently, commit }) => {

  const current = info.history?.at(-1) || {};

  const wigItem = {
    brand: info.brand,
    sku: info.sku,
    wigId: info.wigId,
    price: info.sale?.subtotal,
    title: info.title,
    description: info.description,
    baseMaterial: info.baseMaterial,
    currentlyAt: info.currentlyAt,
    status: info.status
  }

  return (
    <MainBar variant="outlined">
      <InfoRow>
        <InfoTile>
          <StatCard>
            <IconBox color={theme.colors.icon2L} bg={theme.colors.iconBg2}>
            <Icon fontSize="large" choose={currently.status} />
            </IconBox>
          </StatCard>
          <StatInfo>
            <StatHeader>Status</StatHeader>
            <Stat>{currently.status}</Stat>
          </StatInfo>
        </InfoTile>
        {info.status !== "stock" && <InfoTile>
          <StatCard>
            <IconBox color={theme.colors.icon1L} bg={theme.colors.iconBg1}>
            <PersonPinRoundedIcon fontSize='large' />
            </IconBox>
          </StatCard>
          <StatInfo>
            <StatHeader>Customer</StatHeader>
            <Stat>{currently.name}</Stat>
          </StatInfo>
        </InfoTile>
        }
        { info.isAssigned &&
          <InfoTile>
            <Link to={`/consignments/view/${info.assignedRef}`}>
              <StatCard>
                <IconBox color={theme.colors.icon3L} bg={theme.colors.iconBg3}>
                    <AssignmentTurnedInIcon fontSize='large' />
                </IconBox>
              </StatCard>
            </Link>
            <StatInfo>
              <StatHeader>Assigned To</StatHeader>
              <Stat>{info.assignedTo?.name}</Stat>
            </StatInfo>
          </InfoTile>
        }
        {showCurrentLocation(info) &&
          <InfoTile>
          <StatCard>
            <IconBox color={theme.colors.iconCaution} bg={theme.colors.iconBgCaution}>
            <RoomIcon fontSize='large' />
            </IconBox>
          </StatCard>
          <StatInfo>
            <StatHeader>in repair</StatHeader>
            <Stat>{info?.currentLocation?.entity === "repair" ? info?.currentLocation?.name : "HQ"}</Stat>
          </StatInfo>
        </InfoTile>
        }
      </InfoRow>
      <ActionBar>
        <ActionButton color={theme.colors.icon4L}>
          <Tooltip title="View">
            <div>
              <LinkItem op={current.op} linkId={current.refId} shipment={info.shipment}>
                <Search />
              </LinkItem>
            </div>
          </Tooltip>
        </ActionButton>
        {info.status === "consignment"  && <ActionButton onClick={() => commit(info?.availability)} color={theme.colors.icon2L} >
          <Tooltip title={info?.availability !== "committed" ? "Commit Wig" : "Uncommit Wig"}>
             {info?.availability !== "committed" ? <LockRoundedIcon /> : <LockOpenRoundedIcon />}
          </Tooltip>
        </ActionButton> }
        {info.status === "stock"  && <ActionButton color={theme.colors.icon3L} >
          <Tooltip title="Create Consignment">
            <StyledLink to={{pathname: `/consignments/edit`, state: wigItem }} >
              <CardTravelIcon />
            </StyledLink>
          </Tooltip>
        </ActionButton> }
        {info.status !== "sold" && <ActionButton color={theme.colors.icon1L}>
          <Tooltip title="Create Sale">
            <StyledLink to={{pathname: `/orders/edit`, state: wigItem }} >
              <ShoppingCartIcon />
            </StyledLink>
          </Tooltip>
        </ActionButton>
        }
      </ActionBar>  
    </MainBar>
  )
};

export { StatsBar, Icon, LinkItem };