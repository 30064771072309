import React, {useEffect, useState, useCallback, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Formik, Form, Field, FieldArray } from 'formik';
import axios from '../../axios';
import {Grid, Paper, Button, InputAdornment, LinearProgress,
  CircularProgress, Divider, Tooltip, IconButton, Radio, FormLabel, FormControlLabel, FormHelperText, RadioGroup, TextField as MuiTextField} from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import ReportProblemRoundedIcon from '@material-ui/icons/ReportProblemRounded';
import Remove from '@material-ui/icons/Remove';
import Fab from '@material-ui/core/Fab';
import { debounce, isEmpty } from 'lodash'
import { TextField} from 'formik-material-ui';
import * as Yup from 'yup';
import { handleErrors, capitalize, autoCompleteRender } from '../../utils/util';
import { useAuthContext } from '../../auth';
import {useSBContext} from '../../utils/snackbar';

import SearchComponent from './SearchComponent';

import ConsignedWigPicker from './common';

import { Flex } from '../../utils/common';

import { useCartContext } from '../cart/cartContext';

import CustomChip from '../../utils/CustomChip';

import Autocomplete from '../../utils/autoComplete';

import EditForm from '../../utils/editForm';

import AlertDialog from '../../utils/dialog';

import BarcodeComponent from '../../utils/BarcodeScanner';


const Row = styled(Grid)`
  min-height: 10rem;
  /* width: 100%; */
  /* background-color: pink; */
  /* padding-top: 4rem; */
  padding-top: 2.5rem;

  .legend {
    font-size: 1.4rem;
  }
`

const Notice = styled(FormHelperText)`
  color: ${props => props.theme.colors.caution};
  font-weight: 500;
  display: flex;
  align-items: center;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 5px;
  }
`

const ItemContainer = styled(Paper)`
  padding: .5rem 15px;
  margin: 0 auto;
  margin-bottom: 14px;
  margin-top: 14px;
  position: relative;
  width: 97%;
  border-left: 3px solid ${props => props.err ? props.theme.colors.error : props.theme.colors.secondaryMain};

  .heading-wigItem {
    position: absolute;
    top: -.8rem;
    left: 1.5rem;
    color: ${p => p.theme.colors.lightText};
    background-color: #f4f4f4;
    border-radius: 5px;
    padding: 0.2rem 1rem;
    font-size: 1.2rem;
    border: 1px solid #0000001f;
    font-weight: 600;

    .sku {
      display: inline-block;
      padding-left: 2.5rem;
      letter-spacing: 1px;
    }
  }

  .fab-buttons {
  /* position: absolute;
  right: 10px;
  top: 2px; */
}
`

const ConsignmentItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ItemCountLabel = styled.span`
  display: block;
  font-size: 1.2rem;
  color: ${props => props.theme.colors.primaryMain};
  font-weight: 600;
  text-transform: uppercase;
  margin-right: 4rem;
  background-color: ${props => props.theme.colors.primary95};
  border: 1px solid ${props => props.theme.colors.primaryVeryLight};
  padding: .4rem 1.2rem;
  border-radius: 3px;
`

const StatusChip = styled(CustomChip)`
  display: inline-block;
  margin-left: 5.5rem;
`

const FullDivider = styled(Divider)`
  margin: auto -50px;
`

const InfiniteDivider = styled(Divider)`
  margin-right: -100px;
  margin-left: -100px;
`

const SectionHeaderRow = styled(Grid)`
  height: 2.5rem;
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
  `

const SectionHeader = styled.h5`
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
  color: #787878;
  font-weight: normal;
`

const SaveButton = styled(Button)`
  margin-left: 1rem;
`

 
export default (props) => {

  const history = useHistory();

  const location = useLocation()

  const { user } = useAuthContext()

  const scanning = user.system?.settings?.scanning || {}

  const requireScanning = scanning?.consignment ? true : false;

  const [wigErrors, setWigErrors] = useState({})

  const [isSaved, setIsSaved] = useState(false)

  const [resolveDiff, setResolveDiff] = useState(true)

  const [pathname, setPathname] = useState()

  const [submitDraft, setSubmitDraft] = useState(false)

  const [dialogOpen, setDialogOpen] = useState(false)

  const [diffCust, setDiffCust] = useState(false)

  const [consignedWigs, setConsignedWigs] = useState([])

  const [isValidating, setIsValidating] = useState(false)

  const { cartItems, clearCart } = useCartContext()
  

  const {id} =  props.match.params;

  const [cust, setCust] = useState([]);

  const [wigs, setWigs] = useState([]);

  const [loading, setLoading] = useState(id ? true : false)

  const [info, setInfo] = useState({});


  const load = async () => {
    try {
      const {data} = await axios.get(`/consignments/${id}`)
      // console.log(data, "chchch");
      setInfo(data);
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (id) {
      load()
    }

    if (location.state?.wigId) {
      const {wigId, brand, sku, currentlyAt, status, sale, price, title, description, baseMaterial} = location.state;

      console.log(wigId, brand, sku, currentlyAt, status, sale, price, title, description, baseMaterial, "cururur");
      const wig = {
        brand,
        sku,
        wigId,
        status,
        title,
        description,
        base: baseMaterial,
        currentlyAt: currentlyAt?.name || "",
        price: price ? price : sale?.subtotal
      }
      if (info.wigs && info.wigs.length) {
        setInfo(x => {return {...x, wigs: x.wigs.concat({
          ...wig
        })}})
      } else {
        setInfo(x => {return {...x, wigs: [{...wig}]}})
      }
    }

    if (location.state?.cart) {
      // console.log(cartItems, "hhehhe");

      if (info.wigs && info.wigs.length) {
        setInfo(x => {return {...x, wigs: x.wigs.concat(cartItems)}})
      } else {
        setInfo(x => {return {...x, wigs: [...cartItems]}})
      }
    }
  }, [])

  useEffect(() => {
      const unblock = history.block((location) => {
        setPathname(location.pathname)
        if (!isSaved) {
          setDialogOpen(true)
          return false;
        } else {
          return true
        }
      }
      )

      if (isSaved && pathname) {
        // console.log("ehehhehhehhe");
        unblock()
        if (pathname)
          history.push(pathname)
      }

      return () => {
        unblock();
      }
  }, [isSaved])

  
 
  const handleChangeCust = useCallback(debounce(async val => {
    const {data} = await axios.get(`/customers?term=${encodeURIComponent(val)}`)
    setCust(data);
    return val;
  }, 250), [])

  const handleChangeWigs = useCallback(debounce(async (val, e) => {
    if (e) {
      // NEW!! added availability option to limit committed wigs
      const {data} = await axios.get(`/inventory?term=${encodeURIComponent(val)}&status=inventory&availability=true`)
      setWigs(data);
    }
  }, 250), [])

  const successMsg = !id ?  "Consignment successfully created" : 'Consignment successfully updated';

  let crumbs = [{path: '/consignments/', text: 'Consignments'}, {path: id ? `/consignments/edit/${id}` : '/consignments/edit', 
  text: id ? 'Edit' :'Add'}];

  if (id) {
    crumbs.push({path: `/consignments/edit/${id}`, text: `${info?.consignmentId || ""}`})
  }


  const EditSchema = Yup.object().shape({
    customer: Yup
      .string()
      .required('Customer is required.'),
    // email: Yup
    //   .string()
    //   .required('Email is required.')
    //   .email('Not a valid email')
  });

  const {handleClick} = useSBContext();

  const displayMessage = (setSubmitting, msg, status = "error") => {
    setSubmitting(false)
    return handleClick(status, msg)
  }

  const checkWigs = (userInfo) => {
    let wigs = [];
    let errMsg = "";

    if (userInfo.wigs?.length) {
      wigs = userInfo.wigs.filter(el => el.wigId)
    }

    if (!wigs.length) {
      errMsg = "There are no wigs added to the consignment"
      return {errMsg, wigs};
    }

    let wigObj = {}
    let duplicates = false
    let errs = {}

    wigs.forEach(el => {
        if (wigObj[el.wigId]) {
          errs[el.wigId] = true
          return duplicates = true
        } else {
          wigObj[el.wigId] = el.wigId
        }
    });

    if (duplicates)
    {
      setWigErrors({...errs})
      errMsg = "You cannot add same wig twice"
      return {errMsg, wigs};
    }

    return {errMsg, wigs};
  }

  const sendRequest = async (query, setSubmitting, setErrors) => {
    setSubmitting(true)
    try {
      const {data} = await query;
      setSubmitting(false)
      if (location?.state?.cart) {
        clearCart()
      }
      handleClick('success', successMsg)
      props.history.push(`/consignments/view/${id || data._id}`)
    } catch (e) {
      setSubmitting(false);
      const displayMessage = handleErrors({e, setErrors})
      if (displayMessage)
        handleClick('error', displayMessage)
    }
  }

  const saveDraft = async (values, setSubmitting, setErrors) => {

    setIsSaved(true)
    let check = checkWigs(values)

    if (check.errMsg) {
      return displayMessage(setSubmitting, check.errMsg)
    } 
    
    let query = axios.post('/consignments/draft', {...values, id});
    await sendRequest(query, setSubmitting, setErrors)
  }

  // NEW! only for check-in and using fromCustomer to check
  // for wigs from different customers
  const checkWigsFromCustomer = (fromCustName, wigs) => {
    let diff = false
    wigs.forEach(el => {
      if (el.currentlyAt && el.currentlyAt !== "Stock" && el.currentlyAt !== fromCustName) {
        diff = true
      }
    })
    return diff
  }

  const checkDiffCust = (values) => {
    // only for check-in and only when editing (id)
    if (id && info.from?.id && values.fromCustomer) {
      if (info.from?.id !== values.fromCustomer) {
        return true
      }
    }
    return false;
  }

  const onSubmit = async (userInfo={}, { setSubmitting, setErrors}) => {

    setIsSaved(true)
    let check = checkWigs(userInfo)
    if (check.errMsg) {
      return displayMessage(setSubmitting, check.errMsg)
    } 

    //NEW! check if wigs are from different customers for check-ins
    if (userInfo.action !== "consignment" && userInfo.fromCustomer) {
      let diff = checkWigsFromCustomer(custRef?.current?.value, userInfo.wigs)
      if (diff) {
        return displayMessage(setSubmitting, "Cannot check-in wigs from different customers when using 'Returning Customer' field")
      }
    }
    //NEW! check if returning customer was changed
    if (checkDiffCust(userInfo)) {
      return displayMessage(setSubmitting, "Cannot change the 'Returning Customer' field when editing a consignment")
    }

    userInfo.status = info.status

    userInfo.checkWig = []

    let query = !id ? axios.post('/consignments/', {...userInfo}) : axios.put(`/consignments/${id}`, {...userInfo});
    await sendRequest(query, setSubmitting, setErrors)
  }

  const checkDiff = (userInfo={}, { setSubmitting, setErrors, ...rest}) => {
    setIsValidating(true)
    console.log(rest.errors, "dfdfjdklj")
    if (!isEmpty(rest.errors)) {
      setSubmitting(false)
      return;
    }
    let { wigs } = userInfo;
    let curCust = custRef.current?.value

    if (wigs.length && userInfo.action === "consignment") {
      let diff = false
      wigs.forEach(el => {
        if (el.currentlyAt && el.currentlyAt !== curCust) {
          diff = true
        }
      })
      if (diff) {
        setSubmitting(false)
        return setDiffCust(true)
      }
    }
    onSubmit(userInfo, { setSubmitting, setErrors, ...rest})
  }


  const toggleAction = async (val, sfv) => {
    if (val === "checkIn") {
      const {data} = await axios.get(`/customers/stock`)
      sfv("customer", data._id);
    } else {
      // custRef.current.value = ""
      sfv("customer", "")
    }
  }

  const custRef = useRef();

  const searchRef = useRef()


 return (
 <EditForm title={id ? <>Edit Consignment {info?.status === "draft" ? <StatusChip size="large">Draft</StatusChip> : null}</> : "New Consignment"} header="Consignment" crumbs={crumbs}>
   {loading ? <LinearProgress /> : 
   <Formik
    enableReinitialize
    // validateOnChange={false}
    initialValues={{
      customer: info.to?.id || '',
      fromCustomer: info.from?.id || '',
      action: info.isCheckIn ? "checkIn" : "consignment",
      wigs: info.wigs || [],
      consignmentId: info.consignmentId || "",
      delivery: info.delivery || {amount: 0},
      notes: info.notes || '',
      checkWig: []
    }}
    validationSchema={EditSchema}
    onSubmit={checkDiff}
   >
    {
      ({ submitForm, isSubmitting, setFieldValue, values, errors, touched, setErrors, setSubmitting }) => (
        <Form>

          {console.log(values.wigs, "wigs")}
          <AlertDialog
            forceOpen={dialogOpen}
            forceClose={() => setDialogOpen(false)}
            title="Confirm Leave Page"
            save="Confirm Leave"
            onSave={() => {
              setIsSaved(true)
              return "done"
            }}
          >
            Are you sure you want to leave the page without saving your Changes?
          </AlertDialog>
          <AlertDialog
            forceOpen={diffCust}
            forceClose={() => setDiffCust(false)}
            title="Confirm Save"
            save="Confirm Save"
            onSave={() => {
              onSubmit(values, { setErrors, setSubmitting})
              return "done"
            }}
          >
            Some of the wigs on the consignment, are currently consigned to other customers. Do you wish to proceed?
          </AlertDialog>
          <Grid container spacing={4}>

            {console.log(custRef?.current?.value)}

            <SectionHeaderRow item xs={12}>
              <SectionHeader>General Info</SectionHeader>
            </SectionHeaderRow>

            <Row item xs={5}>
            <FormLabel component="legend" className='legend' style={{paddingBottom: "1rem"}}>Consignment Type</FormLabel>
              <Field name="action" row>
              {({
                  field : {onChange, ...fieldBag}, // { }
                  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                <>
                  <RadioGroup 
                    {...fieldBag}
                    onChange={(e) => {
                      toggleAction(e.target.value, setFieldValue)
                      onChange(e)
                    }} 
                    row>
                  <FormControlLabel
                    value="checkIn"
                    control={<Radio />}
                    label="Check-In"
                  />
                  <FormControlLabel
                    value="consignment"
                    control={<Radio />}
                    label="Consignment"
                  />
                  </RadioGroup>
                  <FormHelperText style={{marginTop: 0}}>Select Check-In to return items to Stock</FormHelperText>
                </>
                )}
              </Field>
              
            </Row>

            <Row item xs={6}></Row>


        {/* {console.log(values.customer)} */}

           {values.action === "consignment" ? <Row item xs={8}>
            <Autocomplete
               list={cust} name="customer" 
               field={'_id'} 
               label="Customer Name"
               getOptionLabel={({name}) => name || ""} 
               getOptionSelected={({name}, val) => name === val.name}
               renderOption={(opt) => <span>{opt.name}</span>}
               handleChange={handleChangeCust}
               autoHighlight
               autoSelect
               inputRef={custRef}
               blur
               setter={setFieldValue}
               value={{name: info?.to?.name}}
               err={errors.customer && isValidating ? errors.customer : ''}
              />
            </Row> : <Row item xs={8}>
            <Autocomplete
               list={cust} name="fromCustomer" 
               field={'_id'} 
               label="Returning Customer Name (Optional)"
               getOptionLabel={({name}) => name || ""} 
               getOptionSelected={({name}, val) => name === val.name}
               renderOption={(opt) => <span>{opt.name}</span>}
               handleChange={handleChangeCust}
               autoHighlight
              //  autoSelect
               inputRef={custRef}
               blur
               setter={setFieldValue}
               value={{name: info?.from?.name}}
               err={errors.fromCustomer && touched.fromCustomer ? errors.fromCustomer : ''}
              />
              <Notice><ReportProblemRoundedIcon /> If selected, only wigs from this customer can be added to check-in</Notice>
            </Row> }

            <Grid item xs={12}>
                <FullDivider />
            </Grid>     

              <ConsignedWigPicker values={values} setFieldValue={setFieldValue} custId={values?.fromCustomer} custName={custRef?.current?.value} consignedWigs={consignedWigs} setConsignedWigs={setConsignedWigs} />

            <SectionHeaderRow item xs={12}>
              <ConsignmentItemRow>
              <SectionHeader>Consignment Items</SectionHeader>
             {values.wigs?.length ? <ItemCountLabel>{values.wigs?.length} Items</ItemCountLabel> : null}
              </ConsignmentItemRow>
            </SectionHeaderRow>

            {!values?.fromCustomer ?  <Grid item xs={12}>
              <SearchComponent wigs={values.wigs} setFieldValue={setFieldValue} />
            </Grid> : null }

            <FieldArray
             name="wigs"
             render={arrayHelpers => (
               <>
                 {values.wigs && values.wigs.length > 0 ? (
                   values.wigs.map((wig, index) => (
                     <ItemContainer key={`${wig.wigId ? wig.wigId : wig.random}`} variant='outlined' err={wigErrors[wig.wigId] ? true : false}>
                       <Row container item xs={12} spacing={3} alignItems="center">
                       {wig.wigId ? <div className='heading-wigItem'>{capitalize(wig.status)} {wig.currentlyAt ? `- ${wig.currentlyAt}` : null} <span className='sku'>{wig.skuDisplay || wig.sku}</span></div> : null}
                        <Grid item xs={8}>
                        
                        <Autocomplete
                          list={wigs} name={`wigs.${index}.wigId`} 
                          index={index} arrList="wigs" field="wigId" 
                          label="Wig ID"
                          getOptionLabel={({wigId}) => wigId || ""} 
                          getOptionSelected={({wigId}, val) => wigId === val.wigId}
                          renderOption={autoCompleteRender}
                          handleChange={handleChangeWigs}
                          autoHighlight
                          autoSelect
                          blur
                          setter={setFieldValue}
                          value={{wigId: values.wigs[index].wigId}}
                          updates={[{name: 'brand', field: 'brand'}, {name: 'sku', field: 'sku'}, {name: "skuDisplay", field: "skuDisplay"}, {name: 'title', field: 'title'}, {name: 'description', field: 'description'}, {name: 'base', field: 'baseMaterial'}, {name: 'price', field: 'sale.subtotal'}, {name: 'status', field: "status"}, {name: 'currentlyAt', field: "currentlyAt.name"}, {name: 'searchWigId', field: 'searchWigId'}]}
                          size="small"
                        />
                         
                        </Grid>

                        <Grid item xs={3}>
                          <Field
                            component={TextField}
                            type="number"
                            label="Price"
                            name={`wigs.${index}.price`}
                            variant="outlined"
                            size="small"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={1}>
                          <Tooltip title="Delete Item" className="fab-buttons">
                            <IconButton aria-label="delete" onClick={() => {
                                setWigErrors(x => ({...x, [wig.wigId]: false}))
                              arrayHelpers.remove(index)
                              }}>
                              <DeleteIcon color="secondary" />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      
                     </Row>
                     </ItemContainer>
                   ))
                 ) : null
                 }
               {!values?.fromCustomer ?  <Row item xs={4}>
                  <Flex dir="row" justify="flex-start" pad="0" height="7.5rem">
                  {!requireScanning ? <Button
                      variant="contained"
                      color="primary"
                      startIcon={<Add />}
                      onClick={() => arrayHelpers.push({price: 0, wigId: '', random: Math.random()})}
                    >
                      Add Wig
                    </Button> : null }
                    <BarcodeComponent wigs={values.wigs} setFieldValue={setFieldValue} required={requireScanning} />
                  </Flex>
                </Row> : null }
                {/* <Row item xs={3}>
                  <BarcodeComponent wigs={values.wigs} setFieldValue={setFieldValue} />
                </Row> */}
                 </>
             )}
           />

              <Grid item xs={12}>
                <FullDivider />
              </Grid>  

          <SectionHeaderRow item xs={12}>
            <SectionHeader>Delivery Charge</SectionHeader>
          </SectionHeaderRow>

          <Row item xs={9}></Row>

          <Row item xs={3}>
            <Field
              component={TextField}
              type="number"
              label="Delivery Charge"
              name="delivery.amount"
              variant="outlined"
              fullWidth
            />
          </Row>

           <Grid item xs={12}>
             <InfiniteDivider />
           </Grid>

           <Grid item xs={8}>
             <Field component={TextField} name="notes" label="Notes" multiline minRows={3} variant="outlined" fullWidth />
           </Grid>
         
            <Grid xs={12} className="submit" item >
            {/* <Flex dir="row" justify="flex-end" pad="0"> */}
              <Button 
              type="reset"
              color="primary"
              disabled={isSubmitting}
              variant="outlined"
              className="button_reset"
              >Clear</Button>
              {!id || info?.status === "draft" ? <Button 
              type="button"
              color="secondary"
              variant="contained"
              onClick={() => saveDraft(values, setSubmitDraft, setErrors, errors)}
              >{submitDraft ? <CircularProgress color="#fff" size={25} /> : "Save Draft"}</Button> : null}
              <SaveButton 
              // type="submit"
              color="primary"
              disabled={isSubmitting}
              variant="contained"
              startIcon={<SaveRoundedIcon />}
              onClick={() => checkDiff(values, { setSubmitting, setErrors, errors})}
              >{isSubmitting ? <CircularProgress color="#fff" size={25} /> : "Save"}</SaveButton>
            {/* </Flex> */}
            </Grid>
          </Grid>
        </Form>
      )
    }
   </Formik>
   }
 </EditForm>
 )
}