import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {Field, Formik, Form} from 'formik';

import { truncate, isEmpty } from 'lodash'

import axios from '../../axios';

import { theme } from '../../utils/theme';

import {useSBContext} from '../../utils/snackbar';

import {formatGenericDate, currencyFormat, downloadFile} from '../../utils/util';

import SampleSheet from './SampleSheet';

import DisplayWorkOrderErrors from './WorkOrderErrors';

import { Input, InputLabel, Grid ,
   Card, CardHeader, CardContent, 
   LinearProgress, Divider, IconButton, Button, TextField, FormControl,
  Select, MenuItem, CircularProgress, FormHelperText, Tooltip} from "@material-ui/core";

// mui icons
import Publish from '@material-ui/icons/Publish'
import AssignmentIcon from '@material-ui/icons/Assignment';
import Search from '@material-ui/icons/Search'
import PostAddRoundedIcon from '@material-ui/icons/PostAddRounded';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import UnfoldMoreRoundedIcon from '@material-ui/icons/UnfoldMoreRounded';
import UnfoldLessRoundedIcon from '@material-ui/icons/UnfoldLessRounded';

import Lottie from '../../utils/Lotties';
import UploadLottie from '../../images/lotties/upload-to-cloud-NT-version.json';

import OutlinedSelect from '../../utils/OutlinedSelect';

import { CommonFlex, Flex } from '../../utils/common';


import Editform from '../../utils/editForm';
import SimpleTable from '../../utils/simpleTable';

import Timeline from '@material-ui/lab/Timeline';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineContent from '@material-ui/lab/TimelineContent';

import ShipmentTable from './ShipmentTable';

import Dialog from '../../utils/dialog';

import SampleFile from '../../images/sample-sheet.jpg';

import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";

const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.darkText};
  text-decoration: none;
  `

const InfoItem = styled.span`
  display: block;
  margin-bottom: 1rem;
  font-size: 1.6rem;

`
const InfoLabel = styled.span`
  font-weight: 500;
  padding-right: 1rem;
`

const InfoHeader = styled.span`
  display: block;
  margin-bottom: 1rem;
  font-weight: 400;
  font-size: 1.8rem;
  padding-right: 1rem;
`


const Content = styled(TimelineContent)`
  position: relative;
  top: .2rem;
  font-weight: 700;
  color: ${props => props.theme.colors.lightText};
  padding: 1rem 1.5rem;
  padding-right: 0;
  line-height: 2.2rem;
  text-transform: uppercase;
  font-size: 1.2rem;
`

const FileButton = styled(InputLabel)`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 230px;
  border-radius: 3px;
  padding: 10px 20px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  /* margin-top: 2rem; */
  background-color: ${props => props.theme.colors.primaryMain};
  transition: all .3s;
  /* float: right; */
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.primary35};
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
`

const ImportButton = styled(Button)` 
  margin-top: 2rem;
`

const SelectContainer = styled.div`
  margin-right: 4rem;
  margin-bottom: .5rem;
  /* min-width: 18rem; */
  flex-grow: 1;
  max-width: 32rem;
`

const Description = styled.span`
  font-size: 1.2rem;
`

const ListNumber = styled.div`
  height: 20px;
  width: 20px;
  text-align: center;
`

const TimelineAlign = styled(Timeline)`
  align-items: flex-start;
  padding-inline-start: 0;
  &.MuiTimeline-root {
    padding-right: 0;
  }
  .MuiTimelineItem-missingOppositeContent:before {
    flex: 0;
    padding: 0;
  }
`

const GuideLineText = styled.span`
  padding-right: 1rem;
`

const GuidelineHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 40rem;
`

const GuideLineCard = styled(Card)`
 &.MuiCard-root {
  overflow: visible;
 }
`

const SampleSelector = styled.div`
  width: 10rem;
  height: 10rem;
  border-radius: 3px;
  margin: 5rem 2rem;
  margin-top: 0;
  /* background-color: #ddd; */
  background-image: linear-gradient(#eeee, #ddde), url(${props => SampleFile});
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-image: linear-gradient(#fffd, #eeed), url(${props => SampleFile});
  }
`

const SampleLink = styled.a`
  display: block;
  font-size: 1.2rem;
  text-align: center;
  font-weight: bold;
`
const SampleImg = styled.img`
  display: block;
  border: 2px solid #888;
  padding: .4rem;
  border-radius: 3px;
  margin: 2rem auto;
  width: 100%;
`
 
const AddShipment = (props) => {

  const [fileSelect, setFileSelect] = useState();
  const [loading, setLoading] = useState(false);
  const [validateLoading, setValidateLoading] = useState(false);
  const [loadingProfiles, setLoadingProfiles] = useState(false);
  const [profileHeaders, setProfileHeaders] = useState(null) 
  const [profiles, setProfiles] = useState([]);
  const [profile, setProfile] = useState({});
  const [summary, setSummary] = useState({})

  const [woErrors, setWoErrors] = useState({}) // work order errors

  const [viewGuidelines, setViewGuidelines] = useState(true)

  const [preview, setPreview] = useState({
    rows: [],
    warnings: {},
    errors: {}
  })

  const [brands, setBrands] = useState([])

  const [bLoading, setBLoading] = useState(false) // brands loading

  const [csvValues, setCsvValues] = useState({
    date: new Date(),
    brand: "",
    orderNum: "",
    profileId: ""
  })

  
  const modifyHeaders = (headers) => {
    let modHeaders = {}
      Object.keys(headers).forEach(el => {
        modHeaders[el] = headers[el].name
      })
      return modHeaders;
  }

  const handleChangeValues = (e) => {
    let name = e.target.name
    let val = e.target.value
    console.log(name,val, 'chchchchchchhchckkkkkk');
    if (name) {
      setCsvValues(x => ({...x, [name]: val}))
    }
  }

  const handleProfileChange = (e) => {
    let value = e.target.value
      setProfile(value)
      let selectedProfile = profiles.find(el => el._id?.toString() === value.toString())
      setProfileHeaders(selectedProfile ? modifyHeaders(selectedProfile.headers) : null)
      setCsvValues(x => ({...x, profileId: value}))
  }

  const { handleClick } = useSBContext();

  const downloadSampleCsv = async () => {

    const {brand, orderNum, date, profileId} = csvValues
    let dateFormat = date.toLocaleDateString()
    try {
      let url = `/shipments/download?date=${dateFormat}&brand=${encodeURIComponent(brand)}&orderNum=${orderNum}&profileId=${profileId ? profileId : ""}`
      await downloadFile({url})
    } catch (e) {
      console.log(e);
      handleClick("error", "Error downloading. Please try again.")
    } finally {
      return true;
    }
  }


  const onImportFile = async (e, stage = "upload") => {

    let file;

    if (stage === "upload") {
      file = e.target.files[0];
      setFileSelect(file);
    }

    let sheet = stage === "upload" ? file : fileSelect;

    const formData = new FormData(); 
     
    formData.append( 
      "excel", 
      sheet, 
      sheet.name
    ); 

    if (!isEmpty(preview.errors)) {
      formData.append("errors", JSON.stringify(preview.errors))
    }

    try {
      if (stage === "upload") {
        setValidateLoading(true)
      } else {
        setLoading(true)
      }
      const url = stage === "upload" ? `/shipments/check-validity?profileId=${profile ? profile : ""}` : `/shipments?profileId=${profile ? profile : ""}`
      const {data} = await axios.post(url, formData);
      setViewGuidelines(false)
      if (stage === "upload") {
        setSummary({})
        setWoErrors({})
        setPreview({
          ...data
          })
      } else {
        if (data?.errors) {
          console.log(data, "data")
          setWoErrors(data.errors)
          handleClick('error', 'Error importing the file. Please check the file and try again.')
        } else {
          setSummary(data);
          handleClick('success', 'Shipment Imported Successfully!')
        }
      }
      document.getElementById("import-button").value = ""
    } catch (e) {
      document.getElementById("import-button").value = ""
      let errorMessage = "Error uploading the file";
      if (e.response?.data) {
        errorMessage = e.response.data.message
      }
      handleClick('error', `${errorMessage}`)
    } finally {
      setValidateLoading(false)
      setLoading(false)
    }
  }

  const loadBrands = async () => {
    try {
      setBLoading(true)
      if (!brands.length) {
        const { data } = await axios.get('/brands/names')
        const brandNames = data.map(el => el.name)
        setBrands(brandNames)
        
        setCsvValues(x => ({...x, brand: data.find(el => el.isDefault).name}))
      }
      setBLoading(false)
    } catch (e) {
      setBLoading(false)
      // console.log(e);
    }
  }

  const loadProfiles = async () => {
    setLoadingProfiles(true)
    try {
      const { data } = await axios.get('/system_settings/profiles')
      setProfiles(data)
      if (data.length) {
        setProfile(data[0]._id)
        let headers = modifyHeaders(data[0].headers)
        setProfileHeaders(headers)
        setCsvValues(x => ({...x, profileId: data[0]._id}))
      } else {
        setProfile("")
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingProfiles(false)
    }
  }

  useEffect(() => {
    loadProfiles()
  }, [])  

  const styleHeaders = [
    {id: 'color', label: 'Style'},
    {id: 'qty', label: 'Qty'},
    // {id: 'price', currency: true, label: 'Price'}
  ]

  // console.log(fileSelect);

  let crumbs = [{path: '/shipments/', text: 'Shipments'}, {path: '/shipments/edit', text: 'Add'}];

  const view = () => {
    props.history.push(`/shipments/view/${summary?._id}`)
  }

  const containerXY = { 
    containerHeight: "25rem", 
    containerWidth: "auto" 
  };
  const xy = {
    height: 350,
    width: ""
  }

  const SummaryContent = () => (
    loading ? <Lottie lottieFile={UploadLottie} xy={xy} containerXY={containerXY} /> : 
      <Card variant="outlined">
        <CardHeader title="Shipment Summary" subheader={formatGenericDate(summary.newDate)}
        // action={
        //   <IconButton onClick={view}><Search /></IconButton>
        // }
        ></CardHeader>
        <CardContent>
          <Grid container spacing={3}>

            <Grid item xs={4}>
              <InfoItem><InfoLabel>Number of Orders:</InfoLabel> {summary?.shipments}</InfoItem>
              <InfoItem><InfoLabel>Order Numbers:</InfoLabel> {summary.shipmentNum}</InfoItem>
              {summary?.workOrders?.length ? (
              <InfoItem><InfoLabel>Number of Work Orders:</InfoLabel> {summary?.workOrders?.length}</InfoItem>) : null }
              {summary?.workOrders?.length ? (
              <InfoItem><InfoLabel>Work Orders Found:</InfoLabel> {summary?.workOrderNums}</InfoItem>) : null }
              <InfoItem><InfoLabel>Brand:</InfoLabel> {summary.brand}</InfoItem>
              <InfoItem><InfoLabel>Num of Wigs:</InfoLabel> {summary.totalQty}</InfoItem>
              <Divider style={{margin: '20px 0'}} />
              <InfoItem><InfoLabel>Subtotal:</InfoLabel> {currencyFormat(summary?.subtotal)}</InfoItem>
              <InfoItem><InfoLabel>Shipping Cost Per Item:</InfoLabel> {currencyFormat(summary?.spi)}</InfoItem>
              <InfoItem><InfoLabel>Total Shipping Cost:</InfoLabel> {currencyFormat(summary?.freight)}</InfoItem>
              <InfoItem><InfoLabel>Total:</InfoLabel> {currencyFormat(summary?.total)}</InfoItem>
              <Divider style={{margin: '20px 0'}} />
              <InfoItem><InfoLabel>Notes:</InfoLabel> {summary.notes}</InfoItem>
            </Grid>

            <Grid item xs={1}></Grid>

            <Grid item xs={7}>
              <InfoHeader>
                {summary.styles?.length > 1 ? `${summary.styles?.length} Styles` : `${summary.styles?.length} Style`}
                </InfoHeader>
              {/* <Divider style={{margin: '10px 0'}} /> */}
              <SimpleTable headers={styleHeaders} rows={summary.styles} actions={false} pagination={false} />
            </Grid>

          </Grid>
        </CardContent>
      </Card>
  )
 
 return (
 <Editform title="Add Shipment" header="Shipment" crumbs={crumbs}>

  <Grid container spacing={4}>

    <Grid item xs={4}>
      <Dialog toggle={
        <SampleSelector><SampleLink onClick={loadBrands}>Click to view Sample file</SampleLink></SampleSelector>
      } 
      size="xl"
      title="Sample Sheet"
      onSave={downloadSampleCsv}
      save="Download Sample"
      // cancel={false}
      >
        {console.log(profileHeaders, "profileHeaders")}
        <SampleSheet headers={profileHeaders} />
        {bLoading ? <Grid container><LinearProgress color="primary" /></Grid> : 
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormHelperText>To download sample csv fill out the optional input fields</FormHelperText>
          </Grid>
        <Grid item xs={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
            variant="inline" openTo="year" disableToolbar views={["year", "month", "date"]} format="MM/dd/yyyy"
            defaultValue={csvValues.date} onChange={handleChangeValues} label="Shipment Date" name="date" inputVariant='outlined' fullWidth />
          </MuiPickersUtilsProvider>
        </Grid>
        
        {brands.length > 1 ? <Grid item xs={3}>
          <FormControl variant="outlined" fullWidth>
          <InputLabel>Select Brand</InputLabel>
          <Select
            defaultValue={csvValues.brand}
            onChange={handleChangeValues}
            label="Select Brand"
            fullWidth
            name="brand"
          >
            {brands.map(el => (
            <MenuItem value={el} key={el}>{el}</MenuItem>)
              )}
          </Select>
        </FormControl>
        </Grid> : null }

        <Grid item xs={3}>
          <TextField
          defaultValue={csvValues.orderNum}
          onChange={handleChangeValues}
          label="Order No."
          fullWidth
          name="orderNum"
          variant='outlined'
          />
        </Grid>

        <Grid item xs={3}>
          {loadingProfiles ? <CircularProgress /> : 
          <OutlinedSelect label="Select Shipment Profile" color={theme.colors.secondaryMain} 
            val={ profile } 
            onChange={(e) => handleProfileChange(e)}
          >
            {profiles.map(el => ( <MenuItem value={el._id} key={el._id}>{el.field} - &nbsp;<Description>{truncate(el.description)}</Description></MenuItem>))}
            <MenuItem value="">None</MenuItem>
          </OutlinedSelect>}
        </Grid>

        </Grid>
      }
      </Dialog>
    </Grid>

  <Grid item xs={8}>
      <form encType="multipart/form-data">
        <CommonFlex align="center" justify="flex-end">
          <SelectContainer>
            {loadingProfiles ? <CircularProgress /> : 
          <OutlinedSelect label="Select Shipment Profile" color={theme.colors.secondaryMain} 
            val={ profile } 
            onChange={(e) => handleProfileChange(e)}
          >
            {profiles.map(el => ( <MenuItem value={el._id} key={el._id}>{el.field} - &nbsp;<Description>{truncate(el.description)}</Description></MenuItem>))}
            <MenuItem value="">None</MenuItem>
          </OutlinedSelect>}
          </SelectContainer>
          <FileButton htmlFor="import-button">
          <Input
              id="import-button"
              inputProps={{
                accept:
                  ".csv,.xlsx"
              }}
              onChange={onImportFile}
              style={{display: "none"}}
              type="file"
          />
            <Publish /> <span>Upload Sheet</span>
          </FileButton>
      </CommonFlex>
      </form>
    </Grid>

    {/* Only visible if document wasn't imported yet */}
    { isEmpty(summary) ? <Grid item xs={viewGuidelines ? 5 : 1}>
      {/* {fileSelect ?  */}
      <>
      <GuideLineCard elevation={0}>
        <CardHeader title={
          <><GuidelineHeader><Tooltip title="Shipment Sheet Guidelines"><AssignmentIcon style={{marginRight: '15px'}} color="secondary" fontSize="large" /></Tooltip>
          {viewGuidelines ? <GuideLineText>Shipment Sheet Guidelines</GuideLineText>  : null} 
          <Tooltip title="Toggle Visibility"><IconButton size="small" onClick={() => setViewGuidelines(x => !x)}>{!viewGuidelines ? <VisibilityOffIcon  /> : <VisibilityIcon color="secondary" />}</IconButton></Tooltip></GuidelineHeader></>
          }
         />

        <CardContent>
        
    {viewGuidelines ? <TimelineAlign>

      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot variant="outlined" color="primary">
            <ListNumber>1</ListNumber>
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <Content>Accepts the following file types: .csv, .xlsx </Content>
      </TimelineItem>

      <TimelineItem>
        <TimelineSeparator>
        <TimelineDot variant="outlined" color="primary">
        <ListNumber>2</ListNumber>
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <Content>Include Fields displayed in the sample file above</Content>
      </TimelineItem>

      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot variant="outlined" color="primary">
            <ListNumber>3</ListNumber>
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <Content>Ensure that the 'style' column lists the wig colors in this format, '4/1b/r4' </Content>
      </TimelineItem>

      <TimelineItem>
        <TimelineSeparator>
        <TimelineDot variant="outlined" color="primary">
        <ListNumber>4</ListNumber>
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <Content>'brand' column is only required if this isn't the default brand. Please enter the Brand Code from the brand page.</Content>
      </TimelineItem>

      <TimelineItem>
        <TimelineSeparator>
        <TimelineDot variant="outlined" color="primary">
        <ListNumber>5</ListNumber>
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <Content>Please review the rows for any mistakes in sheet preview!</Content>
      </TimelineItem>

      <TimelineItem>
        <TimelineSeparator>
        <TimelineDot variant="outlined" color="primary">
        <ListNumber>6</ListNumber>
          </TimelineDot>
        </TimelineSeparator>
        <Content>To setup your own sheet rules <StyledLink to="/setup/shipments">click here</ StyledLink></Content>
      </TimelineItem>
      

    </TimelineAlign> : null }
    </CardContent>
    </GuideLineCard>

    </>
    </Grid>
    : null }

    {!isEmpty(summary) || loading ? <Grid style={{marginTop: '50px'}} item xs={12}>
        <SummaryContent />  
      </Grid> : null
      }

    {!isEmpty(woErrors) ? <Grid item xs={viewGuidelines ? 7 : 11}>
      <DisplayWorkOrderErrors errors={woErrors} />
    </Grid> : null
    }

    <Grid item xs={viewGuidelines ? isEmpty(woErrors) ? 7 : 12 : !isEmpty(summary) ? 12 : 11}>
     {fileSelect ? <ShipmentTable loading={validateLoading} rows={preview.rows} errors={preview.errors} warnings={preview.warnings} importFunc={onImportFile} summary={summary} ></ShipmentTable> : null }
    </Grid>
    
     

  </Grid>
      
 </Editform>
 )
}

export default AddShipment;