import React from 'react';
import styled from 'styled-components';
import { Box, Typography } from '@material-ui/core';

// Styled components for our flex layout
const StyledFlexContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: ${props => props.gap || '0'};
  /* justify-content: space-between; */
  ${props => props.customStyles}
`;

const StyledFlexItem = styled(Box)`
  display: flex;
  flex-direction: column;
  ${props => props.customStyles}
`;

const StyledLabel = styled(Typography)`
  font-size: 1.1rem;
  font-weight: 500;
  min-width: fit-content;
  color: ${props => props.theme.colors.lightText};
  ${props => props.customStyles}
`;

const StyledInfo = styled(Box)`
  min-width: fit-content;
  margin-top: 4px;
  ${props => props.customStyles}
`;

// Main container component that creates the flex row
const FlexContainer = ({ children, infoComponent, gap, style, defaultInfoStyle, defaultInfoProps, ...props }) => {
  const enhancedChildren = React.Children.map(children, child => {
    if (!React.isValidElement(child) || child.type !== FlexItem) {
      return child;
    }
    
    return React.cloneElement(child, {
      infoComponent: infoComponent,
      infoStyle: { ...defaultInfoStyle, ...(child.props.infoStyle || {}) },
      infoProps: { ...defaultInfoProps, ...(child.props.infoProps || {}) },
    });
  });
  return (
    <StyledFlexContainer
      gap={gap}
      customStyles={style}
      {...props}
    >
      {infoComponent ? enhancedChildren : children}
    </StyledFlexContainer>
  );
};

// Child item component that creates each column with label and info
const FlexItem = ({ 
  label, 
  info, 
  infoComponent: InfoComponent,
  labelStyle = {}, 
  infoStyle = {},
  style = {},
  ...props 
}) => {
  const renderInfoContent = () => {
    if (InfoComponent) {
      return <InfoComponent>{info}</InfoComponent>;
    }
    return info;
  };

  return ( info ?
    <StyledFlexItem 
      customStyles={style}
      {...props}
    >
      <StyledLabel
        variant="body2"
        customStyles={labelStyle}
      >
        {label}
      </StyledLabel>
      <StyledInfo
        customStyles={infoStyle}
      >
        {renderInfoContent()}
      </StyledInfo>
    </StyledFlexItem> : null
  );
};

// Export both components
FlexContainer.Item = FlexItem;

export default FlexContainer;