import React, {useState} from 'react'
import styled from 'styled-components'

import Drawer from './Drawer';
import AppBar from './AppBar';
// import App from '../../App';

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`
const Main = styled.div`
  position: relative;
  width: 100%;
`
const MainContent = styled.div`
  /* margin-top: 4rem; */
  background-color: rgb(250, 250, 251);
`

const Attribution = styled.div`
  position: fixed;
  bottom: 10px;
  left: 20px;
  color: #ccc;
  opacity: 0.8;
  font-size: 12px;
  z-index: 100000;
`
 
export default ({children}) => {

  const [open, setOpen] = useState(true);

  const toggleDrawer = () => setOpen(!open)

 return (
 <Container>
   <Attribution>
   &copy;  Powered by Novosoft &nbsp; 2.1.0
   </Attribution>
   <Drawer open={open} toggle={toggleDrawer} />
   <Main>
     <AppBar toggle={toggleDrawer} />
     <MainContent>
      {children}
     </MainContent>
   </Main>
 </Container>
 )
}