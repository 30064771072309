import React, {useEffect, useState, useCallback} from 'react';
import styled from 'styled-components';
import { Formik, Form, Field, FastField, FieldArray } from 'formik';
import axios from '../../axios';
import {Grid, Paper, Button, InputAdornment, LinearProgress, IconButton,
  CircularProgress, MenuItem, InputLabel, FormControl, FormControlLabel, FormHelperText, Card, CardHeader, CardContent} from '@material-ui/core';
import { TextField, Select } from 'formik-material-ui';
import * as Yup from 'yup';
import { handleErrors, paymentMethods, currencyFormat, formatCalendar, formatDateStr } from '../../utils/util';
import {useSBContext} from '../../utils/snackbar';

import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DatePicker from '../../utils/DatePicker';
import DeleteIcon from '@material-ui/icons/Delete';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import Switch from '@material-ui/core/Switch';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';


import { debounce, set } from 'lodash'

import EditForm from '../../utils/editForm';

import Autocomplete from '../../utils/autoComplete';

import SimpleAutoComplete from '../../utils/simpleAutoComplete';

const Row = styled(Grid)`
  min-height: 10rem;
  /* width: 100%; */
  /* background-color: pink; */
  padding-top: 4rem;
`

const InnerRow = styled(Grid)`
  min-height: 5rem;
`

const ButtonRow = styled(Grid)`
  min-height: 6rem;
`;

const PaymentInfoStyle = styled(Card)`

padding-bottom: 1rem;
margin-bottom: 2rem;

.MuiCardHeader-root {
  padding: 1rem 1.6rem;
}

.MuiCardContent-root {
  padding: 1rem 1.6rem;
}
`;

const PaymentInfo = ({index, payment, remove, errors, touched}) => {

  const [paymentInfo, setPaymentInfo] = useState('');

  let paymentTypeErr = errors?.paymentType && touched?.paymentType;

  useEffect(() => {
    switch (payment.paymentType) {
      case 'Credit Card':
        setPaymentInfo('Last 4 digits of CC');
        break;
      case 'Check':
        setPaymentInfo('Check Number');
        break;
      case 'Bank Account':
        setPaymentInfo('Last 4 digits of Bank Account');
        break;
      default:
        setPaymentInfo('Payment Info');
    }
    console.log(paymentInfo, "payment info", payment);
  }, [payment])
 

  return (
    <PaymentInfoStyle key={payment.rand} variant="outlined">
      <CardHeader subheader={`${index + 1} - Payment Details`} action={<IconButton onClick={() => remove(index)}><DeleteIcon color="primary" /></IconButton>} />
      <CardContent>
      <Grid container spacing={2}>
        <InnerRow item xs={12} lg={4}>
          <FastField
            component={TextField}
            type="number"
            label="Enter Payment Amount"
            name={`payments.${index}.paymentAmount`}
            variant="outlined"
            size="small"
            fullWidth
          />
        </InnerRow>

        <InnerRow item xs={12} lg={4}>
          <FormControl variant="outlined" fullWidth size="small" error={paymentTypeErr}>
            <InputLabel htmlFor="paymentType">Payment Type</InputLabel>
            <FastField
              component={Select}
              name={`payments.${index}.paymentType`}
              variant="outlined"
              fullWidth
              label="Payment Type"
              size="small"
              inputProps={{
                id: 'paymentType',
              }}
            > 
              {paymentMethods.map(({name, display}, index) => (
                <MenuItem key={index} value={name}>{display}</MenuItem>
              ))}
            </FastField>
          </FormControl>
          <FormHelperText error={paymentTypeErr}>{paymentTypeErr}</FormHelperText>
        </InnerRow>

        <InnerRow item xs={12} lg={4}>
          <DatePicker
              name={`payments.${index}.date`}
              size="small"
          />
        </InnerRow>

        <InnerRow item xs={12} md={7}>
          <Field
            component={TextField}
            type="text"
            label={paymentInfo}
            name={`payments.${index}.paymentInfo`}
            variant="outlined"
            size="small"
            fullWidth
          />
          {/* <FormHelperText>e.g. last digits of credit card etc...</FormHelperText> */}
        </InnerRow>

        <InnerRow item xs={12}>
        <FastField
          component={TextField}
          variant="outlined"
          fullWidth
          multiline
          minRows={2}
          name={`payments.${index}.paymentNotes`}
          label="Notes"
          />
        </InnerRow> 

      </Grid>

      </CardContent>
    </PaymentInfoStyle>
  )
}
 
const AddPayment = (props) => {

  const { id } =  props?.match?.params || "";

  const isEdit = id ? true : false;

  const customerName = props?.customer
 
  const [info, SetInfo] = useState({});

  const [cust, setCust] = useState([]);

  const [loading, setLoading] = useState(id ? true : false)

  const [paymentList, setPaymentList] = useState([])

  // const [selectedDate, setSelectedDate] = useState(new Date());

  const [saveAsGroup, setSaveAsGroup] = useState(true);

  const [newDate, setNewDate] = useState(new Date());


  const handleChangeCust = useCallback(debounce(async val => {
    const {data} = await axios.get(`/customers?term=${encodeURIComponent(val)}`)
    // console.log(data, "custssss");
    setCust(data);
  }, 250), []);

  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  // };

  const handleChangePayment = useCallback(debounce(async (val, values) => {
    const {data} = await axios.get(`/payments/customer/${values.customer?.id}?term=${encodeURIComponent(val)}&date=${values.date}&paymentNum=${values.paymentNum}`)
    setPaymentList(data);
  }, 250), [info]);

  const loadData = async () => {
    try {
      const {data} = await axios.get(`/payments/${id}`)
      console.log(data, "data"); 
      SetInfo(data.payment);
      // setSelectedDate(data.payment.date)
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (id) 
      loadData();
  }, [])

  // console.log(info.customer, customerName, "DFJKKKKKK");

  const action = id ? 'edit' : 'add';
  const successMsg = action === "edit" ? "Payment successfully updated" : "Payment successfully added";

  let crumbs = [{path: '/payments/', text: 'Payments'}];

  if (action === "edit")
    crumbs.push({path: `/payments/view/${id}`, text: 'View'})

  crumbs.push({path: '/payments/edit', text: action === "edit" ? 'Edit' : 'Add'});

  const EditSchema = !isEdit ? Yup.object().shape({
    customer: Yup
      .string()
      .required('Customer name is required.'),
    payments: Yup.array().of(
      Yup.object().shape({
        paymentAmount: Yup
          .number()
          .required('amount is required.').positive(),
        paymentType: Yup
          .string()
          .required('Payment type is required'),
      })
    )
  }) : Yup.object().shape({
    customer: Yup
      .string()
      .required('Customer name is required.'),
  });

  const {handleClick} = useSBContext();

  const onSubmit = async (userInfo={}, { setSubmitting, setErrors}) => {

    let updates = {...userInfo, isGroup: saveAsGroup}

    console.log(updates, "updates");

    // updates.date = selectedDate
    let total = id ? +updates.paymentAmount : updates.payments.reduce((acc, {paymentAmount}) => acc + +paymentAmount, 0);

    if (id) {
      updates.customer = updates.customer.id
    }

    try {
      let query = !id ? axios.post('/payments/', {...updates}) : axios.put(`/payments/${id}`, {...updates});

      const {data} = await query;
      setSubmitting(false)
      handleClick('success', successMsg)
      if (!customerName)
        props.history.push(`/payments/view/${id ? id : data._id}`)
      if (props.updateTotal)
        props.updateTotal(total)
    } catch (e) {
      setSubmitting(false);
      const displayMessage = handleErrors({e, setErrors})
      if (displayMessage)
        handleClick('error', displayMessage)
    }
  }

  const getPaymentInfoText = (paymentType) => {
    switch (paymentType) {
      case 'Credit Card':
        return 'Last 4 digits of CC';
      case 'Check':
        return 'Check Number';
      case 'Bank Account':
        return 'Last 4 digits of Bank Account';
      default:
        return 'Payment Info';
    }
  }

 return (
 <EditForm title={action === "edit" ? "Edit Payment" : "New Payment"} header="Payment" crumbs={crumbs}>
   {loading ? <LinearProgress /> :
   <Formik
    enableReinitialize
    initialValues={
      info.paymentAmount ? {...info} : 
      {
        customer: customerName,
        payments: [{paymentAmount: "", paymentInfo: "", paymentNotes: "", paymentType: "", date: newDate, random: 0.987654321}]
      }
    }
    validationSchema={EditSchema}
    onSubmit={onSubmit}
   >
    {
      ({submitForm, isSubmitting, setFieldValue, setErrors, errors, touched, values}) => (
        <Form>
          {/* {console.log(values, info)} */}
          <Grid container spacing={4}>
            <Row item xs={12}>
            <Autocomplete
               list={cust} name="customer" 
               field={id ? 'id' : '_id'} 
               label="Customer Name"
               getOptionLabel={({name}) => name || ""} 
               getOptionSelected={({name}, val) => name === val.name}
               renderOption={(opt) => <span>{opt.name}</span>}
               handleChange={handleChangeCust}
               autoHighlight
               autoSelect
               blur
               setter={setFieldValue}
               value={{name: customerName?.name || info.customer?.name}}
               err={errors.customer && touched.customer ? errors.customer : ''}
              />
              {/* <FormHelperText>Please add customer</FormHelperText> */}
            </Row>

            {console.log(values, "values")}

           {!isEdit ? <Row item xs={12}>
            <FieldArray
              name="payments"
              render={arrayHelpers => (
                <>
                  {values.payments.map((payment, index) => (
                    <PaymentInfo key={index} index={index} payment={payment} remove={arrayHelpers.remove} errors={errors?.payments?.[index]}
                    touched={touched?.payments?.[index]} />
                  ))}
                 <ButtonRow item xs={12}>
                 <Button
                 onClick={() => {
                   let rand = Math.random()
                   let newDate = new Date()
                   arrayHelpers.push({paymentAmount: "", paymentInfo: "", paymentNotes: "", paymentType: "", date: newDate, random: rand})
                 }}
                 variant="outlined"
                 color="primary"
                 startIcon={<AddRoundedIcon />}
                 >Add Payment</Button>
               </ButtonRow>
              </>
              )}
            />
            </Row> : null}

           {!isEdit ? <ButtonRow item xs={12}>
            <FormControlLabel
              control={<Switch checked={saveAsGroup} onChange={() => setSaveAsGroup(x => !x)} color="secondary" />}
              label="Save As Group"
            />
            <FormHelperText>Will appear together on invoice</FormHelperText>
            </ButtonRow> : null }

            
            {isEdit ? <>
             <Row item xs={12} lg={4}>
              <Field
                component={TextField}
                type="number"
                label="Enter Payment Amount"
                name="paymentAmount"
                variant="outlined"
                fullWidth
              />
            </Row>

            <Row item xs={12} lg={4}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="paymentType">Payment Type</InputLabel>
                <Field
                  component={Select}
                  name="paymentType"
                  variant="outlined"
                  fullWidth
                  label="Payment Type"
                  inputProps={{
                    id: 'paymentType',
                  }}
                >
                 {paymentMethods.map(({name, display}, index) => (
                  <MenuItem key={index} value={name}>{display}</MenuItem>
                 ))}
                </Field>
              </FormControl>
            </Row>

            <Row item xs={12} lg={4}>
              <DatePicker
                name="date"
              />
            </Row>

            <Row item xs={12} md={7}>
              <Field
                component={TextField}
                type="text"
                label={getPaymentInfoText(values.paymentType)}
                name="paymentInfo"
                variant="outlined"
                fullWidth
              />
            </Row>

            <Row item xs={12} md={5}>
              <SimpleAutoComplete
                list={paymentList} 
                label="Group Payment ID"
                getOptionLabel={(opt) => opt.paymentNum || ''}
                getOptionSelected={(service, val) => service.paymentNum === val.paymentNum}
                renderOption={(opt) => <span >#{opt.paymentNum} - {currencyFormat(opt.paymentAmount)} - {opt.paymentType} - {formatDateStr(opt.date)}</span>}
                handleChange={(val, e) => handleChangePayment(val, values)}
                handleSelect={(val) => { setFieldValue("groupId", val)}}
                autoHighlight
                blur
                // freeSolo
                name="groupId"
                value={{paymentNum: values.groupId || ''}}
              />
              <FormHelperText>Attach payment to existing invoice of same date</FormHelperText>
            </Row>

            <Row item xs={12}>
            <Field
              component={TextField}
              variant="outlined"
              fullWidth
              multiline
              minRows={3}
              name="paymentNotes"
              label="Notes"
              />
            </Row> </> : null}

            <Grid xs={12} className="submit" item >
            <Button 
            type="reset"
            color="secondary"
            disabled={isSubmitting}
            variant="outlined"
            className="button_reset"
            startIcon={<ClearRoundedIcon />}
            >Clear</Button>
            {isSubmitting ? <CircularProgress /> : 
            <Button 
            type="submit"
            color="primary"
            disabled={isSubmitting}
            startIcon={<SaveRoundedIcon />}
            variant="contained"
            >Save</Button>
            }
            </Grid>
          </Grid>
        </Form>
      )
    }
   </Formik>
  }
 </EditForm>
 )
}

export default AddPayment;