import React, {useEffect, useState, useCallback, useRef} from 'react';
import styled from 'styled-components';
import { Formik, Form, Field, FastField, FieldArray } from 'formik';
import axios from '../../axios';
import {Grid, Paper, Button, InputAdornment, LinearProgress, IconButton,
  CircularProgress, MenuItem, InputLabel, FormControl, FormControlLabel, FormHelperText, Card, CardHeader, CardContent, FormLabel, RadioGroup, Radio } from '@material-ui/core';
import { TextField, Select } from 'formik-material-ui';
import * as Yup from 'yup';
import { handleErrors, paymentMethods, currencyFormat, formatCalendar, formatDateStr } from '../../utils/util';
import {useSBContext} from '../../utils/snackbar';

import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DatePicker from '../../utils/DatePicker';
import DeleteIcon from '@material-ui/icons/Delete';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import Switch from '@material-ui/core/Switch';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';


import { debounce, set } from 'lodash'

import EditForm from '../../utils/editForm';

import Autocomplete from '../../utils/autoComplete';

import SimpleAutoComplete from '../../utils/simpleAutoComplete';

const Row = styled(Grid)`
  min-height: 10rem;
  /* width: 100%; */
  /* background-color: pink; */
  padding-top: 4rem;
`

const InnerRow = styled(Grid)`
  min-height: 5rem;
`

const ButtonRow = styled(Grid)`
  min-height: 6rem;
`;

const PaymentInfoStyle = styled(Card)`

padding-bottom: 1rem;
margin-bottom: 2rem;

.MuiCardHeader-root {
  padding: 1rem 1.6rem;
}

.MuiCardContent-root {
  padding: 1rem 1.6rem;
}
`;

const DisplayExpense = styled.div`
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: .6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  background-color: ${props => props.theme.colors.primary95};
`;

const ExpenseHeader = styled.div`
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: .5rem;
  color: ${props => props.theme.colors.lightText};
`;

const ExpenseTotal = styled.div`
  font-size: 1.8rem;
  font-weight: 500;
`;

const PaymentInfo = ({index, payment, remove, errors, touched}) => {

  const [paymentInfo, setPaymentInfo] = useState('');

  let paymentMethodErr = errors?.paymentMethod && touched?.paymentMethod;

  useEffect(() => {
    switch (payment.paymentMethod) {
      case 'Credit Card':
        setPaymentInfo('Last 4 digits of CC');
        break;
      case 'Check':
        setPaymentInfo('Check Number');
        break;
      case 'Bank Account':
        setPaymentInfo('Last 4 digits of Bank Account');
        break;
      default:
        setPaymentInfo('Payment Info');
    }
    console.log(paymentInfo, "payment info", payment);
  }, [payment])
 

  return (
    <PaymentInfoStyle key={payment.rand} variant="outlined">
      <CardHeader subheader={`${index + 1} - Payment Details`} action={<IconButton onClick={() => remove(index)}><DeleteIcon color="primary" /></IconButton>} />
      <CardContent>
      <Grid container spacing={2}>
        <InnerRow item xs={12} lg={4}>
          <FastField
            component={TextField}
            type="number"
            label="Enter Payment Amount"
            name={`payments.${index}.paymentAmount`}
            variant="outlined"
            size="small"
            fullWidth
          />
        </InnerRow>

        <InnerRow item xs={12} lg={4}>
          <FormControl variant="outlined" fullWidth size="small" error={paymentMethodErr}>
            <InputLabel htmlFor="paymentMethod">Payment Type</InputLabel>
            <FastField
              component={Select}
              name={`payments.${index}.paymentMethod`}
              variant="outlined"
              fullWidth
              label="Payment Type"
              size="small"
              inputProps={{
                id: 'paymentMethod',
              }}
            > 
              {paymentMethods.map(({name, display}, index) => (
                <MenuItem key={index} value={name}>{display}</MenuItem>
              ))}
            </FastField>
          </FormControl>
          <FormHelperText error={paymentMethodErr}>{paymentMethodErr}</FormHelperText>
        </InnerRow>

        <InnerRow item xs={12} lg={4}>
          <DatePicker
              name={`payments.${index}.date`}
              size="small"
          />
        </InnerRow>

        <InnerRow item xs={12} md={7}>
          <Field
            component={TextField}
            type="text"
            label={paymentInfo}
            name={`payments.${index}.paymentInfo`}
            variant="outlined"
            size="small"
            fullWidth
          />
          {/* <FormHelperText>e.g. last digits of credit card etc...</FormHelperText> */}
        </InnerRow>

        <InnerRow item xs={12}>
        <FastField
          component={TextField}
          variant="outlined"
          fullWidth
          multiline
          minRows={2}
          name={`payments.${index}.paymentNotes`}
          label="Notes"
          />
        </InnerRow> 

      </Grid>

      </CardContent>
    </PaymentInfoStyle>
  )
}
 
const AddPayment = (props) => {

  const { id } =  props?.match?.params || "";

  const isEdit = id ? true : false;

 
  const [info, SetInfo] = useState({});
  
  // only if vendorType === services
  const [expenses, setExpenses] = useState([]);

  // only if vendorType === manufacturing
  const [workOrders, setWorkOrders] = useState([])

  const [loading, setLoading] = useState(id ? true : false)

  const [newDate, setNewDate] = useState(new Date());

  const handleSearchExpenses = useCallback(debounce(async val => {
    console.log(val, "val")
    const {data} = await axios.get(`/expenses?term=${encodeURIComponent(val)}`)
    setExpenses(data?.docs);
  }, 250), []);

  const handleSearchWorkOrders  = useCallback(debounce(async val => {
    const {data} = await axios.get(`/work_orders?term=${encodeURIComponent(val)}`)
    setWorkOrders(data?.docs);
  }, 250), []);


  const loadData = async () => {
    try {
      const {data} = await axios.get(`/vendor_payments/${id}`)
      console.log(data, "data"); 
      SetInfo(data.payment);
      // setSelectedDate(data.payment.date)
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (id) 
      loadData();
  }, [])

  // console.log(info.customer, vendorName, "DFJKKKKKK");

  const action = id ? 'edit' : 'add';
  const successMsg = action === "edit" ? "Payment successfully updated" : "Payment successfully added";

  let crumbs = [{path: '/vendor_payments/', text: 'Vendor Payments'}];

  if (action === "edit")
    crumbs.push({path: `/vendor_payments/view/${id}`, text: 'View'})

  crumbs.push({path: '/vendor_payments/edit', text: action === "edit" ? 'Edit' : 'Add'});

  const EditSchema = !isEdit ? Yup.object().shape({
    payments: Yup.array().of(
      Yup.object().shape({
        paymentAmount: Yup
          .number()
          .required('amount is required.').positive(),
        paymentMethod: Yup
          .string()
          .required('Payment type is required'),
      })
    )
  }) : null;

  const {handleClick} = useSBContext();

  const getAmount = (values) => {
    let expense = values.expense ? values.expense : values.workOrder
    let arr = values.expense ? expenses : workOrders;
    let value = arr.find(item => item._id === expense)
    console.log(value, "value")
    let balance = values.expense ? value.balanceInfo?.balance : value.balance;
    return balance;
  }

  const onSubmit = async (userInfo={}, { setSubmitting, setErrors}) => {

    let updates = {...userInfo}

    // updates.date = selectedDate
    let total = id ? +updates.paymentAmount : updates.payments.reduce((acc, {paymentAmount}) => acc + +paymentAmount, 0);

    let totalBalance = getAmount(userInfo)

    if (total > totalBalance) {
      return handleClick('error', 'Payment amount exceeds total balance')
    }

    try {
      let query = !id ? axios.post('/vendor_payments/', {...updates}) : axios.put(`/vendor_payments/${id}`, {...updates});

      const {data} = await query;
      setSubmitting(false)
      handleClick('success', successMsg)
      props.history.push(`/vendor_payments/view/${id ? id : data._id}`)
      // if (props.updateTotal)
      //   props.updateTotal(total)
    } catch (e) {
      setSubmitting(false);
      const displayMessage = handleErrors({e, setErrors})
      if (displayMessage)
        handleClick('error', displayMessage)
    }
  }

  const getPaymentInfoText = (paymentMethod) => {
    switch (paymentMethod) {
      case 'Credit Card':
        return 'Last 4 digits of CC';
      case 'Check':
        return 'Check Number';
      case 'Bank Account':
        return 'Last 4 digits of Bank Account';
      default:
        return 'Payment Info';
    }
  }

 return (
 <EditForm title={action === "edit" ? "Edit Vendor Payment" : "New Vendor Payment"} header="Vendor Payment" crumbs={crumbs}>
   {loading ? <LinearProgress /> :
   <Formik
    enableReinitialize
    initialValues={
      info.paymentAmount ? {...info} : 
      {
        vendorType: "manufacturing",
        workOrder: null,
        expense: null,
        payments: [{paymentAmount: "", paymentInfo: "", paymentNotes: "", paymentMethod: "", date: newDate, random: 0.987654321}]
      }
    }
    validationSchema={EditSchema}
    onSubmit={onSubmit}
   >
    {
      ({submitForm, isSubmitting, setFieldValue, setErrors, errors, touched, values}) => (
        <Form>
          {/* {console.log(values, info)} */}
          <Grid container spacing={4}>
             <Row item xs={5}>
                <FormLabel component="legend" className='legend' style={{paddingBottom: "1rem"}}>Vendor Type</FormLabel>
                  <Field name="vendorType" row>
                  {({
                      field : {onChange, ...fieldBag}, // { }
                      form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      meta,
                    }) => (
                    <>
                      <RadioGroup 
                        // defaultChecked="manufacturing"
                        {...fieldBag}
                        onChange={(e) => {
                          onChange(e)
                        }} 
                        row>
                      <FormControlLabel
                        value="manufacturing"
                        control={<Radio />}
                        label="Manufacturing"
                      />
                      <FormControlLabel
                        value="services"
                        control={<Radio />}
                        label="Services"
                      />
                      </RadioGroup>
                    </>
                    )}
                  </Field>
                </Row>

              <Row item xs={7}></Row>

            <Row item xs={12}>
            {values.vendorType === 'manufacturing' ? 
              <Autocomplete
               list={workOrders} name="workOrder" 
               key="workOrder"
               field={id ? 'id' : '_id'} 
               label="Select Work Order"
               getOptionLabel={({orderId}) => orderId || ""} 
               getOptionSelected={({orderId}, val) => orderId === val.orderId}
               renderOption={(opt) => <span>{opt.orderId}</span>}
               handleChange={handleSearchWorkOrders}
               autoHighlight
               autoSelect
               blur
               setter={setFieldValue}
               value={{orderId: info?.workOrder?.orderId}}
               err={errors.workOrder && touched.workOrder ? errors.workOrder : ''}
              /> : 
              <Autocomplete
               list={expenses} name="expense"
               key="expense" 
               field={id ? 'id' : '_id'} 
               label="Select Expense"
               getOptionLabel={({expenseType}) => expenseType || ""} 
               getOptionSelected={({expenseType}, val) => expenseType === val.expenseType}
               renderOption={(opt) => <span>{opt.expenseId} - {opt.expenseType}</span>}
               handleChange={handleSearchExpenses}
               autoHighlight
               autoSelect
               blur
               setter={setFieldValue}
               value={{expenseId: info.expense?.expenseId}}
               err={errors.expense && touched.expense ? errors.expense : ''}
              />}
            </Row>

            {console.log(values, "values")}

            {values.expense || values.workOrder ? (
              <Row item xs={12}>
                <DisplayExpense>
                  <ExpenseHeader>Total Balance:</ExpenseHeader>
                  <ExpenseTotal>{currencyFormat(getAmount(values))}</ExpenseTotal>
                </DisplayExpense>
              </Row>
            ) : null}

           {!isEdit ? <Row item xs={12}>
            <FieldArray
              name="payments"
              render={arrayHelpers => (
                <>
                  {values.payments.map((payment, index) => (
                    <PaymentInfo key={index} index={index} payment={payment} remove={arrayHelpers.remove} errors={errors?.payments?.[index]}
                    touched={touched?.payments?.[index]} />
                  ))}
                 <ButtonRow item xs={12}>
                 <Button
                 onClick={() => {
                   let rand = Math.random()
                   let newDate = new Date()
                   arrayHelpers.push({paymentAmount: "", paymentInfo: "", paymentNotes: "", paymentMethod: "", date: newDate, random: rand})
                 }}
                 variant="outlined"
                 color="primary"
                 startIcon={<AddRoundedIcon />}
                 >Add Payment</Button>
               </ButtonRow>
              </>
              )}
            />
            </Row> : null}
            
            {isEdit ? <>
             <Row item xs={12} lg={4}>
              <Field
                component={TextField}
                type="number"
                label="Enter Payment Amount"
                name="paymentAmount"
                variant="outlined"
                fullWidth
              />
            </Row>

            <Row item xs={12} lg={4}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="paymentMethod">Payment Type</InputLabel>
                <Field
                  component={Select}
                  name="paymentMethod"
                  variant="outlined"
                  fullWidth
                  label="Payment Type"
                  inputProps={{
                    id: 'paymentMethod',
                  }}
                >
                 {paymentMethods.map(({name, display}, index) => (
                  <MenuItem key={index} value={name}>{display}</MenuItem>
                 ))}
                </Field>
              </FormControl>
            </Row>

            <Row item xs={12} lg={4}>
              <DatePicker
                name="date"
              />
            </Row>

            <Row item xs={12} md={7}>
              <Field
                component={TextField}
                type="text"
                label={getPaymentInfoText(values.paymentMethod)}
                name="paymentInfo"
                variant="outlined"
                fullWidth
              />
            </Row>

            <Row item xs={12}>
            <Field
              component={TextField}
              variant="outlined"
              fullWidth
              multiline
              minRows={3}
              name="paymentNotes"
              label="Notes"
              />
            </Row> </> : null}

            <Grid xs={12} className="submit" item >
            <Button 
            type="reset"
            color="secondary"
            disabled={isSubmitting}
            variant="outlined"
            className="button_reset"
            startIcon={<ClearRoundedIcon />}
            >Clear</Button>
            {isSubmitting ? <CircularProgress /> : 
            <Button 
            type="submit"
            color="primary"
            disabled={isSubmitting}
            startIcon={<SaveRoundedIcon />}
            variant="contained"
            >Save</Button>
            }
            </Grid>
          </Grid>
        </Form>
      )
    }
   </Formik>
  }
 </EditForm>
 )
}

export default AddPayment;