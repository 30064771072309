import React from "react";
import styled from "styled-components";

import { BasicFlex } from "../../../utils/common";

import { Divider } from "@material-ui/core";

import { currencyFormat } from "../../../utils/util";


const Container = styled.div`
  border: 2px solid ${props => props.theme.colors.border};
  padding: 1.8rem;
  border-radius: 5px;
  background-color: #f3f3f3;
`;

const Header = styled.div`
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 2rem;
`;

const SumContainer = styled.div`
  border: 1px solid ${props => props.theme.colors.border};
  padding: 1rem;
  border-radius: 5px;
`;

const Sidebar = ({ lineItems, shipmentNum }) => {

  let number = shipmentNum?.current?.value ? shipmentNum?.current?.value : "Automatic"

  let liq = lineItems.length;
  let totalQty = lineItems.reduce((acc, item) => acc + (+item.qty || 0), 0);
  let totalCost = lineItems.reduce((acc, item) => acc + (+item.cost || 0) * (+item.qty || 0), 0);
  return (
    <Container>
      <Header>Inventory Summary</Header>

      <BasicFlex my="1rem"><span>Shipment Number</span> <span>{number}</span></BasicFlex>
      <BasicFlex my="1rem"><span>Products</span> <span>{liq}</span></BasicFlex>
      <BasicFlex my="1rem"><span>Total Qty</span> <span>{totalQty}</span></BasicFlex>
      <BasicFlex my="1rem"><span>Cost Subtotal</span> <span>{currencyFormat(totalCost)}</span></BasicFlex>

      <Divider />

      <BasicFlex my="1rem"><span>Cost Total</span> <span>{currencyFormat(totalCost)}</span></BasicFlex>
    </Container>
  );
};

export default Sidebar;