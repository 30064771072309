import React, {useEffect, useState, useRef} from 'react'
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import axios from '../../axios';
import {useAuthContext} from '../../auth';
import {Grid, Paper, Button, InputAdornment, 
  CircularProgress, LinearProgress} from '@material-ui/core'
import { Person, Lock } from '@material-ui/icons';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { handleErrors } from '../../utils/util';
import {useSBContext} from '../../utils/snackbar';

import {theme} from '../../utils/theme';

// import CardLogo from '../../images/novo-logo-web.png'

// import WigIcon from '../../images/wig';

// import Wiggy from '../../images/wigIcon.png'
// import WigBox from '../../images/wig-box-red-words.png'
import LogoImg from '../../images/logo-wig.png';

import SalonGraphic from '../../images/login.png';

const Container = styled.div`
  box-sizing: border-box;
  height: 100vh;
  width: 100%;
  /* background-color: #f8f8f8; */
  /* background: linear-gradient(to bottom right, #0b1427 49.6%, transparent 50.2%); //#63014C;  */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
`

const LoginContainer = styled.div`
  width: 47%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const LoginCard = styled(Paper)`
  position: relative;
  width: 75%;
  /* height: 90%; // 80% for svg and 85% for png */
  padding: 4rem 5rem;
  background-color: transparent;


  input:-ms-input-placeholder {font-size: 1rem;}
  input::-webkit-input-placeholder {font-size: 1rem;}

  svg {
    font-size: 18px;
    color: ${props => props.theme.palette.primary.main};
  }

  /* button {
    position: absolute;
    left: 50%;
    bottom: 50px;
    transform: translateX(-50%);
    font-size: 14px;
  } */
`

const Logo = styled.div`

  margin: 10rem 0;

  svg {
    width: 10rem
  }

  img {
    height: 7rem;
  }
`

const GraphicContainer = styled.div`
  width: 53%;
  background-image: linear-gradient(to left, #4771b8dd, #324f81dd) ; //#e0ecf933, #c4d9f177
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    display: block;
    height: 73%;
    width: 90%;
    margin: 0 auto;
    /* margin-bottom: auto; */
    /* margin-right: auto; */
    object-fit: contain;
    filter: drop-shadow(1rem 1rem 2rem #0005) saturate(115%);
  }
`

const Welcome = styled.h1`
  font-weight: 400;
  margin-bottom: .5rem;
  font-size: 3.2rem;
  color: #444e6f;
  /* text-align: left; */
  letter-spacing: 1px;
`

const SubHeader = styled.h3`
  margin-top:0 ;
  font-weight: normal;
  color: #9B9B9F;
  font-size: 1.4rem;
  margin-bottom: 3rem;
`

const Legal = styled.span`
  display: block;
  color: #9B9B9F;
`

const CtaText = styled.div`
  color: #fff;
  max-width: 85%;
  margin: 0 auto;
  margin-bottom: 15rem;
  letter-spacing: .5px;
  line-height: 2.2rem;

  .details {
    font-size: 1.2rem;
    display: block;
    margin: 0 auto;
    margin-top: 1.8rem;
    /* width: 50%; */
    line-height: 1.8rem;
    /* letter-spacing: 0; */

    * {
      color: inherit;
    }
  }
`
 
export default (props) => {

  const [loading, setLoading] = useState(true);

  const userInputRef = useRef(null);

  const LoginSchema = Yup.object().shape({
    email: Yup
      .string()
      .email()
      .required('Email is required.'),
    password: Yup
      .string()
      .required('Password is required.')
  });

  const {login} = useAuthContext();
  const {handleClick} = useSBContext();

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000)
    userInputRef?.current?.focus()
  }, [])

  const onSubmit = async (userInfo={}, { setSubmitting, setErrors}) => {
    try {
      const {data} = await axios.post('/users/login', {...userInfo})
      login(data)
      setSubmitting(false)
    } catch(e) {
      setSubmitting(false)
      const displayMessage = handleErrors({e, setErrors})
      if (displayMessage)
        handleClick('error', displayMessage)
    }
  }

 
 return (
 <Container>

  <LoginContainer>
  <LoginCard elevation={0}>
     
  <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={LoginSchema}
      onSubmit={onSubmit}
    >
  {({ submitForm, isSubmitting, errors}) => (
    <Form>
        <Welcome>Hi, Welcome Back</Welcome>
        <SubHeader>Sign In To Your Account</SubHeader>
        <span></span>
        <Logo>
            <img src={LogoImg} />
          </Logo>
          <Grid container direction="column" spacing={4}>
          <Grid item xs={12}>
          <Field
              component={TextField}
              name="email"
              type="email"
              label="Email"
              fullWidth
              variant="outlined"
              inputProps={{ref: userInputRef}}
            />
          </Grid>
          <Grid  item xs={12}>
          <Field
              component={TextField}
              name="password"
              type="password"
              label="Password"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            {isSubmitting ? <CircularProgress /> : 
            <Button 
            type="submit"
            disabled={isSubmitting}
            color="primary"
            variant='contained'
            fullWidth
            size='large'
            // onClick={submitForm}
            >Login</Button>
            }
          </Grid>
          <Grid item xs={12}>
            <Legal>
            &copy; Novosoft 2023 | All Rights Reserved
            </Legal>
          </Grid>
      </Grid>
       </Form>
       )}
    </Formik>
    
  </LoginCard>
  </LoginContainer>

  <GraphicContainer>
    <img src={SalonGraphic} alt="graphic of inventory" />
    <CtaText>Novo Tracker is the ultimate inventory tracking app for your consignment business model. You will get real-time inventory tracking information and you can monitor your accounting data with ease. With the help of this app you will be in control of your business. <br />
    <span className='details'>For customer support, please reach out to us at <a href="mailto:novosoftsolutions@gmail.com">novosoftsolutions@gmail.com</a></span>
    </CtaText>
  </GraphicContainer>

 </Container>
 )
}