import React, {useState, useEffect} from 'react'

import { Link } from 'react-router-dom'

import styled from 'styled-components'

import axios from '../../axios';

import ViewInfo from '../../utils/viewInfo'

import { useSBContext } from '../../utils/snackbar';

import EditIcon from '@material-ui/icons/Edit';

import { Grid, Card, CardHeader, CardContent, IconButton } from '@material-ui/core'

import { currencyFormat, formatGenericDate } from '../../utils/util'

import ViewLineItems from './Components/ViewLineItems';

const Info = styled.span`
  display: flex;
  margin-bottom: 1rem;
  font-size: 1.6rem;
  align-items: center;

  .email {
    font-style: italic;
  }
`

const IconLink = styled(Link)`
  color: ${props => props.theme.palette.secondary.main};
  position: relative;
  top: 1rem;
  padding-right: 2rem;
`

const InfoLabel = styled.span`
  font-weight: 500;
  padding-right: 1rem;
`

const ProgressContainer = styled.div`
  width: 100%;
  height: 1.2rem;
  background-color: #f5f5f5;
  border-radius: 1rem;
  margin-top: 1.5rem;
`;

const Progress = styled.div`
  height: 100%;
  background-color: ${props => props.theme.colors.primaryMain};
  border-radius: 1rem;
  width: ${props => props.percent || 0}%;
  transition: width 0.5s ease-in-out;
`;

const ProgressBar = ({value, max}) => {

  const percent = Math.ceil((value / max) * 100);

  return (
    <ProgressContainer>
      <Progress percent={50} />
    </ProgressContainer>
  )
}

const ViewWorkOrder = (props) => {

  const id = props.match.params.id;

  const [info, setInfo] = useState({});

  const [loading, setLoading] = useState(true);

  const crumbs = [{path: '/work_orders/', text: 'Work Orders'},
    {path: `/work_orders/view/${id}`, text: 'View'}, {path: `/work_orders/view/${id}`, text: `${info?.name || ""}`}];

  const { handleClick } = useSBContext();

  const load = async () => {
    setLoading(true);
    try {
      const {data} = await axios.get(`/work_orders/${id}`);
      setInfo(data);
    } catch (e) {
      handleClick(e.response.data.message, 'error');
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (id) {
      load();
    }
  }, []);

  return (
    <ViewInfo title="View Work Order Details" crumbs={crumbs}>
      <Grid container spacing={3}>

        <Grid item xs={4}>
          <Card variant="outlined">
            <CardHeader title="Work Order Info" action={
            <IconLink to={`/work_orders/edit/${info._id}`}><EditIcon color="primary" /></IconLink>
          } />
            <CardContent>
              <Info><InfoLabel>Order ID:</InfoLabel> {info?.orderId}</Info>
              <Info><InfoLabel>Vendor:</InfoLabel> {info?.vendor?.name}</Info>
              <Info><InfoLabel>Products:</InfoLabel> {info?.lineItemQty}</Info>
              <Info><InfoLabel>Total Qty:</InfoLabel> {info?.totalQty}</Info>
              <Info><InfoLabel>Status:</InfoLabel> {info?.status}</Info>
              <Info><InfoLabel>Date:</InfoLabel> {formatGenericDate(info?.createdAt)}</Info>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={4}>
          <Card variant="outlined">
            <CardHeader title="Fulfillment Info" />
            <CardContent>
              <Info><InfoLabel>Fulfilled Qty:</InfoLabel> {info?.fulfilledQty} / {info?.totalQty}</Info>
              <ProgressBar value={info?.fulfilledQty} max={info?.totalQty} />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={4}>

          <Card variant="outlined">
            <CardHeader title="Balance Info" />

            <CardContent>
              <Info><InfoLabel>Subtotal:</InfoLabel> {currencyFormat(info?.subtotal)}</Info>
              <Info><InfoLabel>Total:</InfoLabel> {currencyFormat(info?.total)}</Info>
              <Info><InfoLabel>Balance:</InfoLabel> {currencyFormat(info?.balance)}</Info>
            </CardContent>

          </Card>

        </Grid>

        <Grid item xs={12}>
          <Card elevation={0}>
            <CardHeader title={`Line Items - ${info?.lineItems?.length}`} />

            <ViewLineItems lineItems={info?.lineItems} fulfilledItems={info?.fulfilledItems} />
          </Card>

        </Grid>

      </Grid>

    </ViewInfo>
  )
}

export default ViewWorkOrder;