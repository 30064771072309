import React, { useState, useEffect, useRef } from 'react';
import axios from '../../axios';
import styled from 'styled-components';
import {Grid, Paper, Button, InputAdornment, LinearProgress,
   FormHelperText, RadioGroup, TextField} from '@material-ui/core';

  import {useSBContext} from '../../utils/snackbar';

import SearchRoundedIcon from '@material-ui/icons/SearchRounded';

const Container = styled.div`
  width: 45rem;
  margin: 1rem 0;
`;

const SearchComponent = ({wigs, setFieldValue}) => {

  const searchRef = useRef();

  const { handleClick } = useSBContext();

  const clearSearch = () => {
    searchRef.current.value = "";
  }

  const findWig = async (wigId) => {
    let select = "wigId brand sku length status availability sale line shipmentNum history currentlyAt title description baseMaterial searchWigId";
    let wig, msg;
    try {    
      const {data} = await axios.get(`/inventory/${wigId}?selected=${select}&multiFormat=true`);

      if (!data) {
        msg = `Wig ${wigId} not found.`
        handleClick("error", `Wig ${wigId} not found.`)
      }

      if (data.status === "sold") {
        msg = `Wig ${wigId} is sold.`
        handleClick("warning", `Wig ${wigId} is sold.`)
        clearSearch();
      } else if (data?.availability === "committed") {
        msg = `Wig ${wigId} is committed.`
        handleClick("warning", `Wig ${wigId} is committed.`)
        clearSearch();
      } else {
        wig = {...data, currentlyAt: data.currentlyAt?.name, price: data.sale.subtotal}
      }
    
      } catch (e) {
        msg = `Wig ${wigId} not found.`
        handleClick("error", `Wig ${wigId} not found.`)
      }
    return {wig, msg};
  }

  const addWig = async (wigId) => {
    wigId = wigId?.toUpperCase()
    let foundWig = wigs.find(w => w.wigId === wigId || w.searchWigId === wigId);
    if (foundWig) {
      handleClick("warning", `Wig ${wigId} is already added.`)
      clearSearch();
    } else {
      let {wig, msg} = await findWig(wigId);
      if (wig) {
       let newWigs = wigs.concat(wig);
        setFieldValue("wigs", newWigs);
        clearSearch();
      }
    }
  }

  const searchWig = (e) => {
    let val = e.target.value;
    if (e.key === "Enter") {
      if (val) {
        addWig(val);
      }
    }
  }

  return (
    <Container>
  <TextField variant='outlined' size="small" fullWidth InputProps={{endAdornment: <InputAdornment><SearchRoundedIcon color="primary" /></InputAdornment>}} inputRef={searchRef} placeholder="Enter Wig ID" onKeyUp={searchWig}  />
  <FormHelperText>Enter Wig ID and hit Enter to add wig. Has to be an exact match</FormHelperText>
  </Container>
  )

}

export default SearchComponent;