import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import axios from '../../axios';
import { DateTime } from 'luxon';

import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import getChip from '../repairs/repairs/Common';

import Status from '../../utils/Status';

import { useSBContext } from '../../utils/snackbar';

import {StatsBar, Icon, LinkItem } from './StatsBar';

import { theme } from '../../utils/theme';

import { Flex, CommonFlex, FullDivider, FlexLine, Title, BasicFlex } from '../../utils/common'

import FormDialog from '../../utils/dialog';

import Redeem from '@material-ui/icons/Redeem';
import CreditCard from '@material-ui/icons/CreditCard';
import WigIcon from '../../utils/WigIcon';
import Search from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import Assessment from '@material-ui/icons/Assessment';
import PaletteRoundedIcon from '@material-ui/icons/PaletteRounded';
import HomeIcon from '@material-ui/icons/Home';
import UpdateRoundedIcon from '@material-ui/icons/UpdateRounded';
import RepeatRoundedIcon from '@material-ui/icons/RepeatRounded';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';



import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import CardTravelIcon from '@material-ui/icons/CardTravel';

import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';


import TimelineDisplay from '../../utils/TimelineDisplay';

import CustomChip from "../../utils/CustomChip";

import { currencyFormat, capitalize, calculatePayIndicator, formatCalendar, formatGenericDate } from '../../utils/util';

import SimpleTable from '../../utils/simpleTable';

import TabContainer from '../../utils/tabContainer';

import TabContent from '../../utils/TabContent';

import PaymentTile from './PaymentTile';

const WigInfo = styled(Card)`
  /* padding: 1rem 2rem 2rem 2rem; */
` 

const Info = styled.span`
  display: flex;
  margin-bottom: 1rem;
  font-size: 1.6rem;

  .number {
    letter-spacing: 1px;
    /* font-weight: 600; */
  }
`

const InfoHeader = styled.span`
  font-weight: 600;
  display: block;
  text-transform: uppercase;
  font-size: 1.4rem;
  color: ${props => props.theme.colors.lightText};

 &.sale {
   margin-top: 2rem;
 }
`

const InfoLabel = styled.span`
  font-weight: 500;
  padding-right: 1rem;
`

const Hr = styled(Divider)`
  margin-bottom: 1rem;
`

const TLContainer = styled(Card)`
  /* padding: 1rem 2rem; */
`


const AssignedIcon = styled(Avatar)`
  background-color: ${props => props.theme.colors.secondaryMain};
`

const AssignedStatus = styled.h4`
 font-size: 1.6rem;
 font-weight: 500;
 margin: .3rem;
 /* margin-bottom: 1rem; */
`

const HistoryTitle = styled.div`
  display: flex;
  gap: 1.5rem;
`

const CurrentContainer = styled.div`
  display: flex;
  align-items: center;
  .MuiAvatar-colorDefault {
    background-color: ${props => props.theme.palette.primary.main};
  }

  .location-symbol {
     margin-right: 2rem;
     font-size: 4rem;
   }
`

const CurrentStatus = styled.h4`
 font-size: 1.8rem;
 font-weight: 500;
 margin-left: 2rem;
 /* margin-bottom: 1rem; */
`

const CurrentSub = styled.h5`
   font-size: 1.6rem;
   font-weight: 500;
`

// Timeline Styles

const TimelineListItem = styled(TimelineItem)`
  &::before {
    display: none;
  }
  /* margin-bottom: 1rem; */
`;

const TimeLineBox = styled(TimelineDot)`
  border-radius: 8px;
  padding: .8rem;
`;

const Content = styled(TimelineContent)`
  padding: .6rem 2rem;
`;

const Connector = styled(TimelineConnector)`
  height: 3rem;
`;

const Message = styled.div`

  .date {
    font-size: 1.2rem;
    font-style: italic;
    margin-bottom: .8rem;
  }

  h4 {
    font-size: 1.4rem;
    /* text-transform: uppercase; */
    margin: .2rem 0;
    font-weight: 500;
    /* font-style: italic; */

    /* color: ${props => props.theme.colors.lightText}; */
  }

  span {
    font-size: 1.2rem;
    font-style: italic;
  }
`

const IconLink = styled(Link)`
  color: ${props => props.theme.palette.primary.main};
  position: relative;
  /* top: .7rem; */
  /* padding-right: .2rem; */
`

const FieldTitle = styled.div`
  min-width: 15rem;
  margin-right: 3rem;
`

const links = [
  {
    icon: <ShoppingCartIcon color="primary" />,
    link: '/orders/edit/',
    state: true,
    toolTip: 'Add to Sale',
    id: false
  },
  {
    icon: <CardTravelIcon color="primary" />,
    link: '/consignments/edit/',
    state: true,
    toolTip: 'Add to Consignment',
    id: false
  },
]

const FieldRow = ({title, val, name, handler}) => {
  return <Flex dir="row" justify="flex-start" pad="0" padY="10px"><FieldTitle>{title}:</FieldTitle> <TextField onChange={handler} variant='outlined' fullWidth name={name} defaultValue={val} /></Flex>
}

// Tab styles
const TabBox = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.border};
  /* margin-bottom: 3rem; */
`;

const TabHeaders = styled.div`
  display: flex;
  min-width: 18rem;
  justify-content: center;
  align-items: center;

  .MuiSvgIcon-root {
    margin-right: 1rem;
  }
`




const repairHeaders = [
  {id: "repairId", label: "Ticket #"},
  {id:'repair.name', label: 'Service'},
  {id: 'total', currency: true, label: 'Price'},
  {id: "current_location.name", label: "Location"},
  {id: "status", render: row => getChip(row?.repair?.status), label: "Status"},
  {id: "date", date: true, label: "Date"},
]

const WigDisplay = ({loading, editLoading, info, loadData, submitEdits, handleEdit, repairs, transfers, payments, commit, ...props}) => {

  const [value, setValue] = useState(0); // for the tabs

  const [link, setLink] = useState({});

  const {id} = props.match.params;

  const {handleClick} = useSBContext();

  const Tabs = [
    <TabHeaders><HomeIcon /> General</TabHeaders>,
    <TabHeaders><Assessment /> Accounting</TabHeaders>,
  ]

  if (repairs?.length) {
    Tabs.push(<TabHeaders><PaletteRoundedIcon /> Repairs</TabHeaders>)
  }

  const currently = {
    status: info.status !== 'sold' ? `In ${capitalize(info.status)}` : `${capitalize(info.status)}`,  
    name: info.status !== "stock" ? `${info.currentlyAt?.name}` : ""
  }

  let color, bgColor, statusText;

  if (info.status === "sold") {
    if (info.sale?.paid) {
      color = theme.colors.icon2; bgColor = theme.colors.iconBg2; statusText = "Paid"
    } else {
      if (info.sale?.paidAmount > 0) {
        color = theme.colors.iconCaution; bgColor = theme.colors.iconBgCaution; statusText = "Partially Paid"
      } else {
        color = theme.colors.iconRed; bgColor = theme.colors.iconBgRed; statusText = "Not Paid"
      }
    }
  }

  let chip = '';

  if (info.isRemoved) {
    chip = <CustomChip level="error" size="large">This wig has been removed from the system</CustomChip>
  }

  const handleChange = (event, newValue) => {  // for the tabs
    console.log(newValue, "new value")
    setValue(newValue);
  };

  
  const toggleCommit = async (status) => {
    status = status === "committed" ? "consignment": "committed";
    let message = status === "committed" ? "Wig successfully committed" : "Wig successfully uncommitted";
    try {
      const {data} = await axios.put(`/inventory/${id}/commit?status=${status}`);
      handleClick('success', message)
      loadData()
    } catch (e) {
      handleClick('error', 'Error committing wig')
    }
  }
 
 return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TabBox>
          <TabContainer elevation={0} tabNames={Tabs} value={value} handleChange={handleChange} />
        </TabBox>
      </Grid>

      {loading ? <Grid item xs={12}><LinearProgress /></Grid> :
      <>
        <TabContent panel={0} value={value}>
        <Grid item xs={12}>
          <StatsBar info={info} commit={toggleCommit} currently={currently} />
        </Grid>
      <Grid item xs={4}>
        <WigInfo variant="outlined">
          <CardHeader className="info-title" title="Details" action={
            <FormDialog
              toggle={<IconButton><EditIcon color="secondary" /></IconButton>}
              title="Edit Inventory Item"
              onSave={submitEdits}
              save={editLoading ? <CircularProgress color="secondary" /> : "Save Changes"}
              size="xs"
              >
                <FieldRow handler={handleEdit} title="Wig Color" name="style" val={info.style} />
                <FieldRow handler={handleEdit} title="Wig Length" name="lengthNum" val={info.lengthNum} />
                {/* <FieldRow handler={handleEdit} title="Cap Size" name="capSize" val={info.capSize} /> */}
                <Flex dir="row" justify="flex-start" pad="0" padY="10px">
                <FieldTitle>Cap Size:</FieldTitle>
                  <FormControl variant="outlined" fullWidth>
                    {/* <InputLabel>Cap Size</InputLabel> */}
                    <Select
                      name="capSize"
                      defaultValue={info.capSize}
                      onChange={handleEdit}
                      // label="Cap Size"
                      variant="outlined"
                      fullWidth
                    >
                      <MenuItem value="S">S</MenuItem>
                      <MenuItem value="M">M</MenuItem>
                      <MenuItem value="L">L</MenuItem>
                    </Select>
                  </FormControl>
                </Flex>
                <FieldRow handler={handleEdit} title="Product Title" name="title" val={info.title} />
                <FieldRow handler={handleEdit} title="Wig Description" name="description" val={info.description} />
                <FieldRow handler={handleEdit} title="Wig Hairstyle" name="hairStyle" val={info.hairstyle} />
                <FieldRow handler={handleEdit} title="Base Material" name="baseMaterial" val={info.baseMaterial} />
                <FieldRow handler={handleEdit} title="Retail Price" name="price" val={info.sale?.subtotal} />
              </FormDialog>
          } />
          <CardContent>
            <Info><InfoLabel>ID:</InfoLabel> {info.wigId}</Info>
            <Info><InfoLabel>Brand:</InfoLabel> {info.brand}</Info>
            <Info><InfoLabel>Color:</InfoLabel> {info.style}</Info>
            <Info><InfoLabel>Length:</InfoLabel> {info.length}</Info>
            <Info><InfoLabel>Cap Size:</InfoLabel> {info.capSize}</Info>
            <Info><InfoLabel>Rooting:</InfoLabel> {info.rooting ? "Rooted" : "Not Rooted"}</Info>
            <Info><InfoLabel>Hair Style:</InfoLabel> {info.hairstyle}</Info>
            <Info><InfoLabel>Base Material:</InfoLabel> {info.baseMaterial}</Info>
            <Info><InfoLabel>Highlights:</InfoLabel> {info.highlights}</Info>
            <Info><InfoLabel>Line:</InfoLabel> {info.line}</Info>
            <Info><InfoLabel>Product Title:</InfoLabel> {info.title}</Info>
            <Info><InfoLabel>Description:</InfoLabel> {info.description}</Info>
            <Info><InfoLabel>Order Num:</InfoLabel> {info.shipmentNum}</Info>
            {info.status === "consignment" ? <Info><InfoLabel>Committed:</InfoLabel> {info.availability === "committed" ? "Yes": "No"}</Info> : null}
          </CardContent>
        </WigInfo>
      </Grid>
      

      <Grid item xs={8}>
        <TLContainer variant="outlined">
         <CardHeader title={<HistoryTitle><UpdateRoundedIcon /> Wig History</HistoryTitle>} />

        <Timeline>
          {info.history && info.history.map((el, i) => (
          <TimelineListItem key={i}>
          {/* <TimelineOppositeContent>
            {DateTime.fromISO(el.date).toFormat('cccc, MMMM dd, yyyy, h:mm a')}
            </TimelineOppositeContent> */}
            <LinkItem op={el.op} linkId={el.refId} shipment={info.shipment}>
              <TimelineSeparator>
                  <TimeLineBox color={i % 2 === 0 ? "primary" : "secondary"}>
                    <Icon choose={el.op} />
                  </TimeLineBox>
                  {/* <Connector /> */}
                {info.history.length - 1 !== i ? <Connector /> : null}
              </TimelineSeparator>
            </LinkItem>
            <Content>
                <Message>
                <span className='date'>{DateTime.fromISO(el.date).toFormat('cccc, MMMM dd, yyyy, h:mm a')}</span>
                  <h4>
                     Wig was {el.op} {el.to && `to ${el.to.name}`}
                  </h4>
                  <span>
                    By {el.by?.name}
                  </span>
                </Message>
            </Content>
         </TimelineListItem>
          ))}

        </Timeline>

        </TLContainer>
      </Grid>
      </TabContent>
      <TabContent panel={1} value={value}>
      <Grid item xs={5}>
          <WigInfo variant="outlined">
            <CardHeader className="info-title" title="Accounting" />
            <CardContent>
              {info.cost ? 
                <>
                  <Info><InfoHeader>Cost Info</InfoHeader></Info>
                  <Hr />
                  <Info><InfoLabel>Subtotal:</InfoLabel> <span className="number">{currencyFormat(info.cost?.subtotal)}</span></Info>
                  <Info><InfoLabel>Total:</InfoLabel> <span className="number">{currencyFormat(info.cost?.total)}</span></Info>
                </> : null
              }

              <Info><InfoHeader className="sale">Sale Info</InfoHeader></Info>
              <Hr />
              <Info><InfoLabel>Wig Price:</InfoLabel> <span className="number">{currencyFormat(info.sale?.subtotal)}</span></Info>
              <Info><InfoLabel>Sale Total:</InfoLabel> <span className="number">{currencyFormat(info.sale?.total)}</span></Info>
              {info.cost ? <Info><InfoLabel>Profit:</InfoLabel> <span className="number">{currencyFormat(info.profit)}</span></Info> : null }
              

              {info && info.status === 'sold' ? <>
              <Info><InfoHeader className="sale">Paid Status</InfoHeader></Info>
              <Hr />
              
              <Info><InfoLabel>Paid Total:</InfoLabel> { currencyFormat(info.sale?.paidAmount) } </Info>
              
              <Info><InfoLabel>Paid Status:</InfoLabel> <Status color={color} bgColor={bgColor} statusText={statusText} size="small" /> </Info>
              </>
              : null }

            </CardContent>
          </WigInfo>
        </Grid>
        {payments && payments.length ? 
        <Grid item xs={7}>
          <Card elevation={0} style={{backgroundColor: "transparent"}}>
            <CardHeader title="List of Payments" />
            <CardContent>
              {payments && payments.map((payment, i) => (
                <PaymentTile key={i} payment={payment} loadData={loadData} />
              ))}
            </CardContent>
          </Card>
        </Grid> : null }
      </TabContent>
      <TabContent panel={2} value={value}>

        <Grid item xs={5}>
          <Card variant="outlined">
            <CardHeader title="Summary" />
            <CardContent>
            <Info><InfoLabel>Wig ID:</InfoLabel> { info?.wigId } </Info>
            <Info><InfoLabel>No. of Repairs:</InfoLabel> { repairs?.length } </Info>
            <Info><InfoLabel>Total Amount:</InfoLabel> { currencyFormat(repairs?.reduce((acc, cur) => acc + cur?.total, 0)) } </Info>
            <Info><InfoLabel>Currently In Repair:</InfoLabel> { info?.inRepair ? "Yes" : "No" } </Info>
            <Info><InfoLabel>Current Location:</InfoLabel> { info?.currentLocation?.entity ? info?.currentLocation?.name || "HQ" : info?.currentlyAt?.name || "HQ"} </Info>
            <Info><InfoLabel>Transferred Last:</InfoLabel> { formatGenericDate(info?.transferred_last) } </Info>
            </CardContent>
          </Card>
        </Grid>
        
        <Grid item xs={7}>

          <Card variant="outlined">
            <CardHeader title={<BasicFlex justify="flex-start" gap='1.5rem'><LocalShippingOutlinedIcon /><span>Transfer History</span></BasicFlex>} />
              
            <Timeline>
              {transfers && transfers.map(({date, from, to, addedBy, transferId, _id}, i) => (
                <TimelineDisplay 
                  key={i} 
                  id={i} 
                  date={date} 
                  collection="repair_transfers" 
                  linkId={_id} 
                  icon={<RepeatRoundedIcon />}
                  msg={`Transfer #${transferId} from ${from?.name || "HQ"} to ${to?.name || 'HQ'}`}
                  by={addedBy}
                  len={transfers.length}
                />
              ))}
              </Timeline>
          </Card>

        </Grid>

        <Grid item xs={12}>

          <Card variant="outlined">
            <CardHeader title="Repairs" />

            <CardContent>
              <SimpleTable headers={repairHeaders} rows={repairs} link='repairs' />
            </CardContent>
          </Card>

        </Grid>
      </TabContent>
      </>
       }
    </Grid>
 )
}

export default WigDisplay;