import React, {useEffect, useState, useCallback, useRef} from 'react';
import styled, { css } from 'styled-components';
import { Formik, Form, Field } from 'formik';
import axios from '../../axios';
import {Grid, Paper, Button, InputAdornment, LinearProgress,
  CircularProgress, MenuItem, InputLabel, FormControl, FormHelperText, FormControlLabel} from '@material-ui/core';
import { TextField, Select } from 'formik-material-ui';
import * as Yup from 'yup';
import { handleErrors, createNewOption } from '../../utils/util';
import {useSBContext} from '../../utils/snackbar';

import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import PostAddRoundedIcon from '@material-ui/icons/PostAddRounded';

import Sidebar from './Components/Sidebar';

import { debounce } from 'lodash'

import { SectionHeader, SectionHeaderRow, FullDivider } from '../../utils/common'

import systemColors from '../../utils/colors';

import EditForm from '../../utils/editForm';

import Autocomplete from '../../utils/autoComplete';

import { theme } from '../../utils/theme';

import LineItem from './Components/LineItem';


const Row = styled(Grid)`
  min-height: 10rem;
  /* width: 100%; */
  /* background-color: pink; */
  padding-top: 4rem;
`
 
const AddWorkOrder = (props) => {

  const {id} =  props.match.params;
 
  const [info, SetInfo] = useState({});

  const [loading, setLoading] = useState(false)

  const [brand, setBrand] = useState("")

  const [vendors, setVendors] = useState([])

  const [orderLineItems, setOrderLineItems] = useState([
  //   {
  //   lengthNum: "",
  //   color: "",
  //   capSize: "",
  //   baseMaterial: "",
  //   line: "",
  //   hairstyle: "",
  //   quantity: 1,
  //   price: 0,
  //   canEdit: false
  // }
])

  const [categories, setCategories] = useState([])

  const [includeMaterial, setIncludeMaterial] = useState(false);
  const [includeColor, setIncludeColor] = useState(false);

  const [costModel, setCostModel] = useState({});

  const [systemValues, setSystemValues] = useState({
    lengths: [],
    colors: [],
    capSizes: [],
    baseMaterials: [],
    capSizes: [],
    hairstyles: [],
    lines: []
  });

  const vendorRef = useRef();

  const [total, setTotal] = useState(0);


  const [liErrors, setLiErrors] = useState({});
  const [items, setItems] = useState({});

  const addLineItem = (lineItem) => {
    let rand = Math.random();
    let newItem = {...lineItem, rand};
    setOrderLineItems(oli => oli.concat(newItem));
  }

  const removeLineItem = (index) => {
    setLiErrors({});
    setOrderLineItems(oli => {
      let newItems = [...oli];
      newItems.splice(index, 1);
      return newItems;
    })
  }

  const setLineItem = (e, index, field) => {
    const val = e.target.value || e.target.checked;
    let concatVal = "";
    setOrderLineItems(oli => {
      let newItems = [...oli];
      let item = newItems[index];
      item[field] = val;
      concatVal = item.lengthNum?.toString();
      if (includeMaterial) {
        concatVal += item.baseMaterial;
      }
      if (includeColor) {
        concatVal += item.color;
      }
      let qty = item.qty
      console.log(concatVal, "concatVal")
      if (costModel[concatVal]) {
        if (!item.canEdit) {
          item.cost = costModel[concatVal];
        }
        if (+qty) {
          item.price = item.cost * qty;
        } else {
          item.price = 0;
        }
      } else {
        item.cost = 0;
        item.price = 0;
      }
      return newItems;
    })

  }

  const checkItems = (values, fieldName) => {
    let errors = {};
    let duplicates = {};
    let valid = true;
    let requiredFields = [{field:"lengthNum", label: "Length"}, {field: "color", label: "Color"}, {field: "capSize", label: "Cap Size"}];
    if (includeMaterial) {
      requiredFields.push({field: "baseMaterial", label: "Material"})
    }
    values.forEach((item, i) => {
      errors[`${i}`] = {};
      requiredFields.forEach(({field, label}) => {
        if (!item[field]) {
          valid = false;
          errors[`${i}`] = {msg: `${label} is required`};
        }
      })
      
      let itemName = `${item.color}${item.lengthNum}${item.capSize}${item.baseMaterial || ''}${item.hairstyle || ''}${item.line || ''}`;
      console.log(itemName, "itemName")
      if (duplicates[itemName]?.name) {
        valid = false;
        errors[`${i}`].dup = true;
        errors[`${duplicates[itemName].index}`].dup = true;
      } else {
        duplicates[itemName] = {name: itemName, index: i};
      }
    })
    console.log(errors, "errors in function")
    return {valid, errors};
  }

  const loadData = async () => {
    try {
      setLoading(true)
      if (id) {
        const {data} = await axios.get(`/work_orders/${id}`);
        SetInfo(data);
        setOrderLineItems(data.lineItems);
      }
      const promises = [axios.get('/styles/'), axios.get('/system_settings/system_values'), axios.get(`/system_settings/pricing?brand=${brand}&getSchema=true`)];
      const [styles, systemValues, pricing] = await Promise.all(promises);
      let sysValueSettings = systemValues.data.settings;
      setSystemValues({
        lengths: sysValueSettings.lengths,
        colors: styles.data,
        capSizes: sysValueSettings.capSizes,
        materials: sysValueSettings.materials,
        hairstyles: sysValueSettings.hairstyles,
      })

      let pricingData = pricing.data?.prices;
      let costSchema = pricing.data?.costSchema || {};
      setCostModel(costSchema);
      if (pricingData?.settings?.includeMaterial) {
        setIncludeMaterial(true);
      }
      if (pricingData?.settings?.includeColor) {
        setIncludeColor(true);
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

 

  useEffect(() => {
    // if (id) 
      loadData()
  }, [])

  // console.log(info);

  const action = id ? 'edit' : 'add';
  const successMsg = action === "edit" ? "Work Order successfully updated" : "Work Order successfully added";

  let crumbs = [{path: '/work_orders/', text: 'Work Order'}];

  if (action === "edit")
    crumbs.push({path: `/work_orders/view/${id}`, text: 'View'})

  crumbs.push({path: '/work_orders/edit', text: action === "edit" ? 'Edit' : 'Add'});

  const EditSchema = Yup.object().shape({
    vendor: Yup
      .string()
      .required('Please select Vendor')
  });

  const {handleClick} = useSBContext();


  const onSubmit = async (userInfo={}, { setSubmitting, setErrors}) => {

    userInfo.lineItems = orderLineItems;
    userInfo.pricingByMaterial = includeMaterial;
    userInfo.pricingByColor = includeColor;

    let {valid, errors} = checkItems(userInfo.lineItems, "lineItems");
    if (!valid) {
      setLiErrors(errors);
      handleClick('error', 'Please fill out all required fields, and remove duplicate items')
      setSubmitting(false);
      return;
    }

    if (!userInfo.vendor) {
      handleClick('error', "Work Order requires vendor")
      setSubmitting(false)
    }

    userInfo.vendor = {
      id: userInfo.vendor,
      name: vendorRef?.current?.value
    }

    console.log(userInfo.vendor, "vendor....")

    try {
      let query = !id ? axios.post('/work_orders/', {...userInfo}) : axios.put(`/work_orders/${id}`, {...userInfo});

      const {data} = await query;
      setSubmitting(false)
      handleClick('success', successMsg)
      props.history.push(`/work_orders/`)
      // props.history.push(`/payments/view/${id || data._id}`)
    } catch (e) {
      setSubmitting(false);
      const displayMessage = handleErrors({e, setErrors})
      if (displayMessage)
        handleClick('error', displayMessage)
    }
  }

  let newDate;

  useEffect(() => {
    newDate = new Date();
  })

  const handleChangeVendor = useCallback(debounce(async (val) => {
    console.log(val, "val")
    const {data} = await axios.get(`/vendors?term=${encodeURIComponent(val)}&vendorType=manufacturing`)
    setVendors(data.docs);
  }, 250), [info]);
 
 return (
 <EditForm title={action === "edit" ? "Edit Work Order" : "New Work Order"} header="Work Order" crumbs={crumbs}
  sidebar={<Sidebar lineItems={orderLineItems} vendor={vendorRef} />}
 >
   {loading ? <LinearProgress /> :
   <Formik
    enableReinitialize
    initialValues={
      info.vendor ? {...info, vendor: info?.vendor?.id} : 
      {
        vendor: "",
        orderId: "",
        // shipping: "",
        notes: "",
      }
    }
    validationSchema={EditSchema}
    onSubmit={onSubmit}
   >
    {
      ({submitForm, isSubmitting, setFieldValue, setErrors, errors, touched, values}) => (
        <Form>
          {/* {console.log(errors, values, "checkem")} */}
          <Grid container spacing={4}>

          <SectionHeaderRow item xs={12}>
            <SectionHeader>General Info</SectionHeader>
          </SectionHeaderRow>

            <Row item xs={12} md={6}>
              <Autocomplete
               list={vendors} name="vendor" 
               field={'_id'} 
               label="Select Vendor"
               getOptionLabel={({name}) => name || ""} 
               getOptionSelected={({name}, val) => name === val.name}
               renderOption={(opt) => <span>{opt.name}</span>}
               handleChange={handleChangeVendor}
               autoHighlight
               autoSelect
               inputRef={vendorRef}
               blur
               setter={setFieldValue}
               value={{name: info?.vendor?.name}}
               err={errors.vendor ? errors.vendor : ''}
              />
            </Row>

            {/* {console.log(errors, values,  "errors")} */}

            <Row item xs={12} lg={6}>
              <Field
                component={TextField}
                type="text"
                label="Order Number (Optional)"
                name="orderId"
                variant="outlined"
                fullWidth
              />
            </Row>

            <Grid item xs={12}>
              <FullDivider />
            </Grid>

            <SectionHeaderRow item xs={12}>
              <SectionHeader>Work Order Items</SectionHeader>
            </SectionHeaderRow>

            <Row item xs={12}>
              {
                orderLineItems.map((item, i) => (
                  <LineItem key={i} itemInfo={item} index={i} errors={liErrors} setLineItem={setLineItem} systemValues={systemValues} remove={removeLineItem} />
                ))
              }
              <Button
                startIcon={<PostAddRoundedIcon />}
                variant="outlined"
                color="secondary"
                onClick={() => addLineItem({price: 0, qty: 1})}
              >Add Line Item</Button>
            </Row>

            {/* <Grid item xs={12}>
              <FullDivider />
            </Grid>

            <SectionHeaderRow item xs={12}>
              <SectionHeader>Additional Charges</SectionHeader>
            </SectionHeaderRow>

            <Row item xs={3}>
              <Field
                component={TextField}
                type="number"
                label="Shipping Cost"
                name="shipping"
                variant="outlined"
                fullWidth
              />
            </Row> */}

            <Grid item xs={12}>
              <FullDivider />
            </Grid>

            <SectionHeaderRow item xs={12}>
              <SectionHeader>Notes</SectionHeader>
            </SectionHeaderRow>

              {/* {console.log(orderLineItems, "orderLineItems")} */}
            <Row item xs={12}>
            <Field
              component={TextField}
              variant="outlined"
              fullWidth
              multiline
              minRows={3}
              name="notes"
              label="Notes"
              />
            </Row>

            <Grid xs={12} className="submit" item >
            <Button 
            type="reset"
            color="primary"
            disabled={isSubmitting}
            variant="outlined"
            className="button_reset"
            >Clear</Button>
            {isSubmitting ? <CircularProgress /> : 
            <Button 
            type="submit"
            color="primary"
            disabled={isSubmitting}
            variant="contained"
            startIcon={<SaveRoundedIcon />}
            >Save</Button>
            }
            </Grid>
          </Grid>
        </Form>
      )
    }
   </Formik>
  }
 </EditForm>
 )
}

export default AddWorkOrder;