import React, {useEffect, useState, useCallback, useRef} from 'react';
import styled from 'styled-components';
import { Formik, Form, Field, FieldArray } from 'formik';
import {debounce, sumBy, isEmpty} from 'lodash'
import axios from '../../axios';
import {Grid, Button, 
  CircularProgress, Divider, Card, CardContent, CardHeader, formatMs, Paper, Tooltip, IconButton, Checkbox, MenuItem} from '@material-ui/core';
import Autocomplete from '../../utils/autoComplete';
import Add from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import { TextField, CheckboxWithLabel, RadioGroup, Select} from 'formik-material-ui';

import { updatePayments } from './Common';

import DatePicker from '../../utils/DatePicker';

import { useLocation, useHistory } from 'react-router-dom';

import { useCartContext } from '../cart/cartContext';

import FormHelperText from '@material-ui/core/FormHelperText';

import SearchComponent from '../consignments/SearchComponent';

import ConsignedWigPicker from '../consignments/common';

import { Flex } from '../../utils/common';

import BarcodeComponent from '../../utils/BarcodeScanner';

import * as Yup from 'yup';
import { handleErrors, currencyFormat, calculateTotal, rateCalc, capitalize, dateEnd, autoCompleteRender } from '../../utils/util';
import { useAuthContext } from '../../auth';
import {useSBContext} from '../../utils/snackbar';

import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import SimpleTable from '../../utils/simpleTable';
import EditForm from '../../utils/editForm';
import FormDialog from '../../utils/dialog';

import Payments from './Payments';
import Charges from './Charges';

const CardContentStyles = styled(CardContent)`
  /* text-align: right; */
  font-weight: 500;
  font-size: 1.6rem;
`

const Container = styled.div`

  .MuiTypography-h5 {
    font-size: 2rem;
  }

  .MuiAutocomplete-endAdornment {
    display: none;
  }
`

const Row = styled(Grid)`
  min-height: 10rem;
  /* width: 100%; */
  /* background-color: pink; */
  padding-top: 2.5rem;
`

const BalanceLine = styled(Grid)`
  max-height: 4.5rem;
  font-size: 1.6rem;
  font-weight: 500;
  /* padding-top: 4rem; */
`

const SectionHeaderRow = styled(Grid)`
  height: 2.5rem;
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
  `

const SectionHeader = styled.h5`
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
  color: #787878;
  font-weight: normal;
`

const FullDivider = styled(Divider)`
  margin: auto -50px;
`

const ItemContainer = styled(Paper)`
  padding: .5rem 15px;
  margin: 0 auto;
  margin-bottom: 14px;
  margin-top: 14px;
  position: relative;
  width: 97%;
  border-left: 3px solid ${props => props.err ? props.theme.colors.error : props.theme.colors.secondaryMain};

  .heading-wigItem {
    position: absolute;
    top: -.8rem;
    left: 1.5rem;
    color: ${p => p.theme.colors.lightText};
    background-color: #f4f4f4;
    border-radius: 5px;
    padding: 0.2rem 1rem;
    font-size: 1.2rem;
    border: 1px solid #0000001f;
    font-weight: 600;

    .sku {
      display: inline-block;
      padding-left: 2.5rem;
      letter-spacing: 1px;
    }
  }

  .fab-buttons {
  /* position: absolute;
  right: 10px;
  top: 2px; */
}
`


const OrderItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ItemCountLabel = styled.span`
  display: block;
  font-size: 1.2rem;
  color: ${props => props.theme.colors.primaryMain};
  font-weight: 600;
  text-transform: uppercase;
  margin-right: 4rem;
  background-color: ${props => props.theme.colors.primary95};
  border: 1px solid ${props => props.theme.colors.primaryVeryLight};
  padding: .4rem 1.2rem;
  border-radius: 3px;
`

let updatedTotal = 0;
let updatedSubTotal = 0;

// will update on formik values.wigs change
const Updater = ({wigs, charges, setter}) => { 
  useEffect(() => {
    const {total, subtotal} = calculateTotal(wigs, charges);
    // console.log(total, "cheehehehehehe");
    setter({total, subtotal})
  }, [wigs, charges])

  return null;
}

const Brands = ({wigs, charges, brand, setFV, errors}) => {

  const [show, setShow] = useState(false)
  const [brands, setBrands] = useState([]);
  const [brandLoading, setBrandLoading] = useState(false)

  const handleSelect = (e) => {
    setFV("brand", e.target.value)
  }

  const loadBrands = async () => {
    try {
      setBrandLoading(true)
      const { data } = await axios.get('/brands/names')
      const brandNames = data.map(el => el.name)
      setBrands(brandNames)
      if (brandNames.length === 1) {
        setFV("brand", brandNames[0])
      }
      setBrandLoading(false)
    } catch (e) {
      setBrandLoading(false)
      // console.log(e);
    }
  }

  useEffect(() => {
    if (charges.length && !wigs.length) {
      if (!brands.length) {
        loadBrands()
      }
      setShow(true)
    } else {
      setShow(false)
    }
  }, [wigs, charges])

  if (!show || brands.length < 2) {
    return null;
  }

  return <Row item xs={12} md={7} lg={7}>
    {brandLoading ? <LinearProgress /> :
    <>
    <FormControl variant="outlined" style={{width: '100%'}} >
      <InputLabel id="brand-label">Brand</InputLabel>
      <Field
          component={Select}
          labelId="brand-label"
          name="brand"
          id="brand"
          label="Brand"
          value={brand}
          onChange={handleSelect}
          fullWidth
          error={errors ? true : false}
        >
          {brands && brands.map((el) => (
            <MenuItem key={el} value={el}>{el}</MenuItem>
          ))}
      </Field>
    </FormControl>
    <FormHelperText error={errors ? true : false}>
      Brand is required if no wigs are added
    </FormHelperText>
    </>
    }
  </Row>
}

let newDate;
 
const AddOrder = (props) => {

  const location = useLocation()

  const history = useHistory();

  const { user } = useAuthContext()

  const scanning = user.system?.settings?.scanning || {}

  const requireScanning = scanning?.order ? true : false;

  const [isSaved, setIsSaved] = useState(false)

  const [diffCust, setDiffCust] = useState(false)

  const [pathname, setPathname] = useState()

  const [dialogOpen, setDialogOpen] = useState(false)

  const { cartItems, clearCart } = useCartContext()

  const [wigErrors, setWigErrors] = useState({})

  const {id} =  props.match.params;

  const [loading, setLoading] = useState(id ? true : false) 

  const [info, SetInfo] = useState({})

  const [wigs, setWigs] = useState([]);

  const [cust, setCust] = useState([]);

  const [consignedWigs, setConsignedWigs] = useState([])

  const [isValidating, setValidating] = useState(false)

  // const [newPayments, setNewPayments] = useState([])

  const [payments, setPayments] = useState({
    allPayments: [],
    newPayments: []
  })

  const [paymentObj, setPaymentObj] = useState({
  })

  const [totals, setTotals] = useState({
    total: 0,
    subtotal: 0
  })

  const formRef = useRef()

  const custRef = useRef()


/* Controlled charges */
  const [openDialog, setOpenDialog] = useState(false);

  const [selectedDate, setSelectedDate] = useState(new Date());

  const toggleDialog = () => setOpenDialog(state => !state)

  const rowHeaders = [
    {id: 'description', label: 'Description'},
    {id: 'chargeType', label: 'Charge Type'},
    {id: 'rateType', label: 'Rate'},
    // {id: 'pw', label: 'Charge Per Item'},
    {id: 'wigId', label: 'Wig ID'},
    {id: 'amount', currency: true, label: 'Amount'},
    {id: 'calculated', label: 'Calculated', render: row => currencyFormat(row.calculated)}
  ];

  const paymentHeaders = [
    {id: 'paymentType', label: 'Payment Type'},
    {id: 'paymentAmount', currency: true, label: 'Amount'},
    {id: 'paymentInfo', label: 'Payment Info'},
    {id: 'paymentNotes', label: 'Notes'},
    {id: 'paymentWigId', label: 'Wig ID'},
    {id: 'date', date: true, label: 'Date'},
  ]

  const [charges, setCharges] = useState([])

  const [paymentErrors, setPaymentErrors] = useState([]);


  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const chargeSave = (values, setFieldTouched, setErrors, chargeType) => {
    if (!values.description) {
      setErrors({description: 'Description is required.'})
      setFieldTouched('description'); 
      return;
    }
    if (!values.amount && values.amount !== 0) {
      setErrors({amount: 'Amount is required'}); 
      setFieldTouched('amount'); 
      return;
    }
    if (values.wigId && !values.wigs.find(el => el.wigId === values.wigId)) {
      setErrors({wigId: `${values.wigId} is not included in order`});
      setFieldTouched('wigId')
      return;
    }

   const {rateType, description, amount, wigId, pw} = values;

   // add a calculated field for explicit price even for percentages
   const subtotal = sumBy(values.wigs, "price") || 0
   let calculated = values.wigId ? rateCalc(amount, rateType, values.wigs.find(el => el.wigId === values.wigId).price) : rateCalc(amount, rateType, subtotal)

   if (rateType === "fixed" && chargeType === "discount" && pw) {
    calculated = values.wigs.length * amount
   }
  //  console.log(calculated, values.wigId);

  let rand = Math.random()

   setCharges(state => state.concat({chargeType, rateType, pw, description, amount, wigId, calculated, rand}));

   values.description = '';
   values.amount = ''
   values.rateType = 'fixed';
  //  values.chargeType = 'surcharge';
   values.pw = false;
   values.wigId = '';

   return 'save';
  }

  const paymentSave = (values, setFieldValue, wal) => {

    setPaymentErrors([])

    // console.log(paymentObj, "commmmeeeonnn");

    const { paymentType, paymentInfo, paymentNotes } = values;

    let paymentTotal = 0

    let paymentsList = Object.entries(paymentObj).filter(([key, value]) => value > 0).map(([key, value]) => {
      let payItem = {paymentType, paymentAmount: value, paymentInfo, date: selectedDate.toISOString(), paymentNotes }
      if (key === "remain") {
        payItem.remainingBalance = true
        payItem.paymentWigId = ""
      } else {
        payItem.paymentWigId = key
      }
      paymentTotal += value
      return payItem
    })

    // console.log(paymentsList, paymentTotal);

    if (paymentTotal <= 0) {
      setPaymentErrors({paymentAmount: 'Amount is required'})
      return;
    }

    let customer = id ? info.to?.id : cust.find(el => el._id.toString() === values.customer)

  //   // console.log(customer);


    // console.log({wal, paymentTotal});

    if (wal <  paymentTotal) {
      setPaymentErrors({paymentAmount: 'Customer does not have enough money in account'}); 
      return;
    }
   
   setPayments(state => {
    return {
      allPayments: state.allPayments.concat(paymentsList),
      newPayments: state.newPayments.concat(paymentsList)
    }
   });

   setFieldValue("lineItems", values.lineItems.map(el => ({...el, check: false})))

  //  setNewPayments(state => state.concat(paymentsList));

   setPaymentObj({})

   values.paymentType = 'Other';
   values.paymentInfo = '';
   values.paymentNotes = "";

   setSelectedDate(new Date())

   return 'save';
  }


  const chargeDelete = (index, chargeType) => {
    setCharges(state => {
      let findIndex = state.filter(el => el.chargeType === chargeType).find((el, i) => i === index)
      let identifier = findIndex._id || findIndex.rand

      // console.log(index, findIndex, "eheyeyeyeye");
      return state.filter((el) => {
        let id = el._id || el.rand
        return id !== identifier
      })
    })
  }

  const paymentDelete = (index) => {
    setPayments(state => {
      // used to calculate payment position in newPayments array
      let lenAll = state.allPayments.length
      let lenNew = state.newPayments.length
      let diff = lenAll - lenNew
      return {
        allPayments: state.allPayments.filter((el, i) => i !== index),
        newPayments: state.newPayments.filter((el, i) => i !== index - diff)
      }
    })
    return "done"
  }

  /* end controlled charge components */
  
  const handleChangeWigs = useCallback(debounce(async (val, e) => {
    if (e) {
      // NEW!! added availability option to limit committed wigs
    const {data} = await axios.get(`/inventory?term=${encodeURIComponent(val)}&status=inventory&availability=true`)
    setWigs(data);
    }
  }, 250), [])

  const handleChangeCust = useCallback(debounce(async val => {
    const {data} = await axios.get(`/customers?term=${encodeURIComponent(val)}`)
    setCust(data);
  }, 250), [])

  const loadData = async () => {
    const {data} = await axios.get(`/orders/${id}?populated=to.id`)

    SetInfo(data);

    if (data.wigs)
      setWigs(data.wigs)

    if (data.charges)
      setCharges(data.charges);

    if (data.payments)
      setPayments({
        allPayments: data.payments,
        newPayments: []
      })

    setLoading(false)
  }

  useEffect(() => {
    if (id) 
      loadData();
  }, [])


  const action = id ? 'edit' : 'add';
  const successMsg = action === "edit" ? "Sale successfully updated" : "Sale successfully created";

  let crumbs = [{path: '/orders/', text: 'Sales'}, {path: id ? `/orders/edit/${id}` : '/orders/edit', 
  text: id ? 'Edit' :'Add'}];

  if (action === "edit")
    crumbs.push({path: `/orders/edit/${id}`, text: `${info?.orderId || ""}`})

  // crumbs.push({path: '/orders/edit', text: action === "edit" ? 'Edit' : 'Add'});

  const EditSchema = Yup.object().shape({
    customer: Yup
      .string()
      .required('Customer name is required.'),
    wigs: Yup.array()
    .of(
      Yup.object().shape({
        wigId: Yup
          .string()
          .required('wig ID is required'),
        price: Yup
          .number()
          .required('price is required')
      })),
    // date: Yup.date().required("Sale date is required"),
    // dueDate: Yup.date().min(Yup.ref('date'), "Due date cannot be before sale date"),
    brand: Yup.string()
      .when("wigs", (wigs, schema) => {
        if (!wigs.length && charges.length) {
          return schema.required("Brand is required")
        }
        return schema
      })
  });

  
  const resetHandle = (e, {setFieldValue}) => {
    setFieldValue('customer', '')
    setCharges([])
  }

  const {handleClick} = useSBContext();

  const displayNotification = (setSubmitting, msg, status = "error") => {
    setSubmitting(false)
    return handleClick(status, msg)
  }


  const onSubmit = async (userInfo={}, { setSubmitting, setErrors}) => {

    setIsSaved(true)

    const {wigs, customer, notes, invoiceNotes, orderId, dueDate, date, brand, lineItems, nameOnInvoice} = userInfo;

    console.log(lineItems, "itemememememe")


    let orderDate = new Date(date).toISOString()

    let refunds = id ? info.refunds : [];

    let wigObj = {}
    let duplicates = false

    let brandName, brandDiff = false;

    let errs = {}

    wigs.forEach(el => {
      if (brandName && brandName !== el.brand) {
        brandDiff = true;
      }

      brandName = el.brand;

      if (wigObj[el.wigId]) {
        errs[el.wigId] = true
        return duplicates = true
      } else {
        wigObj[el.wigId] = el.wigId
      }
    });
    
    if (wigs.length && brandDiff) {
      return displayNotification(setSubmitting, 'Cannot add wigs of different brands on same order')
    }

    if (duplicates) {
      setWigErrors({...errs})
      return displayNotification(setSubmitting, 'You cannot add same wig twice')
    }


      /**
     * Checking for mismatching payments Ids
     */

      let mismatch = ""
      let dateIssue = false



      if (payments.allPayments.length) {
      //  console.log("HEHRHEHERHERHERHERHERHEH", payments.allPayments);

        payments.allPayments.forEach(el => {
        // console.log(orderDate, "DATATATATA");

          if (dateEnd(el.date) < orderDate){
            dateIssue = true
          }

          if (el.paymentWigId) {
            if (!wigObj[el.paymentWigId]) {
              mismatch = el.paymentWigId
            }
          }

        })
       }

       if (mismatch){
        if (!refunds.length || !refunds.find(el => el.wigId === mismatch)) {
          return displayNotification(setSubmitting, `Please remove payment for wig ID ${mismatch}, wig not found in order.`)
        }
      }

      if (dateIssue) {
        return displayNotification(setSubmitting, `Date on payment can not be before date of sale`)
      }

    const newPayments = updatePayments(lineItems, payments.allPayments, totals.total)

    const order = { wigs, date, dueDate, orderId, charges, payments: newPayments, refunds, customer, notes, brand, nameOnInvoice, invoiceNotes }

    try {
      let query = !id ? axios.post('/orders/', order) : axios.put(`/orders/${id}`, order);

      const {data} = await query;
      if (location?.state?.cart) {
        clearCart()
      }
      handleClick('success', successMsg)
      props.history.push(`/orders/`)
    } catch (e) {
      console.log(e);
      const displayMessage = handleErrors({e, setErrors})
      if (displayMessage)
        handleClick('error', displayMessage)
    } finally {
      setSubmitting(false)
    }
  }

  const checkDiff = (userInfo={}, { setSubmitting, setErrors, ...rest}) => {
    setValidating(true)
    setSubmitting(true)
    if (!isEmpty(rest.errors)) {
      setSubmitting(false)
      return;
    } 
    let { wigs } = userInfo;
    let curCust = custRef.current?.value

    if (wigs.length) {
      let diff = false
      wigs.forEach(el => {
        if (el.currentlyAt && el.currentlyAt !== curCust) {
          diff = true
        }
      })
      if (diff) {
        setSubmitting(false)
        return setDiffCust(true)
      }
    }
    onSubmit(userInfo, { setSubmitting, setErrors, ...rest})
  }

  

  useEffect(() => {
    newDate = new Date()
    // console.log(location, "hhhhhhh");
    if (location.state?.wigId) {
      const {wigId, brand, sku, status, currentlyAt, sale, price, description, baseMaterial, title} = location.state;
      const wig = {
        brand,
        sku,
        wigId,
        currentlyAt: currentlyAt?.name,
        status,
        description,
        base: baseMaterial,
        title,
        price: price ? price : sale?.subtotal
      }
      if (info.wigs && info.wigs.length) {
        SetInfo(x => {return {...x, wigs: x.wigs.concat({
          ...wig
        })}})
      } else {
        SetInfo(x => {return {...x, wigs: [{...wig}]}})
      }
    }

    if (location.state?.cart) {
      // console.log(cartItems, "hhehhe");

      if (info.wigs && info.wigs.length) {
        SetInfo(x => {return {...x, wigs: x.wigs.concat(cartItems)}})
      } else {
        SetInfo(x => {return {...x, wigs: [...cartItems]}})
      }
    }
  }, [location.state])
  

  useEffect(() => {
   
    const unblock = history.block((location) => {
      // console.log(location, "Hhddhhdh");
      setPathname(location.pathname)
      if (!isSaved) {
        setDialogOpen(true)
        return false;
      } else {
        return true
      }
    }
    )

    if (isSaved && pathname) {
      // console.log("ehehhehhehhe");
      unblock()
      if (pathname)
        history.push(pathname)
    }

    return () => {
      unblock();
    }
}, [isSaved])

 
 return (
 <EditForm title={action === "edit" ? "Edit Sale" : "New Sale"} header="Sale" crumbs={crumbs} 
 sidebar={

 <Card variant="outlined">
    <CardHeader title="Summary"></CardHeader>
    <CardContentStyles>
      Subtotal: { currencyFormat(totals.subtotal)}
      <Divider style={{margin: "10px 0"}} />
      Total: { currencyFormat(totals.total)}
      <Divider style={{margin: "10px 0"}} />
      Balance: {payments.allPayments.length ? currencyFormat(+totals.total - sumBy(payments.allPayments, 'paymentAmount')) : currencyFormat(totals.total)} 
      <Divider style={{margin: "10px 0"}} />
      Total Paid: {payments.allPayments.length ? currencyFormat(sumBy(payments.allPayments, 'paymentAmount')) : `$0.00`}
    </CardContentStyles>
  </Card>

}
>

   <Container>
     {loading ? <Grid container><Grid item xs={12}><LinearProgress /></Grid></Grid> :
   <Formik
    innerRef={formRef}
    enableReinitialize
    initialValues={{
      wigs: info.wigs || [],
      date: info.date || newDate,
      dueDate: info.dueDate || newDate,
      nameOnInvoice: info.nameOnInvoice || '',
      orderId: "",
      customer: info.to?.id?._id || '',
      description: '',  // charge description name
      amount: '',
      chargeType: 'surcharge',
      rateType: 'fixed',
      pw: false,
      wigId: '',
      notes: info.notes || '',
      invoiceNotes: info.invoiceNotes || '',
      lineItems: [],
      paymentType: 'Other',
      paymentInfo: '',
      paymentNotes: '',
      checkWig: [],
      brand: ""
    }}
    validationSchema={EditSchema}
    onSubmit={checkDiff}
    onReset={resetHandle}
   >
    {
      ({submitForm, isSubmitting, values, setValues, setFieldTouched, setFieldValue, setErrors, errors, touched, handleChange, setSubmitting, validateField}) => {

        // console.log(values, "custoemr", cust);
        // console.log(errors)
        // console.log(touched);

        console.log(values.lineItems, payments.allPayments, "lineItems");


        const {total: calculatedTotal, subtotal: calculatedSubtotal} = calculateTotal(values.wigs, charges);
        updatedTotal = calculatedTotal;
        updatedSubTotal = calculatedSubtotal;

        return (
          <Form>
            <FormDialog
              forceOpen={dialogOpen}
              forceClose={() => setDialogOpen(false)}
              title="Confirm Leave Page"
              save="Confirm Leave"
              onSave={() => {
                setIsSaved(true)
                return "done"
              }}
          >
            Are you sure you want to leave the page without saving your Changes?
          </FormDialog>
          <FormDialog
            forceOpen={diffCust}
            forceClose={() => setDiffCust(false)}
            title="Confirm Save"
            save="Confirm Save"
            onSave={() => {
              onSubmit(values, { setErrors, setSubmitting})
              return "done"
            }}
          >
            Some of the wigs on this sale, are currently consigned to other customers. Do you wish to proceed?
          </FormDialog>
            <Updater wigs={values.wigs} charges={charges} setter={setTotals} />
          <Grid container spacing={3} alignItems="center">

            {/* <Grid item xs={8}></Grid> */}

          <SectionHeaderRow item xs={12}>
            <SectionHeader>General Info</SectionHeader>
          </SectionHeaderRow>

          {/* <Row item xs={12} md={4} lg={4}>
          
            <Field
              component={TextField}
              type="string"
              label="Invoice No. (Optional)"
              name="orderId"
              variant="outlined"
              fullWidth
            />
            </Row> */}

            <Row item xs={12} md={4} lg={4}>
            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
            <DatePicker
                // component={KeyboardDatePicker}
                label="Sale Date"
                name="date"
                dtb
                fullWidth
              />
            {/* </MuiPickersUtilsProvider> */}
            </Row>

            <Row item xs={12} md={4} lg={4}>
            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
            <DatePicker
                // component={KeyboardDatePicker}
                label="Due Date"
                name="dueDate"
                // disablePast
                dtb
                fullWidth
              />
            {/* </MuiPickersUtilsProvider> */}
            </Row>

            {/* {console.log(errors, touched, "ERORORORORORORO")} */}

            <Row item xs={12} md={6} lg={6}>
              <Autocomplete
               list={cust} 
               name="customer" 
               field={'_id'} 
               label="Customer Name"
               getOptionLabel={({name}) => name || ""} 
               getOptionSelected={({name}, val) => name === val.name}
               renderOption={(opt) => <span>{opt.name}</span>}
               handleChange={handleChangeCust}
               autoHighlight
               autoSelect
               inputRef={custRef}
               blur
              //  addFunc={loadConsigned}
               setter={setFieldValue}
               value={{name: info?.to?.name}}
               err={errors.customer && isValidating ? errors.customer : ''}
              />
            </Row>

            <Row item xs={12} md={6} lg={6}>
              <Field
                component={TextField}
                type="string"
                label="Name on Invoice (Optional)"
                name="nameOnInvoice"
                variant="outlined"
                fullWidth
              />
              <FormHelperText>Name to display on Invoice</FormHelperText>
            </Row> 

            <Brands wigs={values.wigs} charges={charges} setFV={setFieldValue} brand={values.brand} errors={errors.brand} />

            <Grid item xs={12}>
              <FullDivider />
            </Grid>     

            <ConsignedWigPicker values={values} setFieldValue={setFieldValue} custId={values?.customer} custName={custRef?.current?.value} consignedWigs={consignedWigs} setConsignedWigs={setConsignedWigs} />

            <SectionHeaderRow item xs={12}>
              <OrderItemRow>
              <SectionHeader>Sale Items</SectionHeader>
             {values.wigs?.length ? <ItemCountLabel>{values.wigs?.length} Items</ItemCountLabel> : null}
              </OrderItemRow>
            </SectionHeaderRow> 

            <Grid item xs={12}>
              <SearchComponent wigs={values.wigs} setFieldValue={setFieldValue} />
            </Grid>

            <FieldArray
             name="wigs"
             render={arrayHelpers => (
               <>
                 {values.wigs && values.wigs.length > 0 ? (
                   values.wigs.map((wig, index) => {
                    return (
                     <ItemContainer key={`${wig.wigId ? wig.wigId + wig.random : wig.random}`} variant='outlined' err={wigErrors[wig.wigId] ? true : false}>
                     { wig.wigId ? <div className='heading-wigItem'>{capitalize(wig.status)} {wig.currentlyAt ? `- ${wig.currentlyAt}` : null} <span className='sku'>{wig.skuDisplay || wig.sku}</span></div> : null }
                       <Row container item xs={12}  spacing={3} alignItems="center">

                        <Grid item xs={8}>

                        <Autocomplete
                          list={wigs} name={`wigs.${index}.wigId`} 
                          index={index} arrList="wigs" field="wigId" label="Wig ID"
                          getOptionLabel={({wigId}) => wigId || ""} 
                          getOptionSelected={({wigId}, val) => wigId === val.wigId}
                          renderOption={autoCompleteRender}
                          handleChange={handleChangeWigs}
                          autoHighlight
                          autoSelect
                          blur
                          addRandom
                          setter={setFieldValue}
                          size="small"
                          value={{wigId: values.wigs[index].wigId}}
                          updates={[{name: `brand`, field: 'brand'}, {name: `sku`, field: 'sku'}, {name: "skuDisplay", field: "skuDisplay"}, {name: `title`, field: 'title'}, {name: `description`, field: 'description'}, {name: `base`, field: 'baseMaterial'}, {name: `price`, field: 'sale.subtotal'}, {name: `status`, field: "status"}, {name: `currentlyAt`, field: "currentlyAt.name"}, {name: 'searchWigId', field: 'searchWigId'}]}
                        />
                         
                        </Grid>

                        {/* {console.log(errors, "ERORORORORORORO")} */}

                        <Grid item xs={3}>
                          <Field
                            component={TextField}
                            type="number"
                            label="Price"
                            name={`wigs.${index}.price`}
                            variant="outlined"
                            size="small"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={1}>
                        <Tooltip title="Delete Item" className="fab-buttons">
                        <IconButton aria-label="delete" onClick={() => {
                            setWigErrors(x => ({...x, [wig.wigId]: false}))
                           arrayHelpers.remove(index)
                          }}>
                          <DeleteIcon color="secondary" />
                        </IconButton>
                      </Tooltip>
                      </Grid>
                      
                     </Row>
                     </ItemContainer>
                   )})
                 ) : null
                 }
                
                <Row item xs={4}>
                  <Flex dir="row" justify="flex-start" pad="0" height="7.5rem">
                    {!requireScanning ? <Button
                      variant="contained"
                      color="primary"
                      startIcon={<Add />}
                      onClick={() => {
                        let rand = Math.random()
                        arrayHelpers.push({price: 0, wigId: '', random: rand})
                      }}
                    >
                      Add Wig
                    </Button> : null}
                    <BarcodeComponent wigs={values.wigs} setFieldValue={setFieldValue} required={requireScanning} />
                  </Flex>
                </Row>
                 </>
             )}
           />

            <Grid item xs={12}>
              <FullDivider />
            </Grid>

            <SectionHeaderRow item xs={12}>
              <SectionHeader>Charges</SectionHeader>
            </SectionHeaderRow>

            

            <Row container item xs={12}>
              <Grid item xs={6} md={5} lg={4}>
                <Charges openDialog={openDialog} toggleDialog={toggleDialog} chargeSave={chargeSave} values={values} bag={{setFieldTouched, setFieldValue, setErrors}} />
              </Grid>
            </Row>

            {charges.filter(cur => cur.chargeType === 'surcharge').length >= 1 && 
            <Grid item xs={12}>
              <SimpleTable rows={charges.filter(cur => cur.chargeType === 'surcharge')} headers={rowHeaders} 
               pagination={false} view={false} trash={true} field="description"
              //  edit={true} 
              //  editFunc={toggleDialog}
               trashFunc={(index) => chargeDelete(index, "surcharge")} 
               />
               {/* <Divider /> */}
            </Grid>
            }
            
            <Grid item xs={12}>
              <FullDivider />
            </Grid>

            <SectionHeaderRow item xs={12}>
              <SectionHeader>Discounts</SectionHeader>
            </SectionHeaderRow>

            

            <Row container item xs={12}>
              <Grid item xs={6} md={5} lg={4}>
                <Charges openDialog={openDialog} toggleDialog={toggleDialog} chargeSave={chargeSave} values={values} bag={{setFieldTouched, setFieldValue, setErrors}} chargeType="discount" />
              </Grid>
            </Row>

            {charges.filter(cur => cur.chargeType === 'discount').length >= 1 && 
            <Grid item xs={12}>
              <SimpleTable rows={charges.filter(cur => cur.chargeType === 'discount')} headers={rowHeaders} 
               pagination={false} view={false} trash={true} field="description"
              //  edit={true} 
              //  editFunc={toggleDialog}
               trashFunc={(index) => chargeDelete(index, "discount")} 
               />
               {/* <Divider /> */}
            </Grid>
            }

            <Grid item xs={12}>
              <FullDivider />
            </Grid>

            <SectionHeaderRow item xs={12}>
              <SectionHeader>Payments</SectionHeader>
            </SectionHeaderRow>

            
            <Grid item xs={6} md={5} lg={4}>
               <Payments saveFunc={(wal) => paymentSave(values, setFieldValue,  wal)} buttonVariant="outlined"
               dateValue={selectedDate} handleDateChange={handleDateChange} wigs={values.wigs} charges={charges} setter={setFieldValue} errors={paymentErrors} resetErr={setPaymentErrors} cust={id ? info.to?.id : cust.find(el => el._id.toString() === values.customer)} payments={id ? payments.newPayments : payments.allPayments} allPayments={payments.allPayments} lineItems={values.lineItems} paymentState={{paymentObj, setPaymentObj}} />
              </Grid>


           {payments.allPayments.length >= 1 && 
            <Grid item xs={12}>
              <SimpleTable rows={payments.allPayments} headers={paymentHeaders} 
               pagination={false} view={false} trash={true} field="paymentType"
              //  edit={true} 
              //  editFunc={toggleDialog}
               trashFunc={paymentDelete} 
               />
            </Grid>
            }

            <Grid item xs={12}>
              <FullDivider />
            </Grid>

            <SectionHeaderRow item xs={12}>
              <SectionHeader>Summary</SectionHeader>
            </SectionHeaderRow>

            {/* start change */}

           {/* <Grid item xs={false} md={7}>
           </Grid> */}
           <Grid xs={12} item className="order-total">
           

             <Card variant='outlined' style={{padding: '1.5rem', backgroundColor: "#f9f9f9"}}>
               {/* <CardHeader title="Summary"></CardHeader> */}
               <CardContent>
                <Grid container spacing={5}>
                  <BalanceLine item xs={8}></BalanceLine>
                  <BalanceLine item xs={2}>Subtotal:</BalanceLine>
                  <BalanceLine item xs={2}>{ currencyFormat(updatedSubTotal)}</BalanceLine>
                  <BalanceLine item xs={8}></BalanceLine>
                  <BalanceLine item xs={2}>Total:</BalanceLine>
                  <BalanceLine item xs={2}>{ currencyFormat(updatedTotal)}</BalanceLine>
                  <BalanceLine item xs={8}></BalanceLine>
                  <BalanceLine item xs={2}>Balance:</BalanceLine>
                  <BalanceLine item xs={2}>{payments.allPayments.length ? currencyFormat(+updatedTotal - sumBy(payments.allPayments, 'paymentAmount')) : currencyFormat(updatedTotal)}</BalanceLine>
                  <BalanceLine item xs={8}></BalanceLine>
                  <BalanceLine item xs={2}>Total Paid:</BalanceLine>
                  <BalanceLine item xs={2}>{payments.allPayments.length ? currencyFormat(sumBy(payments.allPayments, 'paymentAmount')) : `$0.00`}</BalanceLine>

                {/* Subtotal: { currencyFormat(updatedSubTotal)}<br />
                Total: { currencyFormat(updatedTotal)}<br />
                Balance: {payments.length ? currencyFormat(+updatedTotal - sumBy(payments, 'paymentAmount')) : currencyFormat(updatedTotal)} <br />
                Total Paid: {payments.length ? currencyFormat(sumBy(payments, 'paymentAmount')) : `$0.00`} */}
                
                </Grid>
               </CardContent>
             </Card>
           </Grid>

           <Grid item xs={12}>
             <FullDivider />
           </Grid>

           <SectionHeaderRow item xs={12}>
              <SectionHeader>Notes</SectionHeader>
            </SectionHeaderRow>

           <Row item xs={12} md={12}>
           <Field
              component={TextField}
              type="text"
              label="Notes"
              name="notes"
              multiline
              minRows={2}
              variant="outlined"
              fullWidth
            />
           </Row>
           <Grid item xs={12}>
             <FullDivider />
           </Grid>

           <SectionHeaderRow item xs={12}>
              <SectionHeader>Invoice Notes</SectionHeader>
            </SectionHeaderRow>

           <Row item xs={12} md={12}>
           <Field
              component={TextField}
              type="text"
              label="Notes"
              name="invoiceNotes"
              multiline
              minRows={2}
              variant="outlined"
              fullWidth
            />
            <FormHelperText>These notes will be displayed on invoice</FormHelperText>
           </Row>

           <Grid item xs={12}>
             <FullDivider />
           </Grid>

            <Row xs={12} className="submit" item>
            <Button 
              type="reset"
              color="primary"
              disabled={isSubmitting}
              variant="outlined"
              className="button_reset"
              >Clear</Button>
            <Button 
              // type="submit"
              onClick={() => checkDiff(values, { setSubmitting, setErrors, errors})}
              color="primary"
              disabled={isSubmitting}
              variant="contained"
              startIcon={<SaveRoundedIcon />}
            >{isSubmitting ? <CircularProgress size={25} /> : "Save"}</Button>
            
            </Row>
          </Grid>
        </Form>
        )
      }
    }
   </Formik>
   }
   </Container>
 </EditForm>
 )
}

export default AddOrder;