import React, {useState} from 'react';
import styled, { css } from 'styled-components';
import Table from '@material-ui/core/Table';
import Checkbox from '@material-ui/core/Checkbox';
import TableBody from '@material-ui/core/TableBody';
import Tooltip from '@material-ui/core/Tooltip';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Edit from '@material-ui/icons/Edit';
import Search from '@material-ui/icons/Search';
import Delete from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

import { useAuthContext } from '../auth';

import { get, truncate } from 'lodash';

import {Link} from 'react-router-dom';

import { formatDisplay } from './util';

import TrashDialog from './dialog';
import { CircularProgress } from '@material-ui/core';
import { Flex } from './common';

const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  a {
  color: ${props => props.theme.palette.primary.main};
  }

  .edit-icon {
    /* margin-right: 1rem; */
  }
`

const NoRows = styled.span`
  color: #777;
`

const StyledTableRow = styled(TableRow)`

  ${props => props.dense ? css`
    .MuiTableCell-root {
      padding: 1rem 1.6rem;
    }
  ` : ''}
`

const BatchActions = styled(Flex)`
  padding: .8rem;
  min-height: 3.5rem;
`

const SelectedText = styled.span`
  display: block;
  font-weight: 500;
  color: ${props => props.theme.colors.lightText};
  margin-right: 2rem;
`
 

const SimpleTable = ({headers, rows, link = '', view = true, title,
  write = false, edit = false, trash = false, editFunc, trashFunc = () => {}, trashLoading = false, actions = true, pagination = true, 
  size = 'medium', field = '_id', idReplace = false, links, rpp = 50, actionLinks, check = false, batchActions = [], dense = false, className}) => {

  const { user } = useAuthContext()

  let disp = {}
  let additional = {}

  headers.forEach(el => {
    disp[el.label] = true

    if (el.additional) {
      additional[el.label] = true
    }
  })

  let display = user.settings?.tables?.find(el => el.table === title)?.rows || disp

  display = Object.assign(display, additional)

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rpp);

  const [selected, setSelected] = useState({})

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // const rowsPerPage = pagination ?  10 : rows?.length;

  // const formatDisplay = (obj1, obj2) => {
  //   const {render} = obj1
      
  //   let string = ''
  //   const val = render ? render(obj2) : get(obj2, obj1.id)

  //   if (obj1.currency && obj2.rateType !== 'percentage') string = currencyFormat(val)
  //   else if (obj1.currency && obj2.rateType === 'percentage') string = `%${val}`
  //   else if (obj1.date) string = formatGenericDate(val)
  //   else string = val

  //   return string || string === 0 ? string : '-';
  // }

  const handleCheck = (e, id) => {
    let checked = e.target.checked;
    console.log({checked, id});
    setSelected(x => ({...x, [id]: checked ? true : false}))
  }

  const handleCheckAll = (e) => {
    let checked = e.target.checked
    let selectObj = {}
    rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).forEach(el => {
      selectObj[el._id] = checked ? true : false
    })
    console.log(selectObj, "OBOBOOBOBO");
    setSelected({...selectObj})
  }


  let numOfSelected = Object.keys(selected).filter(el => selected[el]).length

  return (
    <>
     {batchActions.length ? <BatchActions dir="row" width="80%" justify="flex-start">
        <SelectedText> {numOfSelected > 0 ? `${numOfSelected} Selected` : ""} </SelectedText>
        { numOfSelected > 0 && batchActions.length ? batchActions.map(el => 
         ( <IconButton size="small">
            <TrashDialog toggle={
              el.icon
            } 
            title={el.title}
            onSave={() => el.func(selected, setSelected)}
            save={el.loading ? <CircularProgress color="primary" /> : el.cta}
            size="xs"
            >
             {el.warningText}
            </TrashDialog>
          </IconButton> )
          ) : null }
      </BatchActions> : null }
    <Paper style={{width: '100%'}} elevation={4}>
    <TableContainer>
      <Table aria-label="simple table" size={size}>
        <TableHead>
          <TableRow>
         {check ? <TableCell padding="checkbox">
            <Checkbox
            onChange={handleCheckAll}
            />
          </TableCell> : null }
            {headers.filter(el => display[el.label]).map((header, i) => (
              <TableCell key={header.id} align={"left"}>{header.label}</TableCell>
            ))}
            {actions &&  <TableCell align="center">Actions</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows && rows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => (
            <StyledTableRow dense={dense} key={index}>
             {check ? <TableCell padding="checkbox">
              <Checkbox
                onChange={(e) => handleCheck(e, row._id)}
                checked={selected[row._id] || false}
              />
              {console.log(selected[row._id], "HODODOHADLHFLDHFLA")}
            </TableCell> : null }
              {headers.filter(el => display[el.label]).map((cur, i) => 
              <TableCell key={cur.id} align={"left"}>
                {formatDisplay(cur, row)}
              </TableCell>
              )}
              {actions &&
              <TableCell align="center">
                <ActionsContainer>
                {actionLinks && actionLinks.map(el => {
                  if (!el.condition || el.condition(row)) {
                  return (
                    <Tooltip title={el.toolTip} key={el.id}>
                      <IconButton onClick={(e) => {el.action(row)}}>{el.icon}</IconButton>
                    </Tooltip>
                  ) } else 
                    return null;
                })}
                  {links && links.length && links.map(el => {
                    if (!el.condition || el.condition(row)) {
                    return (
                      <Tooltip title={el.toolTip} key={el.link}>
                      <Link to={{pathname: `${el.link}${el.id ? !idReplace ? row._id : row[idReplace] : ""}`, state: el.state ? {...row} : {} }} >
                        <IconButton>{el.icon}</IconButton>
                      </Link>
                      </Tooltip>
                    )
                    } else {
                      return null
                    }
                  })}
                  {write && <Link to={`/${link}/edit/${row._id}`} ><IconButton size="small"><Edit color="primary" className="edit-icon" /></IconButton></Link> }
                  {edit && !write && editFunc && <IconButton onClick={editFunc} size="small"><Edit color="primary" className="edit-icon" /></IconButton>}
                  {trash && trashFunc && <IconButton size="small">
                    <TrashDialog toggle={
                      <Delete className="hover-icon" color="primary" />
                    } 
                    title="Delete?"
                    onSave={() => trashFunc(index)}
                    save={trashLoading ? <CircularProgress color='primary' /> : "Comfirm Delete"}
                    size="xs"
                    >
                      Deleting will permanently remove this information
                      from the system.
                    </TrashDialog>
                  </IconButton>
                    }
                   {view && <Link to={`/${link}/view/${!idReplace ? row._id : row[idReplace]}`} target="_blank" ><IconButton><Search color="primary" /></IconButton></Link> }
                </ActionsContainer>
              </TableCell>
              }
            </StyledTableRow>
          ))}
          {!rows || !rows.length ? <TableRow>
            <TableCell align="center" colSpan={check ? headers.length + 2 : headers.length + 1}><NoRows>No Rows</NoRows></TableCell>
            </TableRow>
            : null }
        </TableBody>
      </Table>
    </TableContainer>
    {pagination && 
    <TablePagination
    component="div"
    count={rows?.length || 10}
    rowsPerPage={rowsPerPage}
    page={page}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
  />
    }
  </Paper>
  </>
  );
}

export default React.memo(SimpleTable);
