import React, {useState, useEffect, useCallback, useRef} from 'react';
import styled from 'styled-components';
import axios from '../../axios';
import ProtectedRoute from '../../utils/ProtectedRoute';
import { Switch, Route } from 'react-router-dom'
import {FormControlLabel, FormControl, FormLabel, Switch as Switcher, TextField, InputAdornment, Checkbox, FormGroup, IconButton, Grid, LinearProgress, Tooltip, Button} from '@material-ui/core'
import Search from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/Settings';
import AddShoppingCartRoundedIcon from '@material-ui/icons/AddShoppingCartRounded';
import StoreMallDirectoryRoundedIcon from '@material-ui/icons/StoreMallDirectoryRounded';
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';


import Chip from '../../utils/Chip';
import { theme } from '../../utils/theme';
import { getDaysDiff } from '../../utils/util'

import { useSBContext } from '../../utils/snackbar';
import { useCartContext } from '../cart/cartContext';
import { useAuthContext } from '../../auth';

import StatusChip from './Components/StatusChip';

import useLoadList from '../../hooks/useLoadList';

import { FullDivider, Header } from '../../utils/common';

import Table from '../../utils/table-server';
import ViewWig from './ViewWig';

import FormDialog from '../../utils/dialog';

import { debounce } from 'lodash'

import AddInventory from './AddInventory';


const SwitchContainer= styled(FormControlLabel)`
  width: 300px;
  margin-left: 30px;
`

const FormGroupHeader = styled(FormLabel)`
  margin-top: 2rem;
`

const UpdatePriceHeader = styled.span`
  display: block;
  margin-bottom: 2rem;
`

const FilterHeader = styled(Header)`
  font-weight: 500;
`

const returnToStock = async (selected, hc, reload) => {
  try {
    const {data} = await axios.post('/consignments/stock', {wigIds: selected})
    // setInfo(x => ({...x, rows: x.rows.filter(el => !selected.includes(el._id))}))
    reload()
    hc("success", `${selected.length} Items checked in to inventory`)
    return true;
  } catch (e) {
    hc("error", `Something went wrong, please try again`)
    return false;
  }
}

const InventoryFilters = ({brands, applyFilter, filter, setQuery}) => {
  let defaultBrand = {}
  if (filter) {
    if (filter !== "all") {
      defaultBrand[filter] = true
    } else {
      defaultBrand = brands.reduce((acc, curr) => {
        acc[curr] = true
        return acc
      }, {})
    }
  } 
  const [brandFilter, setBrandFilter] = useState(defaultBrand)
  const filterBrand = (e, name) => {
    let checked = e.target.checked;
    let keys = Object.keys(brandFilter);
    if (checked) {
      setBrandFilter({...brandFilter, [name]: true})
      setQuery(prev => {
        let newFilter = {...prev}
        if (prev.brand && prev.brand !== name) {
          newFilter.brand = "all"
        } else {
          newFilter.brand = name
        }
        return newFilter
      })
    } else {
      setBrandFilter(prev => {
        let newFilter = {...prev}
        delete newFilter[name]
        return newFilter
      })
      setQuery(prev => {
        let newFilter = {...prev}
        if (prev.brand && prev.brand === 'all') {
          newFilter.brand = keys.find(el => el !== name)
        } else {
          delete newFilter.brand
        }
        return newFilter
      })
    }
  }
  return (
      <FormControl component="fieldset" variant="standard">
        {brands?.length > 1 ? <>
        <FullDivider y="1rem" />
        <FilterHeader>Filter Brands</FilterHeader>
       <FormGroup>
        {brands?.map(el => 
          <FormControlLabel
            key={el}
            control={<Checkbox 
              checked={brandFilter[el] || false} 
              onChange={(e) => filterBrand(e, el)} 
              name={`brand.${el}`} 
              color="primary" 
            />}
            label={`${el}`}
          />
        )}
      </FormGroup>
      </> : null}
      <FullDivider y="1rem" />
      <Button startIcon={<FilterListRoundedIcon />} variant="contained" size="small" color="secondary" 
      onClick={applyFilter}
      >Apply Filters</Button>
     
    </FormControl>
  )
}
 
const Inventory = (props) => {
  const {rows, loading, load, searchList, count, switcher, setSwitch, tabFilter, setTabFilter, query, handleQuery, setQuery, reload, downloadCsv} = useLoadList('/inventory', 'inventory');

  const [brandLoading, setBrandLoading] = useState(false);

  const [brands, setBrands] = useState([])

  const {handleClick} = useSBContext();
  const { user } = useAuthContext()
  const inv = user.system?.settings?.inventory

  const batchActions = [ // batch actions when items are checked
    {
      icon: <StoreMallDirectoryRoundedIcon color='secondary' />,
      title: "Check In to Stock",
      action: (selected) => returnToStock(selected, handleClick, reload)
    }
  ]

  const applyFilter = () => {
    reload();
  }

  const handleFilter = (e, field, type) => {
    let value;
    if (type === "check") {
      value = e.target.checked;
    } else if (type === "date") {
      value = e;
    } else {
      value = e.target.value;
    }
    handleQuery(field, value);
  }


  const handleTabs = (filter) => {
    setTabFilter(filter)
  }

  const tabs = [
    {
      label: "Inventory",
      default: true,
      title: "inventory",
      active: tabFilter === "inventory" ? true : false
    },
    {
      label: "All",
      title: "",
      active: tabFilter === "" ? true : false
    },
    {
      label: "Sold",
      title: "sold",
      active: tabFilter === "sold" ? true : false
    }
  ]
  

  const headers = [
    { id: 'wigId', label: 'ID' },
    { id: 'brand', label: 'Brand' },
    { id: 'line', label: 'Line' },
    { id: 'sku', label: 'SKU' },
    {id: 'length', label: "Length"},
    { id: 'baseMaterial', label: 'Material' },
    { id: 'status', render: row => <StatusChip wig={row} />, label: 'Status' },
    {id: 'lastLogDate', render: row => {
      let date = row.history[row.history.length - 1].date
      let cInterval = inv?.cNote ? +inv?.cNote : 45
      let sInterval = inv?.sNote ? +inv?.sNote : 45
      let diff = Math.floor(getDaysDiff(date))

      if (row.status !== 'sold') {

        if (row.status === 'consignment') {
          return <Chip padx="3px" bgcolor={diff > cInterval ? theme.colors.error : theme.colors.successGreen} size="small" label={`${diff} Days`}  />
        } else {
          return <Chip padx="3px" bgcolor={diff > sInterval ? theme.colors.error : theme.colors.successGreen} size="small" label={`${diff} Days`}  />
        }

      } else {
        return "-"
      }

    }, label: 'Duration'},
    { id: 'currentlyAt.name', label: 'Currently At' },
    { id: 'shipmentNum', label: 'Order' },
  ]

  const path = props.match.url;

  const loadBrands = async () => {
    setBrandLoading(true)
    try {
      const { data } = await axios.get('/brands/names')
      console.log(data, "brands")
      setBrandLoading(false)
      setBrands(data.map(brand => brand.name))
    } catch (e) {
      setBrandLoading(false)
    }
  }

  useEffect(() => {
    if (!brands.length)
      loadBrands()
  }, [])

  const { addItem } = useCartContext()

  const actions = [
    {
      icon: <AddShoppingCartRoundedIcon color="primary" />,
      toolTip: 'Add to Cart',
      id: "cart",
      action: (row) => addItem(row)
    }
]
 
 return (
   <Switch>
    {/* <ProtectedRoute path={`${path}/edit/:id`} guard='writeInventory' component={EditInventorys} /> */}
    <ProtectedRoute path={`${path}/edit`} guard='writeInventory' component={AddInventory} />
    <ProtectedRoute path={`${path}/view/:id`} guard='readInventory' component={ViewWig} />
     <Route path="/inventory">
      <Table loading={loading} title="Inventory" tableTitle="Inventory" headers={headers} rows={rows} csv={downloadCsv} count={count} link="inventory" load={load} ord="desc" ordField="wigId"
      batchActions={batchActions}
      write={false} actions={actions}
      tabs={tabs} tabHandler={handleTabs}
        filterItems={<InventoryFilters brands={brands} filter={query?.brand} setQuery={setQuery} applyFilter={applyFilter} />} 
        search={setPage => <TextField name="search" label="Search by ID"
        variant="outlined" size="small" fullWidth onChange={(e) => searchList(e.target.value, setPage)}
        InputProps={{
          endAdornment:
            <InputAdornment position="end">
              <Search color="primary" />
            </InputAdornment>
        }}
        />} />
     </Route>
  </Switch>
 )
}

export default Inventory;