import React, {useState, useEffect, useCallback} from 'react';
import styled from 'styled-components';
import axios from '../../axios';
import ProtectedRoute from '../../utils/ProtectedRoute';
import { Switch, Route } from 'react-router-dom'

import useLoadList from '../../hooks/useLoadList';

import Table from '../../utils/table-server';
import EditPayment from './AddPayment';
// import ViewTransfer from './ViewTransfer';

import FilterFields from '../../utils/common/filters';

import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';
import ArrowRightAltRoundedIcon from '@material-ui/icons/ArrowRightAltRounded';

import CustomChip from '../../utils/CustomChip';

const ChipContainer = styled.div`
  width: max-content;
`;

const ChipFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: .5rem;
`;


const TransferList = (props) => {
  const {rows, loading, load, searchList, count, switcher, setSwitch, tabFilter, setTabFilter, query, handleQuery, reload} = useLoadList('/vendor_payments');

  const headers = [
    { id: 'paymentNum', label: 'ID' },
    { id: "vendor.name", render: (row) => row?.vendor?.name, label: "Vendor" },
    {id: "workOrder.orderId", label: "Work Order"},
    {id: "expense.expenseId", label: "Expense"},
    { id: 'paymentAmount', currency: true, label: 'Amount' },
    { id: 'date', date: true, label: 'Date' }
  ];

  const path = props.match.url;

  const applyFilter = () => {
    reload();
  };

  const handleFilter = (e, field, type) => {
    let value;
    if (type === "check") {
      value = e.target.checked;
    } else if (type === "date") {
      value = e;
    } else {
      value = e.target.value;
    }
    handleQuery(field, value);
  };

  useEffect(() => {
    handleQuery("startDate", new Date());
    handleQuery("endDate", new Date());
  }, []);

  return (
    <Switch>
      <ProtectedRoute path={`${path}/edit/:id`} guard='writeVendorPayment' component={EditPayment} />
      <ProtectedRoute path={`${path}/edit`} guard='writeVendorPayment' component={EditPayment} />
      {/* <ProtectedRoute path={`${path}/view/:id`} guard='readVendorPayment' component={ViewTransfer} /> */}
      <Route path="/vendor_payments">
        <Table
          loading={loading}
          load={load}
          title="Vendor Payments"
          headers={headers}
          rows={rows}
          link="vendor_payments"
          count={count}
          ord="desc"
          ordField="date"
          filterItems={<FilterFields filter={query} handleFilter={handleFilter} applyFilter={applyFilter} enableBalance={false} />}
          search={setPage => <TextField
            name="search"
            label="Search by Name, or Payment ID"
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) => searchList(e.target.value, setPage)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search color="primary" />
                </InputAdornment>
              )
            }}
          />}
        />
      </Route>
    </Switch>
  );
};

export default TransferList;
