import React from 'react';

import styled from 'styled-components';

import { theme } from '../../utils/theme';

import { currencyFormat } from '../../utils/util'

import LockRoundedIcon from '@material-ui/icons/LockRounded';
import LockOpenRoundedIcon from '@material-ui/icons/LockOpenRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import AutorenewRoundedIcon from '@material-ui/icons/AutorenewRounded';

const AvailableChip = styled.span`
  display: flex;
  align-items: center;
  background-color: ${props => props.bg};
  color: ${props => props.color};
  padding: .5rem .7rem;
  border-radius: 5px;
  font-weight: 500;
  width: min-content;

  #icon {
    margin-right: .5rem;
    position: relative;
    top: 2px;
  }
`

const Available = ({ row }) => {
  let color, bgColor, icon;
    if (row.isRefund) {
      color = theme.colors.iconRed;
      bgColor = theme.colors.iconBgRed;
      return <AvailableChip bg={bgColor} color={color}><span id="icon"><AutorenewRoundedIcon htmlColor={color} /></span><span>Refund</span></AvailableChip>
    }
    if (row.isApplied) {
      color = theme.colors.icon1;
      bgColor = theme.colors.iconBg1;
      icon = <LockRoundedIcon htmlColor={color} />
    } else {
      if (row.applied === row.paymentAmount) {
        color = theme.colors.icon2;
        bgColor = theme.colors.iconBg2;
        icon = <CheckRoundedIcon htmlColor={color} />
      } else {
        color = theme.colors.icon3;
        bgColor = theme.colors.iconBg3;
        icon = <LockOpenRoundedIcon htmlColor={color} />
      }
    }
    return <AvailableChip bg={bgColor} color={color}><span id="icon">{icon}</span><span>{currencyFormat(row.applied)}</span></AvailableChip>
}

export {
  Available
};