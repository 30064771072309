import React, { useState } from 'react';

import styled from 'styled-components';

import { currencyFormat, formatGenericDate } from '../../utils/util';

import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';

import { Divider, IconButton, Tooltip } from '@material-ui/core';

import { Link } from 'react-router-dom';

import ConfirmDialog from '../../utils/common/ConfirmDialog';

import { useSBContext } from '../../utils/snackbar';

import { FullDivider } from '../../utils/common';

import axios from '../../axios'

const Tile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 5px;
  background-color: #fff;
  overflow: hidden;
`;

const MainRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Amount = styled.div`
  font-size: 1.6rem;
  font-weight: 600;

  .date {
    font-size: 1.3rem;
    font-weight: 500;
    /* color: ${props => props.theme.colors.lightText}; */
  }
`;

const Actions = styled.div`
  display: flex;
  /* gap: 1rem; */
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MainInfo = styled.div`
  display: flex;
  gap: 3.5rem;
  font-weight: 500;
`;

const Date = styled.div`

`;

const IconLink = styled(Link)`
  color: ${props => props.theme.palette.secondary.main};
`;

const StatStyle = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 1rem; */

  .header {
    font-size: 1.1rem;
    font-weight: 600;
    color: ${props => props.theme.colors.lightText};
  }

  .stat {
    /* font-size: 1.2rem; */
    font-weight: 500;
    color: ${props => props.theme.colors.darkText};
    font-style: ${props => props.$italic ? 'italic' : 'normal'};
  }
`;

const OriginalHeader = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
  margin-top: .6rem;
  margin-bottom: .8rem;
  color: ${props => props.theme.colors.lightText};
  border: 1px solid ${props => props.theme.colors.border};
  /* padding-right: .4rem;
  padding-bottom: .2rem; */
  width: fit-content;
  background-color: ${props => props.theme.colors.headerBg};
  padding: .2rem .8rem;
  border-radius: 3px;
`;

const Stat = ({header, stat, italic}) => {
  return (
    <StatStyle $italic={italic}>
      <div className='header'>{header}</div>
      <div className='stat'>{stat}</div>
    </StatStyle>
  )
}

const deleteMessage = "Are you sure you want to delete this payment? It will be permanently removed from the system.";

const PaymentTile = ({payment = {}, loadData}) => {

  const [removeLoading, setRemoveLoading] = useState(false);

  const {paymentAmount} = payment;

  const {paymentAmount: origAmount, paymentNum, paymentType, paymentInfo, date, _id} = payment?.accountPayment?.[0] || {};

  const {handleClick} = useSBContext();

  const deletePayment = async () => {
    let payments = [payment];
    let accountPaymentId = _id;
    let custId = payment?.customer?.id;

    setRemoveLoading(true);
    try {
      const { data } = await axios.put(`/payments/${accountPaymentId}/remove`, { payments, custId });
      loadData();
      handleClick('success', 'Payment successfully removed');
    } catch (e) {
      handleClick('error', 'Error removing payment');
    } finally {
      setRemoveLoading(false);
    }
    return "removed";
   }

  return (
    <Tile>
      <MainRow>
        <Amount>{currencyFormat(paymentAmount)} - <span className='date'>{formatGenericDate(payment.date)}</span></Amount>
        <Actions>
          <Tooltip title={`View Payment #${paymentNum}`}><IconLink to={`/payments/view/${_id}`} target="_blank"><IconButton size='small'><SearchRoundedIcon color="primary" /></IconButton></IconLink></Tooltip>
          <ConfirmDialog saveFunc={deletePayment} message={deleteMessage} saveLoading={removeLoading} />
        </Actions>
      </MainRow>
      <OriginalHeader>
       Original Payment
      </OriginalHeader>
      {/* <FullDivider y=".5rem" /> */}
      <InfoRow>
        <MainInfo>
          <Stat header="ID" stat={<>#{paymentNum}</>} />
          <Stat header="Amount" stat={currencyFormat(origAmount)} />
          <Stat header="Method" stat={paymentType} />
          <Stat header="Info" stat={paymentInfo} italic />
        </MainInfo>
        <Date>
          <Stat header="Date" stat={formatGenericDate(date)} />
        </Date>
      </InfoRow>
    </Tile>
  )

}

export default PaymentTile;