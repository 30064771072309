import React, { useState } from "react";

import styled from "styled-components";

import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';

import { IconButton, CircularProgress } from "@material-ui/core";

import Dialog from "../dialog"


const ConfirmDialog = ({ toggle, title = "Confirm Delete?", message = 'Deleting will permanently remove this information from the system.', saveLoading, saveFunc, iconSize = 'small', ...props }) => {

  const [open, setOpen] = useState(false);

  const ToggleButton = () => {
    return (
      toggle ? toggle : 
        <IconButton size={iconSize}><DeleteRoundedIcon color="error" /></IconButton>
    )
  }

  return (
    <Dialog
      toggle={<ToggleButton />}
      open={open}
      title={title}
      onSave={saveFunc}
      save={saveLoading ? <CircularProgress color="secondary" /> : "Confirm"}
      {...props}
    >
      {message}
    </Dialog>
  );
};

export default ConfirmDialog;