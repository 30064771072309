import React, { useState } from 'react';
import styled from 'styled-components';

import { IconButton } from "@material-ui/core"

import { currencyFormat, formatGenericDate } from '../../../utils/util';

import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import ArrowRightRoundedIcon from '@material-ui/icons/ArrowRightRounded';

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f9fa;
`;

const Cell = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  padding: 10px;
`

const HeaderCell = styled(Cell)`
  font-weight: 500;
`

const TableBody = styled.div`
  width: 100%;
  /* border-left: 1px solid ${props => props.theme.colors.border}; */
`

const TableRow = styled.div`
  /* display: flex;
  flex-direction: column; */
  width: 100%;
`

const BodyRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.border};
`

const ExpandedRow = styled.div`
  display: ${props => props.$expand ? 'block' : 'none'};
  min-height: 5rem;
  /* background-color: transparent; */
  background-color: #f8f9fa;
  padding: 1rem;
`

const BodyCell = styled(Cell)`
  /* border-right: 1px solid ${props => props.theme.colors.border}; */
`

const ExpandCell = styled(Cell)`
  max-width: 10rem;
  /* border-right: 1px solid ${props => props.theme.colors.border}; */
`

const InnerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${props => props.theme.colors.border}; 
`

const InnerHeaderCell = styled(Cell)`
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  padding-bottom: .2rem;
  padding-top: 1rem;
  color: ${props => props.theme.colors.lightText};
`

const InnerBodyRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const InnerTitle = styled.div`
  padding-bottom: .5rem;
  font-weight: 500;
  font-size: 1rem;
  text-transform: uppercase;
  color: ${props => props.theme.colors.lightText};
`

const FulfilledQty = styled.span`
  color: ${props => props.theme.colors.primaryMain};
  font-weight: 600;
`


const ViewLineItems = ({ lineItems = [], fulfilledItems = [] }) => {
  const [expand, setExpand] = useState({})

  const toggleExpand = (id) => {
    setExpand(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }))
  }

    return (
        <Container>
          <TableHeader>
            <ExpandCell></ExpandCell>
            <HeaderCell>Color</HeaderCell>
            <HeaderCell>Length</HeaderCell>
            <HeaderCell>Cap Size</HeaderCell>
            <HeaderCell>Material</HeaderCell>
            <HeaderCell>Hairstyle</HeaderCell>
            <HeaderCell>Line</HeaderCell>
            <HeaderCell>Qty</HeaderCell>
            <HeaderCell>Cost</HeaderCell>
            <HeaderCell>Total</HeaderCell>
          </TableHeader>
          <TableBody>
            {lineItems.map((item, index) => (
              <TableRow>
                <BodyRow key={item.id}>
                   <ExpandCell>
                    <IconButton size='small' onClick={() => toggleExpand(item.id)} 
                    // disabled={!item.fulfilledQty}
                    >
                      {expand[item.id] ? <ArrowDropDownRoundedIcon /> : <ArrowRightRoundedIcon />} 
                    </IconButton>
                   </ExpandCell>
                   <BodyCell>{item.color}</BodyCell>
                   <BodyCell>{item.lengthNum}</BodyCell>
                   <BodyCell>{item.capSize}</BodyCell>
                   <BodyCell>{item.baseMaterial}</BodyCell>
                   <BodyCell>{item.hairstyle || '-'}</BodyCell>
                   <BodyCell>{item.line || '-'}</BodyCell>
                   <BodyCell><FulfilledQty>{item.fulfilledQty}</FulfilledQty> / {item.qty}</BodyCell>
                   <BodyCell>{currencyFormat(item.cost)}</BodyCell>
                   <BodyCell>{currencyFormat(item.price)}</BodyCell>
                </BodyRow>
                <ExpandedRow $expand={expand[item.id]}>
                  <InnerTitle>Fulfillments</InnerTitle>
                  {item.fulfilledQty ? <InnerHeader>
                    <InnerHeaderCell>Qty</InnerHeaderCell>
                    <InnerHeaderCell>Cost</InnerHeaderCell>
                    <InnerHeaderCell>Total</InnerHeaderCell>
                    <InnerHeaderCell>Date</InnerHeaderCell>
                    <InnerHeaderCell>Fulfilled</InnerHeaderCell>
                  </InnerHeader> : null}
                  {fulfilledItems.filter(el => el.lineItemId === item.id).map((el, index) => (
                    <InnerBodyRow key={index}>
                      <Cell>{el.qty}</Cell>
                      <Cell>{currencyFormat(el.cost)}</Cell>
                      <Cell>{currencyFormat(el.price)}</Cell>
                      <Cell>{formatGenericDate(el.date)}</Cell>
                      <Cell>{el.fulfilledBy?.name}</Cell>
                    </InnerBodyRow>
                   ))
                  }
                </ExpandedRow>
              </TableRow>
            ))}
          </TableBody>
        </Container>
    );
};

export default ViewLineItems;




