import {createTheme} from '@material-ui/core'
const size = {
  xs: '0',
  smp: '450px',
  sm: '600px',
  md: '960px',
  lg: '1280px',
  xl: '1920px',
}

export const theme = createTheme({
  colors: {
    border: "#E9E9E9",
    headerBg: '#f5f5f5',
    background: '#d9d9d9',
    primary: '#FFF',
    primaryMain: "#4065a5",
    primaryLight: "#91aad4",
    primaryVeryLight: "#c8d4ea",
    primary95: "#edf1f8",
    primary35: "#324f81",
    secondary25: "#244e5c",
    secondaryMain: "#408ca5",
    secondary60: "#6cafc6",
    secondary75: "#a3cddb",
    secondary90: "#daebf1",
    secondary95: "#edf5f8",
    fadedWhite: '#ccc',
    dollarGreen: '#569027',
    body: '#FEC340',
    dark: 'rgb(50, 48, 49)',
    lightText: '#3a354199',
    lightTextGray: '#6b6b6b99',
    darkText: '#3a3541de',
    border: 'rgb(224, 224, 224)',
    success: "#a6b5d8",
    successGreen: '#a6d8a6',
    error: "#d8a6a6",
    errorText: "#7C3636",
    errorBg: "#F7EDED",
    caution: "#c9a683",
    iconBg1L: '#eef1f7',
    iconBg2L: '#eef7f5',
    iconBg3L: '#f7f3ee',
    iconBg4L: '#f0f7ed',
    iconBg1: '#a6b5d8',
    iconBg2: '#a6d8cc',
    iconBg3: '#d8bfa6',
    iconBg4: '#b3d8a6',
    icon1: '#273659',
    icon2: '#27594d',
    icon3: '#594027',
    icon4: '#345927',
    icon1L: "#4e6cb1",
    icon2L: "#4eb19a",
    icon3L: "#b1804e",
    icon4L: "#68b14e",
    iconBgRed: "#d8a6a6",
    iconRed: "#592727",
    iconRedLight: "#b14e4e",
    iconCaution: "#af7604",
    iconBgCaution: "#fde5b4",
  },
  scShadows: {
    light: '0 5px 8px 0 rgba(0, 0, 0, 0.1)',
    medium: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
    hover: '0 8px 10px 0 rgba(0, 0, 0, 0.1)',
  },
  wigColors: {
    '1': '#0E0E10',
    '2': '#313639',
    '3': '#31221b',
    '4': '#423024',
    '6': '#5d2f27',
    '8': '#96574E',
    '10': '#996515',
    '12': '#b47618',
    '14': '#b47618',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  breaks: {
    up: bp => `(min-width: ${size[bp]})`,
    down: bp => `(max-width: ${size[bp]})`,
    bewteen: (bp1, bp2) => `(min-width: ${size[bp1]}) and (max-width: ${size[bp2]})`,
    only: bp => `(width: ${size[bp]})`
  },
  palette: {
    primary: {
      main: '#4065a5', //'#1976d2' #4771b8 #1480c7
      contrastText: '#fff'
    },
    secondary: {
      main: '#408ca5', // #152136
      contrastText: '#eee'
    },
    error: {
      main: '#c95555'
    },
  },
  mixins: {
    toolbar: {
      minHeight: '7rem'
    }
  },
  typography: {
    fontFamily: "'Montserrat', 'Roboto', 'Helvetica', 'Arial', sans-serif",
    htmlFontSize: 10,
    h6: {
      fontSize: '1.6rem'
    },
    h5: {
      fontSize: '1.8rem'
    },
    body1: {
      fontSize: '1.4rem',
    }
  },
  maxWidth: '1280px',
  maxWidthMd: '960px'
})
