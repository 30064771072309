import React from 'react'
import styled from 'styled-components';

import StoreRoundedIcon from '@material-ui/icons/StoreRounded';
import RedeemRoundedIcon from '@material-ui/icons/RedeemRounded';
import ReceiptRoundedIcon from '@material-ui/icons/ReceiptRounded';
import LockRoundedIcon from '@material-ui/icons/LockRounded';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';

import { theme } from '../../../utils/theme';

const StyledChip = styled.div`
  border: 1px solid ${props => props.$border || props.theme.colors.lightText};
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .6rem;
  width: fit-content;
  padding: .2rem .5rem;
  font-weight: 500;
  color: ${props => props.$color || 'inherit'};
  background-color: ${props => props.$bg};

  svg {
    position: relative;
    top: .2rem;
  }

`;

const StatusChip = ({ wig }) => {
  let status = wig.availability || wig.status;
  let Icon, color, bg, border;
  switch (status) {
    case 'sold':
      Icon = ReceiptRoundedIcon
      color = theme.colors.icon4L;
      bg = theme.colors.iconBg4L;
      border = theme.colors.iconBg4;
      break;
    case 'consignment':
      Icon = RedeemRoundedIcon
      color = theme.colors.icon3L;
      bg = theme.colors.iconBg3L;
      border = theme.colors.iconBg3;
      break;
    case 'committed':
      Icon = LockRoundedIcon
      color = theme.colors.icon2L;
      bg = theme.colors.iconBg2L;
      border = theme.colors.iconBg2;
      break;
    case 'repair':
      Icon = BuildOutlinedIcon;
      color = theme.colors.icon2L;
      bg = theme.colors.iconBg2L;
      border = theme.colors.iconBg2;
      break;
    default:
      Icon = StoreRoundedIcon
      color = theme.colors.icon1L;
      bg = theme.colors.iconBg1L;
      border = theme.colors.iconBg1;
  }


  return (<StyledChip $color={color} $border={border} $bg={bg}><span><Icon htmlColor={color} /></span> <span>{status}</span></StyledChip>)
};

export default StatusChip;



