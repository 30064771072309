import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import axios from '../../axios';


import { theme } from '../../utils/theme';

import { currencyFormat, strToBool, getPercent } from '../../utils/util';

import OutlinedSelect from '../../utils/OutlinedSelect';

import Thumbtack from '../../images/Thumbtack';
import LinkRoundedIcon from '@material-ui/icons/LinkRounded';
import PaymentIcon from '@material-ui/icons/Payment';
import PublishRoundedIcon from '@material-ui/icons/PublishRounded';

import { useSBContext } from '../../utils/snackbar';

import { IconButton, MenuItem, Button, InputLabel, Input } from '@material-ui/core';
import { set, truncate } from 'lodash';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import {DateTime} from 'luxon';

const Container = styled.div`
    width: 95%;
    margin: 0 auto;
`

const TopBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 3rem 0;
`
const Title = styled.h2`
   /* width: 95%; */
    margin: 0;
    font-weight: 500;
    font-size: 2.4rem;
`

const Main = styled.div`
    width: 100%;
    /* background-color: lightpink; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 50vh;
    min-height: 43rem;
`

const FileButton = styled(InputLabel)`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  min-width: 50%;
  border-radius: 30px;
  padding: 15px 20px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  /* margin-top: 2rem; */
  background-color: ${props => props.theme.colors.primaryMain};
  transition: all .3s;
  /* float: right; */
  cursor: pointer;

  div {
    display: flex;
    align-items: center;
    width: max-content;

    span {
        margin-left: 1rem;
    }
  }

  &:hover {
    background-color: ${props => props.theme.colors.primary35};
  }
`

const TransactionList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    height: 100%;
`

const BankSelect = styled.div`
    min-width: 20rem;
`

const TransactionBar = styled.div`
    width: 40rem;
    height: 100%;
    border: 1px solid ${props => props.theme.colors.border};
    box-shadow: inset 0 0 8px #00000011;
    padding: 1rem;
    border-radius: 5px;
    overflow-y: auto;

     /* width */
   ::-webkit-scrollbar {
    width: 2px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #eee;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #bbb;
  }
`

const CashBar = styled(TransactionBar)`
    /* background-color: lightblue; */
`

const BankBar = styled(TransactionBar)`
    /* background-color: lightgreen; */
    width: 72rem;
`

const TransactionStyles = styled.div`
    position: relative;
    display: block; 
    width: ${props => props.source === "cash" ? "100%" : "36rem"};
    height: 7.5rem;
    border-radius: .5rem;
    border: 1px solid;
    /* margin-bottom: 1rem; */
    padding: .4rem .6rem;
    cursor: pointer;

    ${props => props.transactionType === 'credit' ? props.reconciled ? css`
        background-color: #67FB8E;
        border-color: #66E989;
    ` : css`
        background-color: #CEF4D8;
        border-color: #1DD24D;
    ` : ""}

    ${props => props.transactionType === 'debit' ? props.reconciled ? css`
        background-color: #F18383;
        border-color: #EB4D4D;
    ` : css`
        background-color: #F4CECE;
        border-color: #D21D1D;
    ` : ""}

    ${props => props.highlight && props.source === "bank" ?  css`
        border-width: 2px;
        ` : ""
    }

    .paymentInfo {
        line-height: 1.2;
    }

    .type, .paymentMethod, .date {
        font-size: 1.2rem;
        font-weight: 600;
        text-transform: uppercase;
        color: ${props => props.theme.colors.lightText};
    }
    .info {
        font-size: 1.2rem;
    }
    .description {
        font-weight: 500;

    }
    .reconciled {
        font-size: 1.2rem;
        font-weight: 600;
    }
    .amount {
        font-weight: 600;
        font-size: 1.6rem;
        display: block;
        width: 15rem;
        text-align: right;
    }
`

const AttachedTransaction = styled(TransactionStyles)`
    /* position: absolute; */
    width: 28rem;
    height: 7.5rem;
    border: 1px solid;
    background-color: ${props => props.transactionType === 'credit' ? "#CEF4D8" : "#F4CECE"};
    border-color: ${props => props.transactionType === 'credit' ? "#1DD24D" : "#D21D1D"};
    border-radius: 5px;
    padding: .4rem .6rem;
    margin-bottom: .5rem;

    .row { 
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .row.last {
        /* flex-grow: 1; */
    }

    .column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    .info {
        font-size: 1rem;
    }
    .description {
        font-weight: 500;
        font-size: 1.2rem;
    }
    .amount {
        font-weight: 600;
        font-size: 1.2rem;
        
    }
`

const TransactionDescription = styled.span`
    font-weight: 500;
    font-size: ${props => props.textLen > 50 ? "1.2rem" : "1.4rem"};
`

const MinTransactionContainer = styled.div`
    display: flex;
    flex-direction: column;

`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    flex-grow: 1;
    align-items: ${props => props.first ? "flex-start" : props.last ? "flex-end" : "center"};
`

const PaymentType = styled.div`
    position: absolute;
    width: 100%;
    text-align: center;
    top: 0;
`

const Row = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
`

const CashTransactionBar = styled.li`
    margin-bottom: 1rem;
`

const LinkBox = styled.div`
    padding: .1rem;
    background-color: ${props => props.theme.colors.secondary90};
    border-radius: 5px;
    border: 1px solid ${props => props.theme.colors.border};
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 .8rem;
    cursor: pointer;
`

const BankTransactionBar = styled.li`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    min-height: 7.5rem;
    margin-bottom: 1rem;
    align-items: flex-start;
`

const Footer = styled.div`
    display: flex;
    /* justify-content: flex-end; */
`

const Summary = styled.div`
  border: 2px dashed ${props => props.theme.colors.border};
  border-radius: 5px;
  background-color: ${props => props.theme.colors.headerBg};
  min-height: 8rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  margin-top: 1.5rem;
  width: 40rem;
`
const SummaryRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    font-weight: 500;
    margin: .2rem 0;
`
const SummaryHeader = styled.span`
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1.2rem;
    display: block;
    margin-right: 1.5rem;
    min-width: 25rem;
`

const SummaryInfo = styled.span`
    /* font-size: 1.2rem; */
    display: block;
`

const StartScreenContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const StartScreenStyles = styled.div`
    width: 80%;
    height: 60%;
    border: 2px dotted ${props => props.theme.colors.border};
    border-radius: 8px;
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const DateContainer = styled.div`
    width: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;

`

const DatePicker = styled(KeyboardDatePicker)`
    display: block;
    margin-top: 1.5rem;
`

const StartHeader = styled.span`
    display: block;
    margin-bottom: .6rem;
    font-size: 1.2rem;
    font-weight: 500;
    color: ${props => props.theme.colors.lightText};
`

const StartScreen = ({ children }) => {
    return (
        <StartScreenContainer>
            <StartScreenStyles>
                {children}
            </StartScreenStyles>
        </StartScreenContainer>
    )
}


const Transaction = ({ source, highlight, transactionItem, ...props }) => {
    const { id, cat, amount, date, paymentMethod, description, info, type, reconciled, cashReconciled, reconciledAmount = 0 } = transactionItem
    return (
        <TransactionStyles highlight={highlight} source={source} {...props} transactionType={type} reconciled={reconciledAmount === amount}>
            <Row>
                <Column first>
                    <span className='type'>{cat ? cat : type}</span>
                    <div className='paymentInfo'>
                        <TransactionDescription textLen={description.length}>{truncate(description, {length: 50})}</TransactionDescription><br />
                        {info && <span className='info'>INFO: {info}</span>}
                    </div>
                </Column>

                
                    <PaymentType className='paymentMethod'>{paymentMethod}</PaymentType>

                <Column last>
                    <span className='date'>{date}</span>
                    <span className='amount'><span className='reconciled'>{source === "bank" ? `${getPercent(reconciledAmount, amount, 'str')} / ` : ""}</span>{currencyFormat(amount)}</span>
                </Column>
            </Row>
        </TransactionStyles>
    )
}

const MinTransaction = ({ source, transactionItem, ...props }) => {
    const { id, cat, amount, date, paymentMethod, description, info, type, reconciled, cashReconciled } = transactionItem

   return <AttachedTransaction source={source} {...props} transactionType={type}>
    <div className='column'>
        <div className='row'>
            <span className='type'>{cat ? cat : type}</span>
            <span className='amount'>{currencyFormat(amount)}</span>
        </div>
        <div className='row last'>
            <div className='paymentInfo'>
                <span className='description'>{description}</span><br />
                {info && <span className='info'>INFO: {info}</span>}
            </div>
        </div>
    </ div>
        </AttachedTransaction>
}


const Reconciliation = () => {

    const beginDate = DateTime.local().minus({months: 1}).startOf("month").toFormat("MM/dd/yyyy")
    const lastDate = DateTime.local().minus({months: 1}).endOf("month").toFormat("MM/dd/yyyy")

    const [bank, setBank] = useState("default")
    const [draggedItem, setDraggedItem] = useState(null)
    const [dropTarget, setDropTarget] = useState(null)
    const [period, setPeriod] = useState("default")
    const [startDate, setStartDate] = useState(beginDate)
    const [endDate, setEndDate] = useState(lastDate)
    const [statement, setStatement] = useState(null)
    const [cashLoading, setCashLoading] = useState(false)
    const [bankLoading, setBankLoading] = useState(false)

    const { handleClick } = useSBContext();

    const loadCashTransactions = async () => {
        setCashLoading(true)
        try {
            let { data } = await axios.get(`banking/transactions?startDate=${startDate}&endDate=${endDate}&bank=${bank}`)
            setCashTransactions(data)
        } catch (e) {
            console.log(e?.message)
            handleClick('error', 'Error loading cash transactions')
        } finally {
            setCashLoading(false)
        }
    }

    const importStatement = async (e) => {
            
        let file = e.target.files[0];
        setStatement(file);

        let sheet = file;

        const formData = new FormData(); 
        
        formData.append( 
        "bank-statement", 
        sheet, 
        sheet.name
        ); 

        try {
        setBankLoading(true)
        const url = `/banking/import`;
        const {data} = await axios.post(url, formData);
        setBankTransactions(data)
        document.getElementById("import-button").value = ""
        } catch (e) {
        document.getElementById("import-button").value = ""
        let errorMessage = "Error Importing Statement";
        if (e.response?.data) {
            errorMessage = e.response.data.message
        }
        handleClick('error', `${errorMessage}`)
        } finally {
        setBankLoading(false)
        }
    }

    useEffect(() => {
        switch (period) {
            case "default":
                setStartDate(beginDate)
                setEndDate(lastDate)
                break;
            // case "quarter":
            //     setStartDate(DateTime.local().minus({months: 4}).startOf("month").toFormat("MM/dd/yyyy"))
            //     setEndDate(lastDate)
            //     break;
            // case "year":
            //     setStartDate(DateTime.local().minus({years: 1}).startOf("year").toFormat("MM/dd/yyyy"))
            //     setEndDate(DateTime.local().minus({years: 1}).endOf("year").toFormat("MM/dd/yyyy"))
            //     break;
            case "custom":
                break;
            default:
                break;
        }
    }, [period])

    const [cashTransactions, setCashTransactions] = useState([
        {
            id: 1,
            type: 'credit',
            amount: 3000,
            date: '2020-01-01',
            cat: "payment",
            paymentMethod: 'credit card',
            description: '#1081 / Chani Rosinger',
            info: "87659",
            reconciled: false
        },
        {
            id: 2,
            type: 'debit',
            amount: 1000,
            date: '2020-01-01',
            cat: "expense",
            paymentMethod: 'credit card',
            description: '#1081 / Chani Rosinger',
            info: "87659",
            reconciled: false
        },
        {
            id: 3,
            type: 'debit',
            amount: 1000,
            date: '2020-01-01',
            cat: "expense",
            paymentMethod: 'credit card',
            description: '#1081 / Chani Rosinger',
            info: "87659",
            reconciled: false
        },
        {
            id: 4,
            type: 'debit',
            amount: 1000,
            date: '2020-01-01',
            cat: "refund",
            paymentMethod: 'credit card',
            description: '#1081 / Chani Rosinger',
            info: "87659",
            reconciled: false
        },
        {
            id: 5,
            type: 'credit',
            amount: 1000,
            date: '2020-01-01',
            cat: "payment",
            paymentMethod: 'credit card',
            description: '#1081 / Chani Rosinger',
            info: "87659",
            reconciled: true
        },
        {
            id: 6,
            type: 'credit',
            amount: 1000,
            date: '2020-01-01',
            cat: "payment",
            paymentMethod: 'credit card',
            description: '#1081 / Chani Rosinger',
            info: "87659",
            reconciled: false
        },
        {
            id: 7,
            type: 'credit',
            amount: 1000,
            date: '2020-01-01',
            cat: "payment",
            paymentMethod: 'credit card',
            description: '#1081 / Chani Rosinger',
            info: "87659",
            reconciled: false
        },
        {
            id: 8,
            type: 'credit',
            amount: 1000,
            date: '2020-01-01',
            cat: "payment",
            paymentMethod: 'credit card',
            description: '#1081 / Chani Rosinger',
            info: "87659",
            reconciled: false
        },
        {
            id: 9,
            type: 'credit',
            amount: 1000,
            date: '2020-01-01',
            cat: "payment",
            paymentMethod: 'credit card',
            description: '#1081 / Chani Rosinger',
            info: "87659",
            reconciled: false
        }


    ]);
    const [bankTransactions, setBankTransactions] = useState([
        {
            id: 1,
            type: 'credit',
            amount: 3000,
            date: '2020-01-01',
            paymentMethod: 'credit card',
            description: 'Check #375',
            reconciled: false,
            reconciledItems: [],
            reconciledAmount: 0
        },
        {
            id: 2,
            type: 'debit',
            amount: 1000,
            date: '2020-01-01',
            paymentMethod: 'credit card',
            description: 'Check #375',
            reconciled: false,
            reconciledItems: [],
            reconciledAmount: 0
        },
        {
            id: 3,
            type: 'debit',
            amount: 1000,
            date: '2020-01-01',
            paymentMethod: 'credit card',
            description: 'Check #375',
            reconciled: false,
            reconciledItems: [],
            reconciledAmount: 0
        },
        {
            id: 4,
            type: 'debit',
            amount: 12000,
            date: '2020-01-01',
            paymentMethod: 'credit card',
            description: 'Ach Atm-Transaction Deposit - Shopping/Hatzlacha Supermarket',
            reconciled: false,
            reconciledItems: [],
            reconciledAmount: 0
        },
        {
            id: 5,
            type: 'credit',
            amount: 1000,
            date: '2020-01-01',
            paymentMethod: 'credit card',
            description: 'Check #375',
            reconciled: false,
            reconciledItems: [],
            reconciledAmount: 0
        },
        {
            id: 6,
            type: 'credit',
            amount: 1000,
            date: '2020-01-01',
            paymentMethod: 'credit card',
            description: 'Check #375',
            reconciled: false,
            reconciledItems: [],
            reconciledAmount: 0
        }
        
    ]);

    function dragstartHandler(ev, transaction) {
        // Add the target element's id to the data transfer object
        setDraggedItem(transaction)
        ev.dataTransfer.effectAllowed = "move";
      }

      function dragoverHandler(ev, transactionId) {
        ev.preventDefault();
        setDropTarget(transactionId)
        ev.dataTransfer.dropEffect = "move";
      }

      function dropHandler(ev, curTransaction) {
        ev.preventDefault();

        console.log("hello")
        if (draggedItem.type !== curTransaction.type || curTransaction.reconciled || curTransaction.reconciledAmount + draggedItem.amount > curTransaction.amount) return

        setCashTransactions(x => {
            let newTransactions = [...x].map(item => {
                let newItem = {...item}
                if (newItem.id === draggedItem.id) {
                    newItem.reconciled = true
                    newItem.bankId = curTransaction.id
                }
                return newItem
            })
            return newTransactions
        })
        setBankTransactions(x => {
            let newTransactions = [...x].map(item => {
                let newItem = {...item}
                if (newItem.id === curTransaction.id) {
                    newItem.reconciledItems = newItem.reconciledItems.concat(draggedItem)
                    newItem.reconciledAmount += draggedItem.amount
                }
                return newItem
            })
            return newTransactions
        })
        setDraggedItem(null)
        setDropTarget(null)
      }

      const releaseTransaction = (reconciledItems, bankId) => {
        setBankTransactions(x => {
            let newTransactions = [...x]
            let transaction = newTransactions.find(y => y.id === bankId)
            transaction.reconciledAmount = 0
            transaction.reconciledItems = []
            return newTransactions
        })
        setCashTransactions(x => {
            let ids = reconciledItems.map(el => el.id)
            let newTransactions = [...x].map(el => {
                let item = {...el}
                if (ids.includes(item.id)) {
                    item.reconciled = false
                    item.bankId = ""
                }
                return item
            })
            return newTransactions
        })
      }


    return (
        <Container>
            <TopBar>
            <Title>Reconcile Bank Account</Title>
                <BankSelect>
                    <OutlinedSelect size="small" color={theme.colors.secondary75} 
                        bgColor={theme.colors.secondary75}
                        textColor={theme.colors.secondary25}
                        button
                        val={bank} 
                        // onChange={(e) => setBrand(e.target.value)}
                    >
                    <MenuItem value="default">Default Bank</MenuItem>
                </OutlinedSelect>
            </BankSelect>
          </TopBar>
            <Main>
                <CashBar>
                    <StartScreen>
                    <StartHeader>Select A Period To Start</StartHeader>
                        <DateContainer>
                            <OutlinedSelect size="small" color={theme.colors.secondary75} 
                                bgColor={theme.colors.secondary75}
                                textColor={theme.colors.secondary25}
                                // button
                                val={period} 
                                onChange={(e) => setPeriod(e.target.value)}
                            >
                                <MenuItem value="default">Last Month</MenuItem>
                                <MenuItem value="custom">Custom Range</MenuItem>
                            </OutlinedSelect>
                           {period === "custom" ? <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                variant="inline" openTo="year" disableToolbar views={["year", "month", "date"]} inputVariant="outlined"
                                value={startDate}
                                size="small" 
                                format="MM/dd/yyyy" 
                                // onChange={handleStartDateChange}  
                                // label="Start Date"
                                 name="date" />
                            </MuiPickersUtilsProvider> : null }
                            {period === "custom" ? <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                variant="inline" openTo="year" disableToolbar views={["year", "month", "date"]} inputVariant="outlined"
                                value={endDate}
                                size="small" 
                                format="MM/dd/yyyy" 
                                // onChange={handleStartDateChange}  
                                // label="End Date"
                                 name="date" />
                                </MuiPickersUtilsProvider> : null }
                        </DateContainer>
                        <Button size="small" startIcon={<PaymentIcon />}  color="secondary" onClick={loadCashTransactions}>Load Transactions</Button>
                    </StartScreen>
                    {/* <TransactionList>
                        {cashTransactions.map(transaction => (
                           transaction.reconciled ? null : <CashTransactionBar
                           key={`cash-${transaction.id}`}
                           ><Transaction 
                            draggable
                            onDragStart={(e) => dragstartHandler(e, transaction)}
                            transactionItem={transaction}
                            source="cash" 
                            /></CashTransactionBar>
                        ))}

                    </TransactionList> */}
                </CashBar>
                <form encType="multipart/form-data">

                <BankBar>
                    <StartScreen>
                      <StartHeader>Import Bank Statement To Start</StartHeader>
                    <FileButton htmlFor="import-button">
                        <Input
                            id="import-button"
                            inputProps={{
                                accept:
                                ".csv,.xlsx"
                            }}
                            onChange={importStatement}
                            style={{display: "none"}}
                            type="file"
                        />
                            <div>
                                <PublishRoundedIcon /> 
                                <span>Import Statement</span>
                            </div>
                        </FileButton>
                    </StartScreen>

                    {/* <TransactionList>
                    {bankTransactions.map(transaction => (
                        <BankTransactionBar
                            key={`bank-${transaction.id}`}
                        >
                        {transaction.reconciledItems.length ? <MinTransactionContainer>
                            {console.log(transaction.reconciledItems, "reconciledItems")}
                            {transaction.reconciledItems.map(el => 
                                <MinTransaction key={el.id} source="cash" transactionItem={el}></MinTransaction>
                            )}
                            </MinTransactionContainer> : null }
                        {transaction.reconciledItems.length ? <LinkBox onClick={() => releaseTransaction(transaction?.reconciledItems, transaction.id)}><LinkRoundedIcon color="secondary" /></LinkBox> : null }
                            <Transaction transactionItem={transaction}
                                onDrop={(e) => dropHandler(e, transaction)}
                                onDragOver={(e) => dragoverHandler(e, transaction.id)}
                                onDragLeave={() => setDropTarget(null)}
                                highlight={dropTarget === transaction.id && draggedItem.type === transaction.type}
                                source="bank"
                            />
                        </BankTransactionBar>
                        ))}
                    </TransactionList> */}
                </BankBar>
                </form>

            </Main>


            <Footer>
                <Summary>
                    {/* <SummaryRow>
                        <SummaryHeader>Reconcile Period:</SummaryHeader> <SummaryInfo>01/01/2024 - 01/31/2024</SummaryInfo>
                    </SummaryRow> */}
                    <SummaryRow>
                        <SummaryHeader>Beginning Balance:</SummaryHeader> <SummaryInfo>$0.00</SummaryInfo>
                    </SummaryRow>
                    <SummaryRow>
                        <SummaryHeader>Ending Balance:</SummaryHeader>
                        <SummaryInfo>$0.00</SummaryInfo>
                    </SummaryRow>
                    <SummaryRow>
                        <SummaryHeader>Total Entered Transactions:</SummaryHeader><SummaryInfo>{currencyFormat(cashTransactions.reduce((acc, cur) => acc + cur.amount, 0))}</SummaryInfo>
                    </SummaryRow>
                    <SummaryRow>
                        <SummaryHeader>Total Bank Transactions:</SummaryHeader><SummaryInfo>{currencyFormat(bankTransactions.reduce((acc, cur) => acc + cur.amount, 0))}</SummaryInfo>
                    </SummaryRow>
                    <SummaryRow>
                        <SummaryHeader>Reconciled Transactions:</SummaryHeader>
                        <SummaryInfo>{bankTransactions.filter(el => el.reconciledAmount === el.amount).length} / {bankTransactions.length}
                        </SummaryInfo>
                    </SummaryRow>
                    <SummaryRow>
                        <SummaryHeader>Unreconciled Amount:</SummaryHeader>     <SummaryInfo>{currencyFormat(bankTransactions.reduce((acc, cur) => acc + (cur.amount - cur.reconciledAmount), 0))}
                        </SummaryInfo>
                    </SummaryRow>
                </Summary>
            </Footer>
        </Container>
    );
};

export default Reconciliation;