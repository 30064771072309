import React from "react";

import useLoadList from "../../hooks/useLoadList";

import Table from "../../utils/table-server";

import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';

const headers = [
  { id: 'orderId', label: 'ID' },
  { id: 'vendor.name', label: 'Vendor' },
  { id: 'lineItemQty', label: 'Products' },
  { id: 'totalQty', label: 'Qty' },
  { id: 'fulfilledQty', render: (row) => (<span>{row?.fulfilledQty}/{row?.totalQty}</span>), label: 'Fulfilled' },
  { id: 'total', currency: true, label: 'Total' },
  { id: 'balance', currency: true, label: 'Balance' },
  { id: 'date', date: true, label: 'Date' }
];

const WorkOrderList = (props) => {

  const {rows, loading, load, searchList, count, switcher, setSwitch, tabFilter, setTabFilter, query, handleQuery, reload} = useLoadList('/work_orders');

  return (
    <Table loading={loading} load={load} title="Work Orders" headers={headers} rows={rows} link="work_orders" count={count} ord="desc" ordField="date"
    search={setPage => <TextField name="search" label="Search by ID"
    variant="outlined" size="small" fullWidth onChange={(e) => searchList(e.target.value, setPage)}
    InputProps={{
      endAdornment:
        <InputAdornment position="end">
          <Search color="primary" />
        </InputAdornment>
    }}
    />} 
    />
  )

}

export default WorkOrderList;