import React, {useState, useCallback, useEffect} from 'react'
import styled from 'styled-components'
import LinearProgress from '@material-ui/core/LinearProgress';
import axios from '../../axios';

import {useSBContext} from '../../utils/snackbar';

import { useAuthContext } from '../../auth';

import { useCartContext } from '../cart/cartContext';

import OutlinedSelect from '../../utils/OutlinedSelect'

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch'
import SimpleAutoComplete from '../../utils/simpleAutoComplete';

import SimpleTable from '../../utils/simpleTable';
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip';

import { Flex, FullDivider } from '../../utils/common';

import { Ribbon, ResultContainer } from './Tracking';

import { theme } from '../../utils/theme'

import IconButton from '@material-ui/core/IconButton';
import Print from '@material-ui/icons/Print';
import EmailIcon from '@material-ui/icons/Email';
import AddShoppingCartRoundedIcon from '@material-ui/icons/AddShoppingCartRounded';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import CardTravelIcon from '@material-ui/icons/CardTravel';


import FormDialog from '../../utils/dialog';
import { TextField } from '@material-ui/core';

import displayPdf from '../../utils/displayPdf';

import {debounce, get, sumBy} from 'lodash';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";

const SelectContainer = styled.div`
  width: 25rem;
`
const InfoItem = styled.span`
  display: block;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  cursor: pointer;

  &.color {
    color: ${props => props.theme.colors.darkText};
    font-weight: 600;
  }

  &.color:hover {
    transform: scale(1.01);
  }

  &.header {
    text-transform: uppercase;
    margin-bottom: 1.5rem;
  }
`
const InfoLabel = styled.span`
  font-weight: 500;
  padding-right: 1rem;

`

const CustomSwitch = styled(Switch)`
  margin-bottom: 1rem;
`

const TooltipContainer = styled.div`
  display: flex;
`
 
export default ({loading, panel, custOptions, setCustOptions, results, custHeaders, getCustResults, brands}) => {

  const { user } = useAuthContext()

  const inv = user.system?.settings?.inventory

  const { addItem } = useCartContext()

  const [email, setEmail] = useState('');

  const [eLoading, setELoading] = useState(false)

  const [filtered, setFiltered] = useState()

  const [pLoading, setPLoading] = useState(false)

  const [err, setErr] = useState(false);

  const handleFilter = style => {
    if (style === 'all')
      return setFiltered(results.wigs)

    let filter = results.wigs.filter(el => el.style === style)
    setFiltered(filter)
  }

  const handleEmailChange = (e) => {
    setErr(false)
    setEmail(e.target.value)
  }

  const handleChangeCust = useCallback(debounce(async val => {
    const {data} = await axios.get(`/customers?term=${encodeURIComponent(val)}`)
    setCustOptions(x => {return {...x, cust: data}});
  }, 250), [])
  
  // console.log(cust);
  
  const handleCustSelect = (val) => {
    setCustOptions(x => {return {...x, custValue: val}});
  }
  
  const handleSelect = (event, field) => {
    setCustOptions(x => {return {...x, [field]: event.target.value}});
  };

  const handleSwitch = (e) => {
    setCustOptions(x => {return {...x, [e.target.name]: e.target.checked}});
  }
  
  const handleStartDateChange = (date) => {
    setCustOptions(x => {return {...x, selectedStartDate: date}});
  };
  
  const handleEndDateChange = (date) => {
    setCustOptions(x => {return {...x, selectedEndDate: date}});
  };
  
  

  const id = results && results.wigs.length ? results.wigs[0].currentlyAt.id : "";

  const { handleClick } = useSBContext()

  const saveFunc = async () => {
    if (!email){ setErr(true); return}
    if (!results.summary?.length) return;

    const { filterDuration, includeStatus } = custOptions

    let queryString = `&includeStatus=${includeStatus}`

    if (filterDuration)
      queryString += `&duration=${inv?.cNote}`

    setELoading(true)
    try {
      const {data} = await axios.get(`/track/client/${id}?brand=${encodeURIComponent(results.brand)}&email=${email}${queryString}`);
      setELoading(false)
      handleClick('success', 'Email successfully sent')
      return 'sent'
    } catch (e) {
      setELoading(false)
      handleClick('error', 'Error sending Email')
      return 'sent'
    }
    
  }

  const loadEmail = async () => {
    const {data} = await axios.get(`/customers/${id}`)
    setEmail(data.email)
  }

  const printInvoice = async () => {
    if (!results.summary?.length) return;

    const { filterDuration, includeStatus, days } = custOptions

    let queryString = `&includeStatus=${includeStatus}`

    if (filterDuration)
      queryString += `&duration=${days}`

    setPLoading(true)
    const {data} = await axios.get(`/track/client/${id}?brand=${encodeURIComponent(results.brand)}&pdf=true}${queryString}`, {
      responseType: 'blob',
      timeout: 60000,
      headers: {"Content-Type": "application/pdf"}
    });
    setPLoading(false)
    displayPdf(data, `invoice`)
  }

  useEffect(() => {
    if (id) loadEmail();
  }, [id])

  useEffect(() => {
    setFiltered(results?.wigs)
  }, [results?.wigs])

  const links = [
    {
      icon: <ShoppingCartIcon color="primary" />,
      link: '/orders/edit/',
      state: true,
      toolTip: 'Add to Sale',
      id: false,
      condition: (row) => row.status !== "sold" && row?.availability !== "committed" 
    },
    {
      icon: <CardTravelIcon color="primary" />,
      link: '/consignments/edit/',
      state: true,
      toolTip: 'Add to Consignment',
      id: false,
      condition: (row) => row.status !== "sold" && row?.availability !== "committed"
    },
]

  const actions = [
    {
      icon: <AddShoppingCartRoundedIcon color="primary" />,
      toolTip: 'Add to Cart',
      id: "cart",
      action: (row) => addItem(row),
      condition: (row) => row.status !== "sold" && row?.availability !== "committed"
    }
  ]

  if (panel !== 0) return <div></div>;
 
 return (
 <>
  <Ribbon variant='outlined'>
    <Grid container spacing={4} alignItems="center">
    <Grid item xs={12} md={4} lg={5}>
      <SimpleAutoComplete 
      list={custOptions.cust} name="search-cust" label="Customer Name"
      getOptionLabel={(opt) => opt.name || ''}
      getOptionSelected={({name}, val) => name === val.name}
      renderOption={(opt) => <span>{opt.name}</span>}
      autoHighlight
      autoSelect
      handleChange={handleChangeCust}
      handleSelect={handleCustSelect}
      />
    </Grid>

    {brands?.length > 1 ? <Grid item xs={12} md={3} lg={2}>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="brand-sel-label">Select Brand</InputLabel>
        <Select
          labelId="brand-sel-label"
          id="cust-search-brand"
          value={custOptions.brand}
          onChange={(e) => handleSelect(e, "brand")}
          label="Select Brand"
          fullWidth
        >
          {brands?.map(el => 
          <MenuItem value={el.name} selected={el.isDefault} key={el.name}>{el.name}</MenuItem>
            )}
          <MenuItem value="all">All</MenuItem>
        </Select>
      </FormControl>
    </Grid> : null }

        <Grid item xs={12} md={3} lg={2}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="cust-search-options-label">Select Range</InputLabel>
            <Select
              labelId="cust-search-options-label"
              id="cust-search-options"
              value={custOptions.range}
              onChange={(e) => handleSelect(e, "range")}
              label="Select Range"
              fullWidth
            >
              <MenuItem value="current">Current</MenuItem>
              <MenuItem value="past">Past</MenuItem>
            </Select>
          </FormControl>
        </Grid>


        <Grid item xs={2}>
          <Button 
            color="primary"
            variant="contained"
            onClick={getCustResults}
            >Search</Button>
        </Grid>
        
      {custOptions.range && custOptions.range !== "current" &&
        <>
          <Grid item xs={4} md={4} lg={3}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="sort-search-options-label">Sort By</InputLabel>
            <Select
              labelId="sort-search-options-label"
              id="sort-search-options"
              value={custOptions.sort}
              onChange={(e) => handleSelect(e, "sort")}
              label="Sort By"
              fullWidth
            >
              <MenuItem value="consignment">Consignment</MenuItem>
              <MenuItem value="sold">Sold</MenuItem>
            </Select>
          </FormControl>
          </Grid>
          <Grid item xs={3} md={3} lg={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
            variant="inline" inputVariant='outlined' openTo="year" disableToolbar views={["year", "month", "date"]} format="MM/dd/yyyy"
            value={custOptions.selectedStartDate} onChange={handleStartDateChange} label="Start Date" name="date" />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={3} md={3} lg={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
            variant="inline" inputVariant='outlined' openTo="year" disableToolbar views={["year", "month", "date"]} format="MM/dd/yyyy"
            value={custOptions.selectedEndDate} onChange={handleEndDateChange}  label="End Date" name="date" />
            </MuiPickersUtilsProvider>
          </Grid>
        </>
      }
       
        {custOptions.range && custOptions.range === "current" &&
        <>
         <Grid item xs={12}>
          <FullDivider y=".5rem" />
        </Grid>
        <Grid item xs={4} xl={2}>
          <OutlinedSelect size="small"
            color={theme.colors.secondary35} 
            bgColor={theme.colors.secondary75}
            textColor={theme.colors.secondary25}
            // button
            val={custOptions.includeStatus} 
            onChange={(e) => handleSelect(e, "includeStatus")}
          >
            <MenuItem value="all">Include Sold with Open Balance</MenuItem>
            <MenuItem value="consign">Only Consigned</MenuItem>
            <MenuItem value="sold">Only Sold with Open Balance</MenuItem>
          </OutlinedSelect>
        </Grid>

        <Grid item xs={1}>
        </Grid>

        <Grid item xs={4} xl={2}>
          <Flex dir="row" height="5rem" justify="space-between" pad="0" align="center">
          <Flex dir="column-reverse" justify="flex-start" align="flex-start" pad="0" gap="0">
            <InputLabel id="sort-search-options-label">Show only wigs over</InputLabel>
            <CustomSwitch name="filterDuration" checked={custOptions?.filterDuration} size="small" onChange={handleSwitch} />
          </Flex>
          <SelectContainer>
            <OutlinedSelect name="days" size="small" color={theme.colors.secondaryMain} 
            val={custOptions.days} 
            onChange={(e) => handleSelect(e, "days")}
            >
              <MenuItem value="14">14 Days</MenuItem>
              <MenuItem value="30">30 Days</MenuItem>
              <MenuItem value="45">45 Days</MenuItem>
              <MenuItem value="60">60 Days</MenuItem>
              <MenuItem value="90">90 Days</MenuItem>
              <MenuItem value="180">6 Months</MenuItem>
              <MenuItem value="365">Year</MenuItem>
            </OutlinedSelect>
          </SelectContainer>
          </Flex>
        </Grid>
        </> }


      </Grid>
    </Ribbon>
    <ResultContainer>
    {loading ? <LinearProgress /> : 
      <Grid container spacing={3}>
        {results && 
        <>
        
        <Grid item xs={9}>
          <SimpleTable headers={custHeaders} rows={filtered} title="Inventory" link="inventory" actionLinks={actions} links={links} />
        </Grid>
        <Grid item xs={3}>
          <Card>
            <CardHeader title="Summary" action={
              <TooltipContainer>
              <Tooltip title="Print">
                <IconButton onClick={printInvoice} aria-label="settings">
                  <Print color="primary" />
                </IconButton>
                </Tooltip>
                <FormDialog toggle={
                  <Tooltip title="Email">
                  <IconButton>
                    <EmailIcon  color="primary" />
                  </IconButton>
                  </Tooltip>
                  } 
                  title="Add Email Address"
                  onSave={saveFunc}
                  save="Send Email"
                  size="sm"
                  >
              <Grid container justify-content="center">
                <Grid item xs={8}>
                  {eLoading ? <LinearProgress /> : 
                  <TextField error={err} variant="outlined" label="Email" name="email" type="email" fullWidth defaultValue={email} onChange={handleEmailChange}
                  helperText={err ? 'Email Required' : ''} />
                }
                </Grid>
              </Grid>
            </FormDialog>
          
          </TooltipContainer>
        } />
        <CardContent>
          {pLoading ? <LinearProgress /> : 
          <>
        <InfoItem className='header' onClick={() => handleFilter("all")}><InfoLabel>Total of {sumBy(results.summary, 'count')} Wigs 
        {/* Currently in Consignment */}
        </InfoLabel></InfoItem>
        <Divider style={{margin: '20px 0'}} />
          {results.summary && results.summary.map(({_id, count}, i) => (
          <InfoItem className='color' onClick={() => handleFilter(_id)} key={i}><InfoLabel>{_id}:</InfoLabel>{count}</InfoItem>
          ))}
          </>
          }
        </CardContent>
          </Card>
        </Grid>
        </>
        }
      </Grid>
      }
    </ResultContainer>
 </>
 )
}