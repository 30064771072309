import React, {useState, useEffect, useCallback} from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import ViewInfo from '../../utils/viewInfo';
import axios from '../../axios';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import PrintIcon from '@material-ui/icons/Print';
import EmailIcon from '@material-ui/icons/Email';
import Delete from '@material-ui/icons/Delete';
import AttachMoneyRoundedIcon from '@material-ui/icons/AttachMoneyRounded';
import HighlightOffRounded from '@material-ui/icons/HighlightOffRounded';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import MoneyOffRoundedIcon from '@material-ui/icons/MoneyOffRounded';
import TouchAppIcon from '@material-ui/icons/TouchApp';

import displayPdf from '../../utils/displayPdf';
import {useSBContext} from '../../utils/snackbar';


import FormDialog from '../../utils/dialog';

import ApplyPayments from './ApplyPayments';

import FullDialog from '../../utils/FullDialog';

import { theme } from '../../utils/theme';

import CustomChip from '../../utils/CustomChip';

import { currencyFormat, formatCalendar, formatGenericDate, handleErrors } from '../../utils/util';

import { Flex } from '../../utils/common';

// import Accordion from './AccordionWigs';
import SimpleTable from '../../utils/simpleTable';
import { Button, CircularProgress, Checkbox, FormControlLabel, Switch } from '@material-ui/core';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';

const Info = styled.span`
  display: flex;
  margin-bottom: 1rem;
  font-size: 1.6rem;

  .amount {
    font-weight: 600;
    font-size: 1.7rem;
  }
`

const DialogHeader = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  color: ${props => props.theme.colors.secondaryMain};
  margin: 1.5rem 0;
  text-transform: uppercase;
`

const InfoLabel = styled.span`
  font-weight: 500;
  padding-right: 1rem;
`

const AccordionContainer = styled.div`
  margin-top: 2rem;
`

const StatusChip = styled(CustomChip)`
  display: inline-block;
  margin-left: 3.5rem;
`

const ApplyButton = styled(Button)`
  margin-top: .5rem;
  margin-right: 1.5rem;

  .icon {
    margin-right: 1rem;
  }
`

const IconLink = styled(Link)`
  color: ${props => props.theme.palette.secondary.main};
  position: relative;
  top: 1rem;
  padding-right: 2rem;
`

const Balance = styled.h3`
  /* font-family: 'Roboto'; */
  color: ${props => props.negative ? 'salmon' : 'inherit'};
`

const Wallet = styled.div`
  height: 8rem;
  text-align: center;
  margin-bottom: 5rem;
  margin-top: 2rem;

  h3 {
    font-size: 4.5rem;
    font-weight: 600;
    text-align: center;
    margin: .5rem 0;
    
  }

  .sub {
    display: block;
    text-align: center;
  }
`
const Due = styled.div`
  height: 4rem;
  text-align: center;
  margin-bottom: 5rem;

  h3 {
    font-size: 3rem;
    font-weight: 500;
    text-align: center;
    margin: .5rem 0;
  }

  .sub {
    display: block;
    text-align: center;
  }
`

const TooltipContainer = styled(Flex)`
`
 
export default (props) => {

  const [loading, setLoading] = useState(false)

  const [info, setInfo] = useState({});
  const [available, setAvailable] = useState(0)

  const [applied, setApplied] = useState([]);

  const [refunds, setRefunds] = useState([]);

  const [parentPayment, setParentPayment] = useState(null)

  const [calculated, calculate] = useState({
    set: false,
    available,
    applied: 0,
    totalWigs: 0,
    totalInvoices: 0
  })

  const [selected, setSelected] = useState({

  })

  const [applyDate, setApplyDate] = useState(new Date)

  const [email, setEmail] = useState('');

  const [orders, setOrders] = useState([])

  const [emailErr, setEmailErr] = useState(false);

  const [eLoading, setELoading] = useState(false)

  const [invoiceLoading, setInvoiceLoading] = useState(false);

  const [applyLoading, setApplyLoading] = useState(false)

  const [loadingOrders, setLoadingOrders] = useState(false)

  const [trashLoading, setTrashLoading] = useState(false)

  const [refundTrashLoading, setRefundTrashLoading] = useState(false)

  const [batchLoading, setBatchLoading] = useState(false)

  const [batchLoadingRefund, setBatchLoadingRefund] = useState(false)

  const [refund, setRefund] = useState(null)

  const {id} = props.match.params;

  const handleEmailChange = (e) => {
    setEmailErr(false)
    setEmail(e.target.value)
  }

  // console.log(id);
 
  const crumbs = [{path: '/payments/', text: 'Payments'}, 
  {path: `/payments/view/${id}`, text: 'View'}, {path: `/payments/view/${id}`, text: `${info?.paymentNum || ""}`}];

  const refreshStats = (data) => {
    let avail = data.payment.paymentAmount;

    let total = data.attachedPayments.reduce((acc, cur) => acc + cur.paymentAmount, 0)

    avail = avail - total;

    setApplied(data.attachedPayments)

    setRefunds(data.refunds)

    setParentPayment(data.parentPayment)

    setAvailable(avail)
  }

  const loadUnpaidOrders = async (cust) => {
    try {
      setLoadingOrders(true)
      const { data } = await axios.get(`/orders/unpaid?custId=${cust?.id?._id}`)
      setOrders(data)
      calculate(x => {
        if (!x.set) {
        return {
          set: true,
          available,
          applied: 0
        }
      }
      })
      console.log({data});
      setLoadingOrders(false)
    } catch (e) {
      setLoadingOrders(false)
      console.log(e);
    }
  }

  const applyPayments = useCallback(async () => {
    setApplyLoading(true)
    let payments = []
    Object.entries(selected).forEach(([key, value]) => {
      Object.entries(value).forEach(([item, amount]) => {
        if (item !== "order" && amount > 0) {
          payments.push({
            remainingBalance: item === "remain" ? true : false,
            paymentAmount: amount,
            paymentType: info.paymentType,
            paymentInfo: "",
            date: applyDate,
            entryType: 'applied',
            paymentId: info._id,
            orderId: key,
            wigId: item !== "remain" ? item : "",
            customer: {
              name: info.customer?.name,
              id: info.customer?.id?._id
            },
            paymentNotes: "",
          })
        }
      })
    })

    console.log(payments, "paymemememem", applyDate);

    try {
      const { data } = await axios.post(`/payments/${info._id}/apply`, {payments})
      console.log(data, "ehyyy");
      setInfo(data.payment)

      refreshStats(data)

    } catch (e) {
      console.log(e);
    } finally {
      setApplyLoading(false)
    }
    return true;
  }, [selected, info, applyDate])

  const loadData = async () => {
    setLoading(true)
    const {data} = await axios.get(`/payments/${id}?populated=customer.id`);
    const custInfo = data.payment.customer?.id;
    console.log(data, "check it out...");
    setInfo(data.payment, "info");
    setEmail(custInfo?.email || '')
    refreshStats(data)

    setLoading(false)
  }

  const paymentHeaders = [
    { id: 'customer.name', label: 'Customer'},
    { id: 'orderId', label: 'Sale ID' },
    { id: 'paymentAmount', currency: true, label: 'Payment Amount'},
    { id: 'paymentInfo', label: 'Info'},
    { id: 'wigId', label: "Wig ID"},
    { id: 'paymentNotes', truncate: true, label: 'Notes'},
    { id: 'date', date: true, label: 'Date'}
  ]

  const refundHeaders = [
    { id: 'displayAmount', currency: true, label: 'Refund Amount'},
    { id: 'paymentNotes', truncate: true, label: 'Refund Notes'},
    { id: 'date', date: true, label: 'Date'}
  ]

  useEffect(() => {
    if (id)
      loadData();
    else
      props.history.push('/payment');
  }, [])

  const { handleClick } = useSBContext()

  const sendEmail = async () => {
    if (!email) return setEmailErr(true);
    setELoading(true)
    try {
      await axios.post(`/payments/${id}/receipt?email=${email}`)
      handleClick('success', 'Email successfully sent')
      setInvoiceLoading(false)
    } catch (e) {
      handleClick('error', 'Error sending email')
      setELoading(false)
    }
    return 'sent'
  }

  const printInvoice = async () => {
    setInvoiceLoading(true)
    const {data} = await axios({
      method: "POST",
      url: `/payments/${id}/receipt?pdf=true`,
      responseType: 'blob',
      timeout: 60000,
      headers: {"Content-Type": "application/pdf"}
    });
    setInvoiceLoading(false)
    displayPdf(data, `Payment Receipt`)
  }

  const deletePayments = async (newPayments, loadFunc, reset) => {
    const updatedInfo = { custId: info.customer?.id?._id, payments: newPayments }
    console.log(updatedInfo, info.customer, "updatedInfo");
    loadFunc(true)

    try {
      const { data } = await axios.put(`/payments/${id}/remove`, {...updatedInfo});

      setInfo(data.payment)
      refreshStats(data)


      if (reset) {
        // reset selected
        reset({})
      }

      handleClick('success', 'Payment successfully removed')
    } catch (e) {
      console.log(e?.message)
      handleClick('error', '')
    } finally {
      loadFunc(false)
    }
    return "save"
  }

  const trashFunc = (index) => {
    let newPayments = applied.filter((el, ind) => ind === index)
    return deletePayments(newPayments, setTrashLoading)
  }

  const batchTrashFunc = (listObj, reset) => {
    let newPayments = applied.filter((el) => listObj[el._id])
    console.log(newPayments, "Chhcchchchchc");

    return deletePayments(newPayments, setBatchLoading, reset)
  }

  const removeRefunds = async (refundIds, loadFunc, reset) => {
    loadFunc(true)
    try {
      const { data } = await axios.put(`/payments/${id}/refunds/remove`, {refunds: refundIds})
      if (reset) {
        // reset selected
        reset({})
      }
      loadData()
      handleClick('success', 'Refunds successfully removed')
    } catch (e) {
      handleClick('error', handleErrors({e}))
    } finally {
      loadFunc(false)
    }
    return "save"
  }

  const removeRefund = (index) => {
    let refundIds = refunds.find((el, ind) => ind === index)._id
    console.log(index, refunds, refundIds, "refundIds");
    return removeRefunds(refundIds, setRefundTrashLoading)
  }

  const batchRemoveRefunds = (listObj, reset) => {
    let refundIds = Object.keys(listObj).filter(el => listObj[el])
    return removeRefunds(refundIds, setBatchLoadingRefund, reset)
  }

  const batchActions = [
    {
      icon: <Delete color="primary" />,
      title: "Delete?",
      cta: "Confirm Delete",
      func: (listObj, reset) => batchTrashFunc(listObj, reset),
      warningText: "Deleting will permanently remove payments from the system.",
      loading: batchLoading
    }
  ]

  const reconcilePayment = async (e) => {
    const checked = e.target.checked;
    const payments = [info._id]
    try {
      const { data } = await axios.put(`/payments/reconcile`, {payments, flag: checked})
      loadData()
      handleClick('success', checked ? 'Payment Reconciled' : 'Payment Unreconciled')
    } catch (e) {
      handleClick('error', 'Error Updating Reconciliation')
    }
  }

  const applyRefund = async () => {
    console.log(refund, "refund");
    if (!refund) return;

    if (!refund.date) setRefund(x => ({...x, date: new Date()}))
    try {
      const { data } = await axios.put(`/payments/${id}/refund`, {refund})
      loadData()
      handleClick('success', 'Refund Applied')
    } catch (e) {
      handleClick('error', 'Error Applying Refund')
    } finally {
      setRefund(null)
    }
  }

  const addRefund = (e, name) => {
    let val = e.target.value;
    setRefund(x => ({...x, [name]: val}));
  }

  const batchActionsRefund = [
    {
      icon: <Delete color="primary" />,
      title: "Delete?",
      cta: "Confirm Delete",
      func: (listObj, reset) => batchRemoveRefunds(listObj, reset),
      warningText: "Deleting will permanently remove the Refunds.",
      loading: batchLoadingRefund
    }
  ]

 return (
  <ViewInfo title="View Payment Details" crumbs={crumbs}>
    {loading ? <LinearProgress /> : 
    <Grid container spacing={3}>

      <Grid item xs={5}>

        <Card variant="outlined">
          <CardHeader title={<>Payment Info - {info?.paymentNum} {info?.isRefund ? <StatusChip size="large" level="error">Refund</StatusChip> : null} {info?.hasRefunds ? <StatusChip size="large" level="icon3">Refunded</StatusChip> : null}</>}
          action={!info?.isRefund ? <IconLink to={`/payments/edit/${info?._id}`}><EditIcon /></IconLink> : null } subheader={formatGenericDate(info?.date)} />
          <CardContent>
            <Info><InfoLabel>Customer Name:</InfoLabel>{info?.customer?.name}</Info>
            <Info><InfoLabel>Payment Amount:</InfoLabel><span className="amount">{currencyFormat(info?.displayAmount)}</span></Info>
            <Info><InfoLabel>Payment Method:</InfoLabel>{info?.paymentType}</Info>
            <Info><InfoLabel>Payment Info:</InfoLabel>{info?.paymentInfo}</Info>

            {info?.isRefund ?<Info><InfoLabel>Refunded Payment</InfoLabel>#{parentPayment ? parentPayment?.paymentNum : null}</Info> : null}
            
            <Info><InfoLabel>Notes:</InfoLabel>{info?.paymentNotes}</Info>

            <Divider style={{margin: '10px 0'}} />

            <Info><InfoLabel>Payment Entered By:</InfoLabel> {info?.addedBy?.name}</Info>
            <Info><InfoLabel>Payment Entered on:</InfoLabel> {formatCalendar(info?.createdAt)}</Info>
            <Info><InfoLabel>Last Updated:</InfoLabel> 
            {info?.updatedBy && `${formatCalendar(info?.updatedAt)} By ${info?.updatedBy?.name}`}
            </Info>
          </CardContent>
        </Card>

      </Grid>
     
      <Grid item xs={4}>
        
        <Card variant="outlined">
        <CardHeader title="Payment Availability" action={
            <TooltipContainer dir="row" pad="0" justify="space-between">
            <Tooltip title="Print Receipt">
            <IconButton onClick={printInvoice}>
              {invoiceLoading ? <CircularProgress size={25} /> :
              <PrintIcon color="primary" /> }
            </IconButton>
            </Tooltip>
            
            <FormDialog toggle={
                <Tooltip title="Email Receipt">
                <IconButton>
                  <EmailIcon color="primary" />
                </IconButton>
                </Tooltip>
              } 
              title="Add Email Address"
              onSave={sendEmail}
              save="Send Email"
              size="sm"
              >
              <Grid container justify-content="center">
                <Grid item xs={8}>
                  {eLoading ? <LinearProgress /> : 
                  <TextField error={emailErr} variant="outlined" label="Email" name="email" type="email" fullWidth defaultValue={email} onChange={handleEmailChange}
                  helperText={emailErr ? 'Email Required' : ''} />
                }
                </Grid>
              </Grid>
              </FormDialog>
              </TooltipContainer>
          }
           />
          {/* <CardContent> */}

            <Wallet>
            <h3>{currencyFormat(available)}</h3>
            <span className='sub'>Current Amount Available</span>
            </Wallet>

            <Divider style={{margin: '30px 0'}} />

            <Due>
              <Balance>{currencyFormat(info?.paymentAmount - available)}</Balance>
              <span className='sub'>Payment Amount Used</span>
            </Due>

        </Card>

      </Grid>

      <Grid item xs={3}>
        <Card variant='outlined'>
          <CardHeader title="Bank Reconciliation"></CardHeader>

          <CardContent>

            <Info><InfoLabel>Reconciled:</InfoLabel>{info?.isReconciled ? <CheckCircleOutlineRoundedIcon htmlColor={theme.colors.successGreen} /> : <HighlightOffRounded htmlColor={theme.colors.iconRedLight} />}</Info>
           {info?.isReconciled ? <Info><InfoLabel>Last Reconciled:</InfoLabel>{formatCalendar(info?.lastReconciled)}</Info> : null}

          <FormControlLabel
            control={<Switch 
              defaultChecked={info?.isReconciled}
              onChange={reconcilePayment} 
              color="primary" 
            />
          }
            label="Payment Reconciled"
          />

          </CardContent>
        </Card>
      </Grid> 
      
      {!info?.isRefund ? <Grid item xs={12}>

      <Card variant="outlined"> 
      <CardHeader title={applied.length ? `Applied Payments - ${applied.length} Payments` : "No Applied Payments"} action={
       ( <FullDialog toggle={
           <ApplyButton color="secondary" variant='contained' onClick={() => loadUnpaidOrders(info?.customer)}><TouchAppIcon className='icon' />Apply Payment</ApplyButton>
        } 
        title={<>Apply Payments</>}
        onSave={applyPayments}
        save={applyLoading ? <CircularProgress size={25} color="inherit" /> : "Save Payments"}
        cancel={false}
        >
        {loadingOrders ? <LinearProgress color="primary" /> : <>
          {orders.length ? <DialogHeader>Found {orders.length} Unpaid Invoices for {info.customer?.name} - {orders.reduce((acc, cur) => acc + cur.wigs?.length, 0)} Wigs</DialogHeader> : ""}
          <ApplyPayments orders={orders} calculated={calculated} calculate={calculate} original={available} selected={selected} setSelected={setSelected} date={{applyDate, setApplyDate}} />
        </>}
      </FullDialog>)
      } />

        

      <SimpleTable link="orders" headers={paymentHeaders} rows={applied} idReplace="orderId" write={false} view={false} trash={true} trashLoading={trashLoading} trashFunc={trashFunc} check={true} batchActions={batchActions} />

      </Card>
      </Grid> : null}


     {!info?.isRefund ? <Grid item xs={12}>

      <Card variant="outlined"> 
        <CardHeader title="Refunds" action={
          <FormDialog toggle={
            <Button color="secondary" variant='contained'><MoneyOffRoundedIcon />Apply Refund</Button>
          } 
          title="Apply Refund"
          onSave={applyRefund}
          save="Apply Refund"
          size="md"
          >
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <TextField variant="outlined" label="Refund Amount" type="number" fullWidth onChange={(e) => addRefund(e, "paymentAmount")} />
              </Grid>
              <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                  variant="inline" openTo="year" disableToolbar views={["year", "month", "date"]} inputVariant="outlined"
                 format="MM/dd/yyyy" onChange={(date) => addRefund({target: {value: date}}, "date")} label="End Date" name="date" fullWidth />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" label="Notes" multiline minRows={3} type="text" fullWidth onChange={(e) => addRefund(e, "paymentNotes")} />
              </Grid>

            </Grid>
          </FormDialog>
        } />

        <SimpleTable link="payments" headers={refundHeaders} rows={refunds} idReplace="_id" write={false} view={false} trash={true} trashLoading={refundTrashLoading} trashFunc={removeRefund} check={true} batchActions={batchActionsRefund} />

      </Card>

      </Grid> : null}
      

    </Grid>
    }
  </ViewInfo>
 )
}