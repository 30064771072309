import React, {useState, useEffect, useCallback} from 'react';
import styled from 'styled-components';
import axios from '../../axios';
import {Switch, Route} from 'react-router-dom';
import ProtectedRoute from '../../utils/ProtectedRoute';

import useFirstLoad from '../../hooks/useFirstLoad';
import useLoadList from '../../hooks/useLoadList';

import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';

import { theme } from '../../utils/theme';

import { debounce } from 'lodash'

import Table from '../../utils/table-server';

import AddPayment from './AddPayment';
import ViewPayment from './ViewPayment';
import { Available } from './paymentComponents';

import { useSBContext } from '../../utils/snackbar';

import { currencyFormat } from '../../utils/util'

import AccountBalanceRoundedIcon from '@material-ui/icons/AccountBalanceRounded';
import HighlightOffRounded from '@material-ui/icons/HighlightOffRounded';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';

import FilterPayments from './FilterPayments';


const reconcileBulk = async (selected, hc, load, setRows) => {
  try {
    const {data} = await axios.put('/payments/reconcile', {payments: selected, flag: true});
    load();
    hc("success", `${selected.length} Payments reconciled successfully`)
    return true;
  } catch (e) {
    hc("error", `Something went wrong, please try again`)
    return false;
  }
}

export default (props) => {

  const {rows, loading, load, searchList, count, switcher, setSwitch, tabFilter, setTabFilter, query, handleQuery, handleFilter, reload} = useLoadList('/payments');

  const [minMax, setMinMax] = useState([0, 100000])

  const [firstLoad, setLoad] = useFirstLoad()

  const [marks, setMarks] = useState([
      {
        value: 0,
        label: `$0.00`,
      },
      {
        value: 5000,
        label: '$5000.00',
      },
      {
        value: 10000,
        label: '$10,000.00',
      }
    ])

  const { handleClick } = useSBContext();

  const debouncedLoad = useCallback(debounce(load, 350), [])

  const loadMax = async () => {
    try {
      const { data } = await axios.get('/payments/max');

      let amount = Math.ceil(data.paymentAmount / 1000) * 1000;
      let half = amount / 2;
      setMarks([
        {
          value: 0,
          label: `$0.00`,
        },
        {
          value: half,
          label: `${currencyFormat(half)}`,
        },
        {
          value: amount,
          label: `${currencyFormat(amount)}`,
        }
      ])
      setMinMax([0, amount])
      handleFilter([0, amount], "slider", "range")
      setLoad(false)
    } catch (error) {
      handleClick('error', 'Error retrieving max amount')
    }
  }

  useEffect(() => {
    if (props.location.pathname !== '/payments/') return
    loadMax();
  }, [props.location.pathname])

  const headers = [
    { id: 'paymentNum', label: "ID"},
    { id: 'customer.name', label: 'Customer'},
    { id: 'displayAmount', currency: true, label: 'Amount' },
    { id: 'paymentType', label: 'Method'},
    { id: 'paymentInfo', label: 'Info'},
    { id: 'applied', render: row => <Available row={row} />, label: 'Available'},
    { id: 'isReconciled', render: row => row.isReconciled ? <CheckCircleOutlineRoundedIcon htmlColor={theme.colors.successGreen} /> : <HighlightOffRounded htmlColor={theme.colors.iconRedLight} />, label: 'Reconciled'},
    // { id: 'paymentNotes', label: 'Notes'},
    { id: 'date', date: true, label: 'Date'}
  ];

  const path = props.match.url;

  
  const reconcilePayments = async (ids) => {
    try {
      const {data} = await axios.put(`/payments/reconcile`, {payments: ids, flag: true});
      handleClick('success', "Successfully reconciled payment")
      reload();
    } catch (error) {
      handleClick('error', 'Error reconciling payment')
    }
  }

  let canEdit = (row) => {
    return row.isReconciled || row.isRefund ? false : true
  }

  const actions = [
    { 
      id: 'reconcile',
      icon: <AccountBalanceRoundedIcon color="primary" />, 
      toolTip: 'Reconcile Payment', 
      action: (id) => reconcilePayments([id]),
    }
  ]

  const batchActions = [ // batch actions when items are checked
    {
      icon: <AccountBalanceRoundedIcon color='secondary' />,
      title: "Reconcile Payments",
      action: (selected) => reconcileBulk(selected, handleClick, reload)
    }
  ]

  const handleTabs = (filter) => {
    setTabFilter(filter)
  }

  const tabs = [
    {
      label: "All",
      title: "",
      active: tabFilter === "" ? true : false
    },
    {
      label: "Payments",
      default: true,
      title: "payments",
      active: tabFilter === "payments" ? true : false
    },
    {
      label: "Refunds",
      title: "refunds",
      active: tabFilter === "refunds" ? true : false
    }
  ]


 return (
  <Switch>
    <ProtectedRoute path={`${path}/edit/:id`} guard='writePayment' component={AddPayment} />
    <ProtectedRoute path={`${path}/edit`} guard='writePayment' component={AddPayment} />
    <ProtectedRoute path={`${path}/view/:id`} guard='readPayment' component={ViewPayment} />
    <Route path="/payments">
    <Table loading={loading} tabs={tabs} tabHandler={handleTabs} load={load} title="Payments" headers={headers} rows={rows} link="payments" count={count} ord="desc" ordField="date"
    actions={actions}
    batchActions={batchActions}
    canEdit={canEdit}
      filterItems={
        <FilterPayments handleFilter={handleFilter} filter={query} marks={marks} minMax={minMax} applyFilter={reload} />
      }
      search={setPage => <TextField name="search" 
      label="Search Customer Name, or Amount" 
      variant="outlined" size="small" fullWidth onChange={(e) => searchList(e.target.value, setPage)}
      InputProps={{
        endAdornment:
          <InputAdornment position="end">
            <Search color="primary" />
          </InputAdornment>
      }}
      />} 
      />
    </Route>
  </Switch>
 )
}