import React, {useState} from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { Card, CardHeader, CardContent, IconButton } from '@material-ui/core';
import UnfoldMoreRoundedIcon from '@material-ui/icons/UnfoldMoreRounded';
import UnfoldLessRoundedIcon from '@material-ui/icons/UnfoldLessRounded';

const DisplayWoCard = styled(Card)`
  height: ${props => props.expand ? "auto" : "7rem"};
  overflow: hidden;
  background-color: ${props => props.theme.colors.errorBg};
  border: 1px solid ${props => props.theme.colors.errorText};
  color:${props => props.theme.colors.errorText} !important;
`;

const WoErrorHeader = styled.h4`
  margin: .3rem 0;
  font-weight: 500;
`;

const WoErrorList = styled.ul`
  margin: 0;
`;

const DisplayWorkOrderErrors = ({errors}) => {
  const [expand, setExpand] = useState(false);

  const toggleExpand = () => {
    setExpand(x => !x)
  }
  if (!isEmpty(errors)) {
    return (
      <DisplayWoCard variant="outlined" expand={expand}>
        <CardHeader title="Work Order Errors"
        subheader={`Errors for ${Object.keys(errors).length} Items`}
        action={<IconButton onClick={toggleExpand}>{!expand ? <UnfoldMoreRoundedIcon /> : <UnfoldLessRoundedIcon /> }</IconButton>} />
        <CardContent>

        {Object.keys(errors).map((key, i) => (
          <div key={i}>
            <WoErrorHeader>{key}</WoErrorHeader>
            <WoErrorList>
            {Object.keys(errors[key]).map((el, i) => (
              <li key={i}>{errors[key][el]}</li>
            ))}
            </WoErrorList>
          </div>
        ))}
        </CardContent>
      </DisplayWoCard>
    )
  } else {
    return null;
  }
}

export default DisplayWorkOrderErrors;

