import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import ViewInfo from '../../utils/viewInfo';
import axios from '../../axios';

import { theme } from '../../utils/theme';

import CustomChip from "../../utils/CustomChip";

import {useSBContext} from '../../utils/snackbar';


import WigDisplay from './WigDisplay';


const PageHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`;


const ViewWig = (props) => {

  const [value, setValue] = useState(0); // for the tabs

  const [loading, setLoading] = useState(true)
  const [info, setInfo] = useState({});

  // new for repairs and transfers
  const [repairs, setRepairs] = useState([])
  const [transfers, setTransfers] = useState([])

  // new for payments if wig is sold
  const [payments, setPayments] = useState([])

  const [edits, setEdits] = useState({
    
  })

  const [editLoading, setEditLoading] = useState(false)

  const {id} = props.match.params;

  const crumbs = [{path: '/inventory/', text: 'Inventory'}, 
  {path: '/inventory/view', text: 'View'}, {path: `/inventory/view/${id}`, text: `${info?.wigId || ""}`}];


  const loadData = async () => {
    try {
      const {data} = await axios.get(`/inventory/${id}?details=true`);
      setInfo(data.inventory);
      setRepairs(data?.repairs);
      console.log(data.repairs, "repairs")
      setTransfers(data?.transfers);
      setPayments(data?.payments);
      let wigInfo = data.inventory;
      setEdits({
        style: wigInfo.style,
        capSize: wigInfo.capSize,
        lengthNum: wigInfo.lengthNum,
        description: wigInfo.description,
        hairstyle: wigInfo.hairstyle,
        price: wigInfo.sale?.subtotal
      })
      // const {data: backLink} = await axios.get(`/inventory/link/${id}`)
      // setLink(backLink?.link)
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
    
  }

  useEffect(() => {
    if (id) {
      loadData()
    } else {
      props.history.push('/inventory')
    }
  }, [])

  const handleEdit = e => {
    const {name, value} = e.target;
    setEdits(x => ({...x, [name]: value}))
  }

  const {handleClick} = useSBContext();

  const submitEdits = async () => {
    if (!info._id) return handleClick('error', 'Error saving changes')
    setEditLoading(true)
    console.log(edits, "edits", id)
    try {
      const {data} = await axios.put(`/inventory/${info._id}`, {
        ...edits
      })
      handleClick('success', 'Updates successfully saved')
      loadData()
      return 'done';
    } catch (e) {
      handleClick('error', 'Error saving changes')
    } finally {
      setEditLoading(false)
    }
    
  }

  let color, bgColor, statusText;

  if (info.status === "sold") {
    if (info.sale?.paid) {
      color = theme.colors.icon2; bgColor = theme.colors.iconBg2; statusText = "Paid"
    } else {
      if (info.sale?.paidAmount > 0) {
        color = theme.colors.iconCaution; bgColor = theme.colors.iconBgCaution; statusText = "Partially Paid"
      } else {
        color = theme.colors.iconRed; bgColor = theme.colors.iconBgRed; statusText = "Not Paid"
      }
    }
  }

  let chip = '';

  if (info.isRemoved) {
    chip = <CustomChip level="error" size="large">This wig has been removed from the system</CustomChip>
  }

 return (
  <ViewInfo title={<PageHeader>View Wig Details {chip}</PageHeader>} crumbs={crumbs}>
    <WigDisplay info={info} loading={loading} submitEdits={submitEdits} editLoading={editLoading} handleEdit={handleEdit} repairs={repairs} transfers={transfers} payments={payments} loadData={loadData} {...props} />    
  </ViewInfo>
 )
}

export default ViewWig;