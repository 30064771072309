import React from "react";
import styled, { css } from "styled-components";

import { isEmpty } from "lodash";

import { Grid, TextField, Select, MenuItem, Switch, FormControlLabel } from "@material-ui/core";

import { theme } from "../../../utils/theme";

import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';


import { IconBox } from "../../../utils/common";

import OutlinedSelect from "../../../utils/OutlinedSelect";
import { currencyFormat } from "../../../utils/util";



const OrderItem = styled.div`
  /* padding: .8rem; */
  position: relative;
  flex-grow: 1;
  border-radius: 3px;
  border: 2px solid #0000001f;
  border-left: 3px solid ${props => !isEmpty(props.error) ? props.theme.colors.errorBg : props.theme.colors.secondaryMain};
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.5rem;

  ${props => props.err && css`
    border-left: 3px solid ${props.theme.colors.error};
  `}
  ${props => props.dup && css`
    border-left: 3px solid ${props.theme.colors.caution};
  `}
`
const InputLabel = styled.label`
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 500;
  color: ${props => props.theme.colors.lightText};
  margin-bottom: .2rem;
`;

const ActionsContainer = styled.div`
  width: 300px;
  background-color: ${props => props.theme.colors.headerBg};
  border-left: 2px solid ${props => props.theme.colors.border};
  padding: .8rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: flex-end;
`;

const ActionFields = styled(TextField)`
  background-color: #fff;
`; 

const SwitchContainer = styled.div`
  padding-left: .5rem;
`;

const CustomIconBox = styled(IconBox)`
  margin-right: 0;
  height: 4rem;
`;

const FieldsContainer = styled.div`
  flex-grow: 1;
  padding: .8rem 1.5rem;
  padding-top: 2rem;

  .MuiGrid-spacing-xs-3 > .MuiGrid-item { 
    padding-top: 0;
  }
`;

const Summary = styled.div`
  display: flex;
  justify-content: flex-end;

  .header {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1.2rem;
    color: ${props => props.theme.colors.lightText};
  }

  .value {
    font-weight: 500;
  }
`;

const SummarySpan = styled.div`
  border: 1px solid ${props => props.theme.colors.border};
  display: flex;
  gap: 1.5rem;
  align-items: center;
  padding: .6rem 1rem;
  border-radius: .5rem;
  background-color: #fff;
`;

const DisplayFulfilled = styled.div`
  position: absolute;
  top: -1.5rem;
  left: 1.5rem;
  color: ${p => p.theme.colors.primaryMain};
  background-color: #f4f4f4;
  border-radius: 5px;
  padding: 0.2rem 1rem;
  font-size: 1.2rem;
  border: 1px solid #0000001f;
  font-weight: 600;
`


const LineItem = ({itemInfo, setLineItem, errors = {}, index, systemValues, remove, ...props}) => {

  let { lengthNum, color, capSize, baseMaterial, hairstyle, line, qty, cost, price, canEdit, fulfilledQty } = itemInfo; // canEdit Cost
  const { lengths = [], colors = [], capSizes = [], materials = [], hairstyles = [], lines = [] } = systemValues;

  const updateCost = (e) => {
    if (canEdit) {
      setLineItem(e, index, "cost");
    }
  }

  console.log(errors[index], "errors!!!!!!!!!!!");

  return (
    <OrderItem error={errors[index]}>
      {fulfilledQty ? (<DisplayFulfilled>
        Fulfilled {fulfilledQty}
      </DisplayFulfilled>) : null}
      <FieldsContainer>
      <Grid container spacing={3}>
        <Grid item xs={4}>
        <InputLabel>Color</InputLabel>
          <OutlinedSelect $regular size="small" val={color} onChange={(e) => setLineItem(e, index, "color")}>
          <MenuItem value="">Select Option</MenuItem>
              {colors.map((color = {}, i) => (
                <MenuItem key={i} value={color.style}>{color.style}</MenuItem>
              ))}
          </OutlinedSelect>
        </Grid>
        <Grid item xs={4}>
          <InputLabel>Length</InputLabel>
          <OutlinedSelect $regular size="small" val={lengthNum} onChange={(e) => setLineItem(e, index, "lengthNum")}>
          <MenuItem value="">Select Option</MenuItem>
              {lengths.map((length = {}, i) => (
                <MenuItem key={i} value={length.value}>{length.value}</MenuItem>
              ))}
          </OutlinedSelect>
        </Grid>
        <Grid item xs={4}>
        <InputLabel>Cap Size</InputLabel>
          <OutlinedSelect $regular size="small" val={capSize} onChange={(e) => setLineItem(e, index, "capSize")}>
            <MenuItem value="">Select Option</MenuItem>
              {capSizes.map((capSize = {}, i) => (
                <MenuItem key={i} value={capSize.value}>{capSize.value}</MenuItem>
              ))}
          </OutlinedSelect>
        </Grid>
       {materials?.length ? <Grid item xs={4}>
        <InputLabel>Material</InputLabel>
          <OutlinedSelect $regular size="small" val={baseMaterial} onChange={(e) => setLineItem(e, index, "baseMaterial")}>
              <MenuItem value="">Select Option</MenuItem>
              {materials.map((material = {}, i) => (
                <MenuItem key={i} value={material.value}>{material.value}</MenuItem>
              ))}
          </OutlinedSelect>
        </Grid> : null }
       {hairstyles?.length ? <Grid item xs={4}>
        <InputLabel>Hairstyle</InputLabel>
          <OutlinedSelect $regular size="small" val={hairstyle} onChange={(e) => setLineItem(e, index, "hairstyle")}>
          <MenuItem value="">Select Option</MenuItem>
              {hairstyles.map((hairstyle = {}, i) => (
                <MenuItem key={i} value={hairstyle.value}>{hairstyle.value}</MenuItem>
              ))}
          </OutlinedSelect>
        </Grid> : null }
        {lines?.length ? <Grid item xs={4}>
        <InputLabel>Line</InputLabel>
          <OutlinedSelect $regular size="small" val={line} onChange={(e) => setLineItem(e, index, "line")}>
          <MenuItem value="">Select Option</MenuItem>
              {lines.map((line = {}, i) => (
                <MenuItem key={i} value={line.value}>{line.value}</MenuItem>
              ))}
          </OutlinedSelect>
        </Grid> : null }
      </Grid>
      </FieldsContainer>
      <ActionsContainer>
        <Actions>
        <div>
          <InputLabel>Qty</InputLabel>
          <ActionFields
            fullWidth
            type="number"
            size="small"
            // label="Qty"
            value={qty}
            onChange={(e) => setLineItem(e, index, "qty")}
            variant="outlined"
          />
        </div>
        <div>
          <InputLabel>Cost</InputLabel>
          <ActionFields
            fullWidth
            type="number"
            size="small"
            disabled={!canEdit}
            onChange={updateCost}
            // label="Cost"
            value={cost}
            variant="outlined"
          />
        </div>

            <CustomIconBox onClick={() => remove(index)} bg={theme.colors.iconBgRed} color={theme.colors.iconRed} button size="medium">
              <DeleteRoundedIcon />
            </CustomIconBox>
        </Actions>
        <SwitchContainer>
          <FormControlLabel 
            control={<Switch size="small"
                onChange={(e) => setLineItem(e, index, "canEdit")}
                checked={canEdit}
              />} 
            label="Edit Cost" 
          />
        </SwitchContainer>
        <Summary>
          <SummarySpan>
            <div className="header">Total:</div>
            <div className="value">{currencyFormat(price)}</div>
          </SummarySpan>
        </Summary>
      </ActionsContainer>
    </OrderItem>
  )

}

export default LineItem;