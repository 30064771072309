import React from "react";
import ProtectedRoute from '../../utils/ProtectedRoute';
import { Switch, Route } from 'react-router-dom'

import EditWorkOrder from './AddWorkOrder';
import ViewWorkOrder from './ViewWorkOrder';
import WorkOrderList from "./WorkOrderList";


const WorkOrders = (props) => {

  const path = props.match.url;

  return (
    <Switch>
      <ProtectedRoute path={`${path}/edit/:id`} guard='readWorkOrder' component={EditWorkOrder} />
      <ProtectedRoute path={`${path}/edit`} guard='readWorkOrder' component={EditWorkOrder} />
      <ProtectedRoute path={`${path}/view/:id`} guard='readWorkOrder' component={ViewWorkOrder} />
      <ProtectedRoute path={path} guard="readWorkOrder" component={WorkOrderList} />
    </Switch>
  )

}

export default WorkOrders;