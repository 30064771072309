import React, {useState, useEffect, useCallback} from 'react';
import styled, { css } from 'styled-components';
import {Link} from 'react-router-dom';
import axios from '../axios';

import {useSBContext} from './snackbar';
import { useAuthContext } from '../auth';


import { handleErrors, formatDisplay } from './util';

import {Table, TableBody, TableCell, 
  TableContainer, TableHead, TablePagination, TableRow
,TableSortLabel, Toolbar, Paper, Checkbox, IconButton, 
Tooltip, FormControlLabel, Switch, CircularProgress,
Fab, Grid, TextField, InputAdornment, Button} from '@material-ui/core';

import {Delete, FilterList, Add, Search, Edit, GetAppRounded} from '@material-ui/icons';
import TuneIcon from '@material-ui/icons/Tune';

import { Flex, FlexLine } from './common';

import Skeleton from '@material-ui/lab/Skeleton';
import AlertDialog from './dialog';

import Popover from './popover';

const DisplayPaper = styled(Paper)`
  background-color: rgb(250, 250, 251);

  .MuiTablePagination-root {
    width: 95%;
    border: 2px solid ${props => props.theme.colors.border};
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: none;
    margin: 0 auto;
    background-color: rgb(245, 245, 245);
  }
`
const ButtonLink = styled(Button)`
  /* display: block; */
  float: right;
  margin-right: 3rem;
`

const IconLink = styled(Link)`
  display: inherit;
`

const TrashDialog = styled(AlertDialog)`
  display: inherit;
`

const PopContent = styled.div`
  padding: 10px 25px;

  h5 {
    font-weight: 500;
    margin-bottom: 1.5rem;
  }
`

const TableHeadStyles = styled(TableHead)`
  background-color: rgb(245, 245, 245);
  border-top: none;
`

const SwitchItem = styled(Flex)`
  min-width: 20rem;
`


const TableHeader = styled.span`
 display: block;
 font-size: 2rem;
 font-weight: 500;
 width: 95%;
 margin: 0 auto;
 padding: 2.5rem 0;
`

const ActionsContainer = styled.div`
/* display: flex;
justify-content: flex-start; */

  .MuiIconButton-root {
    padding: 6px !important;
  }
`

const MainTable = styled(Table)`
  width: 95%;
  margin: 0 auto;
  border: 1px solid rgb(224, 224, 224);
  /* border-top: none; */
  border-radius: 10px;
  background-color: #fff;
`

const TableToolBarStyles = styled.div`
  width: 95%;
  margin: 0 auto;
  padding: 1.2rem 1rem .5rem 1rem;
  border: 1px solid rgb(224, 224, 224);
  border-bottom: none;
  background-color: #fff;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;

  .MuiToolbar-gutters {
    padding: 0;
  }
`
const GridBreak = styled(Flex)`

  span {
    font-weight: 500;
    font-size: 105%;
    display: block;
  }

  .selected {
    padding: 0 1.5rem;
  }

  .results-span {
    text-align: right;
    flex-grow: 1;
    padding: 1rem 3.5rem 1rem 0;
  }
`

const SelectedActionsFlex = styled(FlexLine)`
 max-width: 50rem; 
`

const NoRows = styled.span`
  color: #777;
`

const TabStyles = styled.div`
  width: 95%;
  margin: 0 auto;
  padding-bottom: .6rem;
  /* height: 6rem; */
  display: flex;
  flex-direction: row;
  border-right: 1px solid ${props => props.theme.colors.border};
  border-left: 1px solid ${props => props.theme.colors.border};
  /* border-top-right-radius: 20px;
  border-top-left-radius: 20px; */
  /* border-bottom: 6px solid ${props => props.theme.colors.border}; */
  /* border-bottom: none; */
  background-color: #eff3ff;
  box-shadow: inset 0 -3px 10px rgba(128, 159, 255, .2);
`

const SingleTab = styled.div`
  width: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-right: 2px solid ${props => props.theme.colors.border}; */
  /* text-transform: uppercase; */
  font-weight: 500;
  font-size: 1.4rem;

  span {
    display: block;
    width: max-content;
    padding: 2.3rem 2rem;
    /* padding-bottom: 1rem; */
    text-align: center;
    /* margin-bottom: 1rem; */
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    ${props => props.active ? css`
      background-color: white;
      box-shadow: 1px 5px 10px rgba(0, 0, 0, .04);
      height: 85%;
      align-self: flex-start;
      font-weight: 600;
      color: ${props => props.theme.colors.primaryMain};
    ` : ''}
    
  }

  &:hover {
    color: ${props => props.theme.colors.primaryMain};
    cursor: pointer;
    font-weight: 600;
    span {
      ${props => props.active ? css`
      box-shadow: 0 10px 12px 5px rgba(0, 0, 0, .04);
       ` : ''}
    }
  }
`

const CompactButton = styled(Button)`
  padding: .5rem .5rem;
  min-width: auto;
`;


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function stableSort(array, comparator) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el) => el[0]);
// }

const Tabs = ({tabs, tabHandler}) => {
  return (
    <TabStyles>
      {tabs && tabs.map(el => 
        <SingleTab key={el.label} active={el.active} onClick={() => tabHandler(el.title)}>
          <span>{el.label}</span>
        </SingleTab>
        )}
    </TabStyles>
  )
}

const urgentColor = "#fff9fa" // #faf1f2
 

const EnhancedTableToolbar = (props) => {
  const { title, add, selected, setSelected, batchActions = [],  handleDisplay, display, link, switchField, search, setPage, filterItems, settings, count = 0 , pages = 0, headers, views, tableTitle, tabs, tabHandler, csv} = props;

  let numSelected = selected?.length

  const [loading, setLoading] = useState(false)

  const saveFunc = useCallback(async (action) => {
    setLoading(true)
    const result = await action(selected);
    if (result) {
      setSelected([])
    }
    setLoading(false)
    return "done";
  }, [selected])

  return (
    <TableToolBarStyles>
    <Toolbar>
      {
      (
        <Popover
        toggle={
          <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterList />
          </IconButton>
        </Tooltip>
        }
        >
          <PopContent>
            <h5>Filter By:</h5>
            {filterItems || `No Options`}
          </PopContent>
        </Popover>
      )
      }
     
      <Grid container spacing={3} alignItems="center" justifyContent="flex-start">
        <Grid item xs={3}>
          <Flex dir="row" pad="0" justify="flex-end">
            {switchField || null}
            <Popover
              toggle={
                <Tooltip title="Select Fields">
                <IconButton><TuneIcon color="secondary" /></IconButton>
              </Tooltip>
              }
              >
                <PopContent>
                  <h5>Select Fields to Display</h5>
                  {
                    headers.map(el => 
                        <SwitchItem key={el.label} dir="row" pad="0" padY="1rem" justify="space-between">
                          {el.label} 
                          <FormControlLabel
                            control={
                              <Switch
                                checked={display[el.label]}
                                onChange={(e) => handleDisplay(e, el.label)}
                                name={el.label}
                                color="primary"
                              />
                            }
                            />
                        </SwitchItem>
                      )
                  }
                </PopContent>
              </Popover>
          </Flex>
        </Grid>
        <Grid item xs={5}>
          {search ? search(setPage) : null}
        </Grid>
        <Grid item xs={1}>
          {settings ? settings : null}
          {views ? views : null}
          </Grid>
        <Grid item xs={3}>
          {csv ? <Tooltip title="Download CSV"><CompactButton onClick={csv} color="secondary" variant="contained"><GetAppRounded /></CompactButton></Tooltip> : null}
          {add ? <ButtonLink color='primary' variant='contained' size='small' href={`/${link}/edit/`} startIcon={<Add /> }>New {tableTitle || title.slice(0, -1)}</ButtonLink>  : null }
        </Grid>
        
      </Grid>
      
    </Toolbar>
      <GridBreak dir="row" justify="space-between" pad="0">
      {numSelected > 0 && (
         <SelectedActionsFlex dir="row" justify="flex-start" pad="0">
          <span className='selected'> {numSelected} selected</span> 
          { batchActions.map(({icon, action, title}) => (

          <AlertDialog key={title} toggle={<IconButton><Tooltip title={title}>{icon}</Tooltip></IconButton>}
          title={title}
          save={loading ? <CircularProgress color='primary' /> : "Confirm Selection"}
          onSave={() => saveFunc(action)}
          size='xs'
          >
            You have selected <b>{numSelected}</b> items.
          </AlertDialog>

          )
          )}
        </SelectedActionsFlex>
      ) } 
      <span className='results-span'>{count} Results&nbsp; - &nbsp;{pages} Pages</span>
      </GridBreak>
    </TableToolBarStyles>
  );
};


function EnhancedTableHead(props) {
  const { onSelectAllClick, display, order, orderBy, numSelected, rowCount, onRequestSort, headCells, search} = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHeadStyles>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.filter(el => display[el.label]).map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align ? headCell.align : 'left'}
            style={{fontSize: '15px', fontWeight: '600'}}
            padding='normal'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
            {headCell.search ? search : null}
          </TableCell>
        ))}
        <TableCell
          align='center'
          style={{fontSize: '15px', fontWeight: '600'}}
          >
            Actions
          </TableCell>
      </TableRow>
    </TableHeadStyles>
  );
}

const SkeletonContainer = styled(TableBody)`
/* 
  &.MuiTableBody-root {
    border: none;
  }
  .MuiTableRow-root {
    height: 40px;
    border: none;
  }
  .MuiTableCell-root {
    border: none;
  } */
`


export default function EnhancedTable({load, loading, title, tableTitle, headers, rows, count, ord='asc', ordField='', link, add = true, view = true, write = true, trash = true, switchField, search, filterItems, urgent = () => {return false}, canEdit = () => {return true}, settings, actions, batchActions, viewTitle, views, altId, tabs = [], tabHandler, csv}) {

  const [order, setOrder] = useState(ord);
  const [orderBy, setOrderBy] = useState(ordField);
  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(false);
  // console.log(ordField, 'ord', viewTitle, 'hi', orderBy)

  const { user, setUser } = useAuthContext()

  let disp = {}

  headers.forEach(el => {
    disp[el.label] = true
  })

  const drpp = user.settings?.general?.defaultRpp

  let collection = viewTitle || title

  let displaySettings = user.settings?.tables?.find(el => el.table === collection)?.rows || disp

  let [display, setDisplay] = useState(displaySettings)

  const handleDisplay = async (e, name) => {
    setDisplay(x => ({...x, [name]: e.target.checked}))

    try {
      let table = {table: collection, rows: {...display, [name]: e.target.checked}}

      let result = await axios.put('/users/settings/tables', {table})

      if (result) {
        setUser(result.data)
      }
    } catch (e) {
      // console.log("trouble updating...");
    }
  }

  useEffect(() => {
    // setDisplay(displaySettings)
    // setOrderBy(ordField)
    // setOrder(ord)
  }, [viewTitle, ordField])

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(drpp ? drpp : 25);
  const [pages, setPages] = useState(0)

  let pagejs = 0, rpp = 10, sortOrder = 'asc', localOrderBy = '';

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    sortOrder = isAsc ? 'desc' : 'asc'
    localOrderBy = property
    load(page, rowsPerPage, localOrderBy, sortOrder);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClickCheck = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  useEffect(() => {
    // console.log("here IN TABLLELELEL");
    load(page, rowsPerPage, orderBy, order)
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    pagejs = newPage
    load(pagejs, rowsPerPage, orderBy, order);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    rpp = parseInt(event.target.value, 10)
    pagejs = 0
    load(page, rpp, orderBy, order)
  };

  const {handleClick: sb} = useSBContext();

  const trashFunc = async (id) => {
    try {
      await axios.delete(`/${link}/${id}`);
      load(page, rowsPerPage, orderBy, order)
      sb('success', 'Successfully deleted')
    } catch (e) {
      sb('error', handleErrors({e}))
    }
    return 'deleted';
  }

  const columns = headers.length + 2

  const Skeletons = () => ( 
    <SkeletonContainer>
      {[...Array(5)].map((el, i) => (
        <TableRow key={i}>
          {[...Array(columns)].map((el, ind) => (
            <TableCell key={ind}>
              <Skeleton variant="text" />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </SkeletonContainer>
    )
  
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows = rows?.length ? rowsPerPage - rows.length : rowsPerPage;

  // const formatDisplay = (obj1, obj2) => {
  //   const {render} = obj1
      
  //   let string = ''
  //   const val = render ? render(obj2) : get(obj2, obj1.id) || ""

  //   if (obj1.currency && obj2.rateType !== 'percentage') string = currencyFormat(val)
  //   else if (obj1.currency && obj2.rateType === 'percentage') string = `%${val}`
  //   else if (obj1.date) string = formatGenericDate(val)
  //   else string = val

  //   return string || string === 0 ? string : '-';
  // }
  

  useEffect(() => {
    let p = Math.ceil(count / rowsPerPage)
    setPages(p)
  }, [rowsPerPage, count])

  // console.log(selected, "SLSLLSLLLSL");

  return (
    <div>
      <DisplayPaper elevation={0}>
        <TableHeader>
          {title} 
        </TableHeader>
        <EnhancedTableToolbar pages={pages} count={count} title={title} tableTitle={tableTitle} selected={selected} setSelected={setSelected} link={link} switchField={switchField} settings={settings} add={add} search={search} setPage={setPage} filterItems={filterItems} headers={headers} handleDisplay={handleDisplay} views={views}
        batchActions={batchActions} 
        display={display}
        tabs={tabs} tabHandler={tabHandler} csv={csv}
        />
        {tabs && tabs.length ? <Tabs tabs={tabs} tabHandler={tabHandler}></Tabs> : null }

        <TableContainer>
          <MainTable
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              display={display}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headers}
              search={search}
            />
            {loading ? <Skeletons /> :
            <TableBody>
               
              {rows && rows
                .map((row, index) => {
                  const isItemSelected = isSelected(row._id);
                  let rowId = altId ? row[altId] : row._id
                  let canEditRow = canEdit(row)
                  return (
                    <TableRow
                      style={urgent(row) ? {backgroundColor: urgentColor} : {}}
                      hover
                      onClick={(event) => handleClickCheck(event, row._id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected || false}
                        />
                      </TableCell>
                      {headers.filter(el => display[el.label]).map(cur => 
                      <TableCell key={cur.id} align={cur.align ? cur.align : "left"}>
                       {formatDisplay(cur, row)}
                      </TableCell>
                      )}
                      <TableCell align="center">
                        <ActionsContainer>
                        {actions && actions.map(el => {
                        return (
                          <Tooltip title={el.toolTip} key={el.id}>
                            <IconButton onClick={(e) => {el.action(row)}}>{el.icon}</IconButton>
                          </Tooltip>
                        )
                      })}
                         { write && canEditRow && <IconButton size='small'>
                         <IconLink to={`/${link}/edit/${rowId}`} ><Edit className="edit-icon" color="primary" /></IconLink></IconButton>
                         }
                         { view && <IconButton size='small'>
                          <IconLink to={`/${link}/view/${rowId}`} ><Search color="primary" /></IconLink></IconButton>
                         }
                          { trash && <IconButton size='small'>
                            <TrashDialog toggle={
                              <Delete className="hover-icon" color="primary" />
                            } 
                            title="Delete?"
                            onSave={() => trashFunc(rowId)}
                            save="Comfirm Delete"
                            size="xs"
                            >
                              Deleting will permanently remove this information
                              from the system.
                            </TrashDialog>
                            </IconButton>
                         }
                        </ActionsContainer>
                      </TableCell>
                    </TableRow>
                  );
                })
                }
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan="100" />
                </TableRow>
              )}
            </TableBody>
            }
          </MainTable>
        </TableContainer>
        {!loading ? 
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> : null}
      </DisplayPaper>
    </div>
  );
}